import React, {
  forwardRef,
  useImperativeHandle
} from 'react';

import Web3 from 'web3';
import $ from 'jquery';
import config from '../../lib/config';

import {
  AddLikeAction,
  GetLikeDataAction
} from '../../actions/v1/token';
import BEP1155 from '../../ABI/BEP1155.json';
import BEP721   from  '../../ABI/BEP721.json';
import VALOBITDX from '../../ABI/VALOBITDX.json'
import {
  getCurAddr
} from '../../actions/v1/User';
import {
  convertion,
  BurnField,
  TokenCounts_Get_Detail_Action
} from '../../actions/v1/token';
import { Button, TextField } from '@material-ui/core';
import {
  useHistory
} from "react-router-dom";
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

var web3              = new Web3(window.ethereum);
const VALOBITDXContract = new web3.eth.Contract(VALOBITDX,config.ValobitdxAddr)
const BEP721Contract    = new web3.eth.Contract(BEP721, config.singleContract)

const price = [
  { value: 'ETH', label: 'ETH' },
  { value: 'VBIT', label: 'VBIT' }
];

export const BurnRef = forwardRef((props, ref) => {
  const history = useHistory();
  const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');
  const [onwer_price,set_owner_price]=React.useState({})
  const [burnLoading, setBurnLoading] = React.useState('empty');
  const[noofitems,setnoofitem]=React.useState(1);
  const [showingLoader, setshowingLoader] = React.useState(false);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = React.useState({});
  const [OwnersDetailFirst, Set_OwnersDetailFirst] = React.useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = React.useState({});
  const [Bids, Set_Bids] = React.useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = React.useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = React.useState(0);
  var  [item, Set_item] = React.useState({}); 
  var  [item1, Set_item1] = React.useState({}); 
  const [Burndata,setBurndata] = React.useState(0);

  var {
      item,
      UserAccountAddr,
      GetUserBal,
     // Set_item
  } = props;
  async function FormSubmit(item,Burntoken){
      if(window.ethereum) {
        var web3 = new Web3(window.ethereum);
        if (web3&&web3.eth) {
          var connectwallet=localStorage.getItem("xdtitfnbolav");
          if(!connectwallet){
            toast.error("Please connect to a Metamask wallet", toasterOption);
            return false;
          }
         
          if(noofitems == "" ){
            toast.error("Burn token is not allow to empty", toasterOption);
            return false;
          }else  if(noofitems == 0){
            toast.error("Burn token must be greater than zero", toasterOption);
            return false;
          }
          if(Burntoken.type==721){
            var CoursetroContract = new web3.eth.Contract(BEP721,config.singleContract);
            let contract = (Burntoken.type === config.singleType) ? config.singleContract : item.contractAddress; 
            setBurnLoading('processing');
            CoursetroContract.methods
                .burn(
                  Burntoken.tokenCounts,
                )
                .send({ from: UserAccountAddr })
                .then(async (result) => {
                    setBurnLoading('done');
                    var postData = {
                    tokenOwner: UserAccountAddr,
                    // tokenCreator:item.tokenCreator,
                    tokenCounts: Burntoken.tokenCounts,
                    blockHash: result.blockHash,
                    transactionHash: result.transactionHash,
                    contractAddress:Burntoken.contractAddress,
                    type:Burntoken.type,
                    balance:noofitems,
                    currAddr:UserAccountAddr,
                    quant:Burntoken.balance
                    }
                    var updateBurnField = await BurnField(postData)
                    if(updateBurnField){
                    toast.success('Burned successfully',toasterOption)
                    // window.$('#burn_token_modal').modal('hide');
                    document.getElementById('closeburn').click()
                    history.push('/nft')
                    }
                })
                .catch((error) => {
                            setBurnLoading('try');
                            toast.error('Order not placed', toasterOption);
                             setTimeout(() => window.location.reload(false), 900);
                        })
                      }else{
                        var CoursetroContract = new web3.eth.Contract(BEP1155,item.contractAddress);
                        let contract = (Burntoken.type === config.singleType) ? config.singleContract : item.contractAddress; 
                        if(noofitems>MyTokenDetail.balance){
                          toast.error('Available Tokens'+" "+MyTokenDetail.balance,toasterOption)
                          return false;
                        }
                        setBurnLoading('processing');

                        CoursetroContract.methods
                            .burn(
                                UserAccountAddr,
                                Burntoken.tokenCounts,
                                noofitems
                                // contract,
                                // MyTokenDetail.type,
                                 
                            )
                            .send({ from: props.Accounts })
                            .then(async (result) => {
                                setBurnLoading('done');
                                var postData = {
                                tokenOwner: UserAccountAddr,
                                // tokenCreator:item.tokenCreator,
                                tokenCounts: Burntoken.tokenCounts,
                                blockHash: result.blockHash,
                                transactionHash: result.transactionHash,
                                contractAddress:Burntoken.contractAddress,
                                type:Burntoken.type,
                                balance:noofitems,
                                currAddr:UserAccountAddr,
                                quant:Burntoken.balance
                                }
                                var updateBurnField = await BurnField(postData);
                                if(updateBurnField){
                                toast.success('Burned successfully',toasterOption)
                                // window.$('#burn_token_modal').modal('hide');
                                document.getElementById('closeburn').click()
                                history.push('/nft')
                                }
                            })
                            .catch((error) => {
                                        setBurnLoading('try');
                                        toast.error('Order not placed', toasterOption);
                                        setTimeout(() => window.location.reload(false), 900);
                                    })
                      }
        }
     }

  }
  const TokenCounts_Get_Detail_Call = async (payload) => {
    var curAddr = await getCurAddr();
    setshowingLoader(true)
    var Resp = await TokenCounts_Get_Detail_Action(payload);
    setTimeout(() => {
      // setshowingLoader(true)
      // change
      setshowingLoader(false)
    }, 2000);

    if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {

      var TokenResp = Resp.data.Detail.Resp;
      if (
        TokenResp
        && TokenResp.Token
        && TokenResp.Token[0]
        && TokenResp.Token[0].tokenowners_current
      ) {
        for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
          const element = TokenResp.Token[0].tokenowners_current[i];
          set_owner_price(element)
          if (element.tokenPrice > 0 && element.tokenOwner != curAddr) {
            Set_BuyOwnerDetailFirst(element);
            break;
          }
          if (element.tokenPrice > 0 && element.tokenOwner == curAddr) {
            Set_OwnersDetailFirst(element);
            break;
          }
        }
      }
      Set_tokenCounts_Detail(TokenResp);
      //  console.log("slasjakljkasjdsajdasdlasj",TokenResp)
      if (TokenResp.Bids) {
        Set_Bids(TokenResp.Bids);
      }

      var IndexVal = -1;

      if (TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        IndexVal = tokenowners_all.findIndex(val => (val.tokenOwner.toString() == curAddr.toString() && val.balance > 0));
      }
      // console.log("check all val")
    
      if (IndexVal > -1) {
        // console.log("check all val1",tokenowners_all)
        Set_MyTokenBalance(tokenowners_all[IndexVal].balance);
        Set_MyTokenDetail(tokenowners_all[IndexVal])
      }
      else {
        Set_MyTokenDetail({});
        Set_MyTokenBalance(0);
      }

      if (TokenResp.Token && TokenResp.Token[0]) {
        //(TokenResp.Token[0]);
        Set_item1(TokenResp.Token[0]);
         console.log("tokenvaluesss",TokenResp.Token[0])
      }
    
  }
  }
  const handleChange = (e) => {
    
      if(MyTokenDetail>=e && e.target && typeof e.target.value != 'undefined'){
        setnoofitem(e.target.value)
        setBurnLoading('init');
      }
      else if(e.target.value == 0){
        setBurnLoading('zero');
        setnoofitem(0)
      }
      else if(e.target.value == "" ){
        setBurnLoading('empty');
        setnoofitem("")
      }else if(e.target.value == undefined ){
        setBurnLoading('empty');
        setnoofitem("")
      }
      else{
        setBurnLoading('errors');
        setnoofitem("")
      }
  }
  useImperativeHandle(
      ref,
      () => ({
          async Burn_Click(item,burndata) {
            console.log("Burn_Click",item,burndata)
            var connectwallet=localStorage.getItem("xdtitfnbolav");
            if(!connectwallet){
              toast.error("Please connect to a Metamask wallet", toasterOption);
              return false;
            }
            setBurndata(burndata);
              var curAddr = await getCurAddr();
              // alert(curAddr)
                // if(!(window.ethereum)){
                  var payload = {
                    curAddr: curAddr,
                    tokenCounts: item.tokenCounts
                  };
                  TokenCounts_Get_Detail_Call(payload);
              window.$('#burn_token_modal').modal('show');
             
          },
      }),
  )

  return (
      <div class="modal fade primary_modal" id="burn_token_modal" tabindex="-1" role="dialog" aria-labelledby="burn_token_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
    <h5 class="modal-title" id="burn_token_modalLabel">Burn token</h5>
    <button type="button" id="closeburn" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body px-0">
      <form className="bid_form">
      <div className="px-4">
        {Burndata.type!=721&&
          <p className="text-light text-center" style={{color:'black',fontWeight:'bold'}}>{MyTokenDetail.balance} Tokens Available</p>
        }
          </div> 
        <div className="px-4">
          <p className="text-light">Are you sure to burn this token? This action cannot be undone. Token will be transfered to zero address</p>
        </div>  
        {Burndata.type==721?    

        <div className="px-4 mt-4 pt-2">               
          {/* <input 
            id = "burn" 
            name="burn" 
            class="form-control"
            value={1}
          /> */}
          <div className="text-center mt-3">
            <Button 
              className="burn_btn_red primary_btn btn-block" 
              onClick={(() => FormSubmit(item,MyTokenDetail))}
              disabled = { (burnLoading == 'done' || burnLoading=='processing' ||burnLoading == 'errors') }
              >
                {burnLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                {burnLoading == 'init' && 'Continue'}
                {burnLoading == 'processing' && 'In-progress...'}
                {burnLoading == 'done' && 'Done'}
                {burnLoading == 'try' && 'Try Again'}
                {burnLoading == 'errors' && 'Check Balance'}
                {/* {burnLoading == 'zero' && "Qty can't be Zero"} */}
                {burnLoading == 'empty' && "Continue"} 
                
              </Button>
            <Button className="btn_outline_grey cancel_btn btn-block" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>Cancel</Button>
          </div>
        </div>
      :
      <div className="px-4 mt-4 pt-2">               
      <input 
         id = "burn" 
         name="burn" 
         type="number"
         class="form-control"
         autoComplete="off"
         value={noofitems}
         onKeyDown={(evt) => ["e", "E", "+", "-",","].includes(evt.key) && evt.preventDefault()} 
         onChange = {(e) => { handleChange(e)}}
         min="0"
       />
        <div className="text-center mt-3">
                    <Button 
                      className="burn_btn_red primary_btn btn-block" 
                      onClick={(() => FormSubmit(item1,MyTokenDetail))}
                      disabled = { (burnLoading == 'done' || burnLoading=='processing' ||burnLoading == 'errors') }
                      >
                        {burnLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                        {burnLoading == 'init' && 'Continue'}
                        {burnLoading == 'processing' && 'In-progress...'}
                        {burnLoading == 'done' && 'Done'}
                        {burnLoading == 'try' && 'Try Again'}
                        {burnLoading == 'errors' && 'Check Balance'}
                        {/* {burnLoading == 'zero' && "Qty can't be Zero"} */}
                        {burnLoading == 'empty' && "Continue"} 
                        
                      </Button>
                    <Button className="btn_outline_grey cancel_btn btn-block" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>Cancel</Button>
                  </div>
      </div>
        }

      </form>
    </div>
  </div>
  </div>
</div>
  )
})

