import React,{useEffect} from 'react'
import classNames from "classnames";
import { Link } from "react-router-dom";
// material ui
import { AppBar, Toolbar, IconButton, Button, MenuItem, Menu, Typography } from "@material-ui/core";
import { MenuOpen, MeetingRoom } from '@material-ui/icons';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import {
  getCurAddr,
  halfAddrShow
} from '../../actions/v1/User';
import config from '../../lib/config';
import valabi from '../../ABI/VALOBITDX'
import wethabi from '../../ABI/DETH'

const HeaderDashboard =(props)=> {
    const { classes, handleDrawerOpen, open, handleMenu, handleClose, anchorEl } = props;
    const [currAddr, setcurrAddr] = React.useState("");
    const [blns, setBln]          = React.useState(0);
    const [tokenbalance, settokenbalance]        = React.useState(0);
    const [wethbalance, setwethbalance]          = React.useState(0);

  useEffect(() => {
    getconnect();
  }, []);
    async function getconnect() {
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        try {
          if (typeof web3 !== "undefined") {
            window.ethereum.enable().then(async function () {
              const web3 = new Web3(window.web3.currentProvider);
               if(window.web3.currentProvider.networkVersion == config.networkVersion){
              if (window.web3.currentProvider.isMetaMask === true) {
                await web3.eth.getAccounts(async function (error, result) {
                  var currAddr = window.web3.eth.defaultAccount;
                  setcurrAddr(currAddr);
                  var web3 = new Web3(window.ethereum);
                  window.web3.eth.getBalance(result[0],async (error, balance) => {
                      var currbal = balance / 1000000000000000000;
                      setBln(currbal.toFixed(8));
                      var CoursetroContract = new web3.eth.Contract(valabi,config.ValobitdxAddr);
                      var tokenDethBln = await CoursetroContract.methods.balanceOf(currAddr).call();
                      var value = tokenDethBln / 1000000000000000000;
                      settokenbalance(value.toFixed(8));
                      var CoursetroContract1 = new web3.eth.Contract(wethabi,config.DETHtokenAddr);
                      var tokenDethBln1 = await CoursetroContract1.methods.balanceOf(currAddr).call();
                      var value1 = tokenDethBln1 / 1000000000000000000;
                      setwethbalance(value1.toFixed(8));
                    }
                  );
             
                });
              }
            }else{
              localStorage.removeItem("xdtitfnbolav");
              }
            });
          } else {
          //  toast.success("Please Add Metamask External", toasterOption);
          }
        } catch (err) {}
      } else {
       // toast.success("Please Add Metamask External", toasterOption);
      }
    }
    async function disconnectMetamask() {
      if (localStorage.getItem("xdtitfnbolav")) {
        localStorage.removeItem("xdtitfnbolav");
        window.location.reload(false)
      } 
    }
  return (
    <div>
      <AppBar
          position="fixed"
          className={classes.appBar + " dash_header"}
          fooJon={classNames(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar disableGutters={true}>
          <div className="topbar_left" >          
            <IconButton color="inherit" aria-label="Open drawer" onClick={handleDrawerOpen} className={classes.menuButton}>
              <MenuOpen
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed
                }}
              />
            </IconButton>
            <Link to="/" onClick={()=>window.location.href="https://valobitdx.io/"}><img src={require("../../assets/images/logo.png")} alt="Logo" className="logo_icon"/></Link>
          </div>
          <div className="topBarRight">
          {(!localStorage.getItem("xdtitfnbolav"))?(
            <Button className="primary_btn" data-toggle="modal" data-target="#wallet_modal">Connect</Button>
          ):(
            <>
              {/* <Button onClick={()=>disconnectMetamask()}>Logout</Button> */}
              <IconButton
              aria-owns={open ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className="profileButton"
            >
              
              <Button className="primary_btn nft_primary_btn">{halfAddrShow(currAddr)}</Button>
            </IconButton>
              <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
            <MenuItem>
              <div className="dropProfilePic">                  
                <Typography className="dropProfileName"><span>Balance in {config.currencySymbol}</span>: {blns}</Typography>
                <Typography className="dropProfileName"><span>Balance in {config.tokenSymbol}</span>: {tokenbalance}</Typography>
                <Typography className="dropProfileName"><span>Balance in {config.qualTokenSymbol}</span>: {wethbalance}</Typography>
              </div>
            </MenuItem>            
            <MenuItem>
            <Link to="/my-items" className="text-dark">My-Items</Link>

            </MenuItem>   
              <MenuItem onClick={()=>disconnectMetamask()}><MeetingRoom /> Logout</MenuItem>
            </Menu>
            </>

          )}
            {/* <a href="#"><Notifications /></a>
            <IconButton
              aria-owns={open ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className="profileButton"
            >
              <AccountCircle />
              <Typography>John Kennedy</Typography>
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
            <MenuItem>
              <div className="dropProfilePic">
                <img src={require("../../assets/images/profilepic.jpg")} alt="" className="img-fluid" />
                <Typography className="dropProfileName">John Kennedy</Typography>
              </div>
            </MenuItem>
              <MenuItem onClick={handleClose}>My Account</MenuItem>
              <MenuItem onClick={handleClose}><MeetingRoom /> Logout</MenuItem>
            </Menu> */}
          </div>
        </Toolbar>
        
      </AppBar>

      

    </div>
)
}

export default HeaderDashboard;