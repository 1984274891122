/*eslint-disable*/
import React, { useEffect, useRef } from "react";
import { Notifications, AccountBalanceWallet, FileCopy } from '@material-ui/icons';
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Button } from "@material-ui/core";
import $ from 'jquery';
import config from '../../lib/config';

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import {
    headerSearch
} from "../../actions/items"

const useStyles = makeStyles(styles);

export default function HeaderSearch(props) {

    const classes = useStyles();
    const [itemList, setitemList] = React.useState([]);
    const [userList, setuserList] = React.useState([]);
    const [Keyword, Set_Keyword] = React.useState('');
    const [searchShow, setsearchShow] = React.useState(false);

    useEffect(() => {
        getSearchText();
    }, []);


    const searchChange = async (e) => {
        var value = e.target.value;
        console.log(e.target.value,"=================mychange")
        if (value && value != "") {
            Set_Keyword(value);
            var response = await headerSearch({ search: value });

            setitemList(response.result);
            setuserList(response.userList);
            setsearchShow(true);
        }else{
            setitemList([]);
            setuserList([]);
            Set_Keyword("");
            window.location = "/nft";
        }
    }

    const keyPress = async (e) => {
        if (e.keyCode == 13) {
            console.log('value', e.target.value);
             window.location = "/nft?query=" + e.target.value;
          //  history.push("/nft?query=" + e.target.value)
        }
    }

    async function searchPage() {
        window.location = "/nft?query=" + Keyword;
        // history.push("/nft?query=" + Keyword)
    }

    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    function getSearchText(){
        var searchtxt = getParameterByName('query');
        if(searchtxt){
            Set_Keyword(searchtxt)
        }
    }

    return (
        <List className={classes.list + " main_navbar"}>
            <ListItem className={classes.listItem}>

                <div className="search_inp_group">
                    <input type="text" onKeyDown={keyPress} value={Keyword} onChange={searchChange} className="search_inp" placeholder="Search collections " />
                    <div className="search_inp_group_append">
                        <i className="fas fa-search"></i>
                    </div>
                </div>
                {searchShow &&
                    <div className="topSearchPopup noti_parent noti_parnt_user usemneu_dd" id="usemneu_dd" >

                        {itemList && itemList.length > 0 &&
                            <div className="Item_list">
                                <h3 className="font_we_700"><b>Items</b></h3>
                                <ul className="noti_ul_dd search_list">
                                    {
                                        itemList.map((item) => {
                                            console.log("search_list:",item)
                                            return (
                                                <li>
                                                    <Link to={"/info/" + item.tokenCounts}>
                                                                {
                                                                item.image.split('.').pop() == "mp4" ?
                                                                <video src={`${config.ipfsurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid search search_img" />
                                                                :
                                                                item.image.split('.').pop() == "mp3" ?
                                                                <img src={`${config.Back_URL}/images/music.png`} alt="" className="img-fluid search_imgmp3 search_img"/>
                                                                :
                                                                <img src={`${config.ipfsurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid search_img" />
                                                            }
                                                        <div className="search_filter_txt">
                                                            <h2>{item.tokenName}</h2>
                                                            <p>
                                                                {(item.PutOnSale == false || (item.PutOnSale == true && item.PutOnSaleType == 'FixedPrice' && item&&item.tokenowners_current&&item.tokenowners_current.tokenPrice == 0)) && <span>Not for sale </span>}
                                                                {(item.PutOnSale == true && item.PutOnSaleType == 'FixedPrice' && item&&item.tokenowners_current&&item.tokenowners_current.tokenPrice > 0) && <span>{item&&item.tokenowners_current&&item.tokenowners_current.tokenPrice} {config.currencySymbol} </span>}
                                                                {(item.PutOnSale == true && item.PutOnSaleType == 'TimedAuction') && <span>Minimum Bid </span>}
                                                                {(item.PutOnSale == true && item.PutOnSaleType == 'UnLimitedAuction') && <span>Open sor Bids </span>}
                                                                {item&&item.tokenowners_current&&item.tokenowners_current.balance} of {item&&item.tokenowners_current&&item.tokenowners_current.quantity}
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <div className="text-center">
                            <button onClick={searchPage} class="primary_btn mb-2 mt-2" tabindex="0" type="button">
                                <span>All Results </span>
                            </button>
                        </div>
                            </div>
                        }

                        {userList && userList.length > 0 &&
                            <div className="Item_list">
                                <p className="font_we_700 mb-0">Users</p>
                                <ul className="noti_ul_dd">
                                    {
                                        userList.map((item) => {

                                            var imageUrl = (item.image && item.image != "") ?
                                                config.Back_URL + "/images/noimage.png" : config.Back_URL + "/images/noimage.png";

                                            var followers = (item.followers && item.followers > 0) ? item.followers : "0"

                                            return (
                                                <li>
                                                    <Link to={"/user/" + item.curraddress}>
                                                        <img src={imageUrl} alt="Collections" className="img-fluid " width="30px" height="50px" />
                                                        <div className="search_filter_txt">
                                                            <h2>{item.name}</h2>
                                                            <p>{item.numberOfFollower} Followers</p>
                                                        </div>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                          
                        
                          </div>
                        }
                    </div>
                }

            </ListItem>
        </List>
    );
}
