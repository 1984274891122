import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import './index.css';
import './nft.css';

// pages for this product
import Home from "views/home.js";
import NFT from "views/nft";
import Stake from "views/stake";
import Create from "views/create";
import CreateSingleandMultiple from "views/create-single-multiple.js";
import createMultiple from "views/create-multiple";
import EditProfile from "views/edit-profile";
import Info from "views/info";
import Myitems from "views/my-items";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


ReactDOM.render(
  <BrowserRouter basename="/">
      <ToastContainer/>
    <Switch>
      <Route path="/info/:tokenidval" component={Info} />
      <Route path="/create-multiple" component={CreateSingleandMultiple} />
      <Route path="/create-single" component={CreateSingleandMultiple} />
      <Route path="/create" component={Create} />
      <Route path="/my-items" component={Myitems} />
      <Route path="/edit-profile" component={EditProfile} />
      <Route path="/user/:paramAddress" component={Myitems} />
      <Route path="/nft" component={NFT} />
      <Route path="/stake" component={Stake} />
      {/* <Route path="/home" component={Home} /> */}
      <Route exact path="/*" component={Home}>
        <Redirect to="/nft" />
      </Route>
      
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
