/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  return (
    <footer className={footerClasses + " section"}>
    
      <div className="footer_home">
        <div className={classes.container}>
          <div className="subscribe_section">
            <div className="container"> 
             {/* data-aos="fade-up" data-aos-duration="2000" */}
              <GridContainer className="justify-content-center" >
                
                <GridItem sm={12} md={12} lg={12}>
                <div className="footer_bottom_home">            
            <div className="copyright_txt">              
            <img src={require("../../assets/images/v_bit/logo.png")} alt="Social Icons" className="img-fluid" />
            <div className="footer_social_links_v_bit">
                    <ul>
                      <li><a href="#" target="_blank"><i class="fas fa-paper-plane"></i></a></li>
                      <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                      <li><a href="#" target="_blank"><i class="fab fa-reddit-alien"></i></a></li>
                      <li><a href="#" target="_blank"><i class="fab fa-medium-m"></i></a></li>




                          
                    </ul>
                  </div>
            </div>
          </div>
          <div className="footer_bottom_home">            
            <div className="copyright_txt">              
              <ul>                
                <li className="mr-3"><a href="/terms">Terms of Service</a></li> |
                <li className="ml-3"><a href="/privacy">Privacy Policy</a></li>
              </ul>
              <p className="mb-0">Copyright ©  2021 Valobitdex, All rights reserved</p>
            </div>
          </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
         
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
