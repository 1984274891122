import React, {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle
} from 'react';
import {
    useHistory
} from "react-router-dom";
import { Button, TextField } from '@material-ui/core';

import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import BEP721 from '../../ABI/BEP721.json';
import BEP1155 from '../../ABI/BEP1155.json';
import VALOBITDX from '../../ABI/VALOBITDX.json';
import WETH from '../../ABI/DETH.json';

import config from '../../lib/config';

import {
    AddLikeAction,
    GetLikeDataAction,
    TokenPriceChange_update_Action,
    PurchaseNow_Complete_Action,
    ActivitySection
} from '../../actions/v1/token';

import {
    getCurAddr,
    halfAddrShow
} from '../../actions/v1/User';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;
const contractAddr = config.smartContract;
var web3 = new Web3(window.ethereum);
const VALOBITDXContract = new web3.eth.Contract(VALOBITDX, config.ValobitdxAddr);
const BEP721Contract = new web3.eth.Contract(BEP721, config.singleContract);


export const PurchaseNowRef = forwardRef((props, ref) => {

    const history = useHistory();

    var {
        UserAccountAddr,
        UserAccountBal,
        TokenBalance,
        MyItemAccountAddr,
        buytoken,
        AllowedQuantity,
        Set_AllowedQuantity
    } = props;

    const [ApproveCallStatus, setApproveCallStatus] = useState('init');
    const [PurchaseCallStatus, setPurchaseCallStatus] = useState('init');
    const [price, Set_Price] = useState(0);
    const [PurchaseCurrency, setPurchaseCurrency] = useState();
    const [BuyerName, Set_BuyerName] = useState('');
    const [blns, Set_blns] = useState('');
    const [dethBln, Set_dethBln] = useState('');
    const [bidProfile1, Set_bidProfile1] = useState([]);
    const [MultipleWei, Set_MultipleWei] = useState(0);
    const [NoOfToken, Set_NoOfToken] = useState(1);
    const [FormSubmitLoading, Set_FormSubmitLoading] = useState('');
    const [ValidateError, Set_ValidateError] = useState({});
    const [YouWillPay, Set_YouWillPay] = useState(0);
    const [TokenPrice, Set_TokenPrice] = useState(0);
    const [MetaMaskAmt, setMetaMaskAmt] = useState(0);
    const [servicefee, setservicefee] = useState(0);
    const [saletokenbal, setSaletoken] = useState(0);
    const [CalculateLoading, Set_CalculateLoading] = useState('');


    var feepercval = 0;
    var biddingtoken = ""
    var PurchaseBalancenew = 0
    if (buytoken != undefined) {
        feepercval = buytoken !== undefined && buytoken == config.currencySymbol ?
            servicefee
            :
            buytoken !== undefined && buytoken == config.tokenSymbol ?
                servicefee : servicefee;
    }
    else {
        feepercval = servicefee;
    }
    const [feeperc, set_feeperc] = useState(feepercval)
    const [biddingtokenaddress, set_biddingtokenaddress] = useState(biddingtoken);
    const [PurchaseBalance, set_PurchaseBalance] = useState(PurchaseBalancenew);
    const [PurchaseLoad, set_PurchaseLoad] = useState(true);

    async function buymodal() {
        setTimeout(() => window.$('#PurchaseNow_modal').modal('hide'), 600);
        setTimeout(() => window.location.reload(false), 900);
    }
    const PriceCalculate = async (data = {}) => {
        try {
            if (window.ethereum) {
                var web3 = new Web3(window.ethereum);

                Set_CalculateLoading("processing")
                if (data.type == 721) {
                    var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                    if (data.PurchaseCurrency == config.currencySymbol) {
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                    } else if (data.PurchaseCurrency == config.tokenSymbol) {
                        var fee = await CoursetroContract.methods.getValobitdxFee().call();
                    } else {
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                    }
                    var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
                    var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;
                    var newPrice = data.type == 721 ? price * config.decimalvalues :
                        quantity * (price * config.decimalvalues);
                    var per = (newPrice * fee) / 1e20;
                    var mulWei = newPrice + per;
                    Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                    Set_MultipleWei(mulWei);
                    Set_Price(newPrice)
                } else {

                    var contractAddress = (item && item.contractAddress) ? item.contractAddress : data.contractAddress;

                    var CoursetroContract = new web3.eth.Contract(BEP1155, contractAddress);
                    if (data.PurchaseCurrency == config.currencySymbol) {
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                        var feeValue = fee / config.decimalvalues;
                        var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
                        var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;
                        var newPrice = quantity * (price * config.decimalvalues);
                        var per = (newPrice * fee) / 1e20;
                        var mulWei = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                        Set_MultipleWei(mulWei);
                        Set_Price(newPrice)
                    } else if (data.PurchaseCurrency == config.tokenSymbol) {
                        var fee = await CoursetroContract.methods.getValobitdxFee().call();

                        var feeValue = fee / config.decimalvalues;
                        var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
                        var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;
                        var newPrice = quantity * (price * config.decimalvalues);
                        var per = (newPrice * fee) / 1e20;
                        var mulWei = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                        Set_MultipleWei(mulWei);
                        Set_Price(newPrice)
                    } else {
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                        var feeValue = fee / config.decimalvalues;
                        var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
                        var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;
                        var newPrice = quantity * (price * config.decimalvalues);
                        var per = (newPrice * fee) / 1e20;
                        var mulWei = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                        Set_MultipleWei(mulWei);
                        Set_Price(newPrice)
                    }

                }
                Set_CalculateLoading("")
            }
        } catch (err) {

        }
    }
    const onKeyUp = (e) => {
        var charCode = e.keyCode;
        if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
            var ValidateError = {};
            Set_ValidateError(ValidateError);
        } else {
            var ValidateError = {};
            ValidateError.NoOfToken = '"Quantity" must be a number';
            Set_NoOfToken("");
            Set_ValidateError(ValidateError);
        }
    }

    const inputChange = (e) => {
        if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
            var value = e.target.value;
            switch (e.target.name) {
                case 'NoOfToken':
                    Set_NoOfToken(value);
                    if (value != '' && isNaN(value) == false && value > 0) {
                        Set_CalculateLoading("processing")
                        PriceCalculate({ quantity: value, PurchaseCurrency: PurchaseCurrency, contractAddress: item.contractAddress });
                    }
                    break;
                case 'TokenPrice':
                    Set_TokenPrice(value);
                    if (value != '' && isNaN(value) == false && value > 0) {
                        Set_CalculateLoading("processing")
                        PriceCalculate({ price: value, PurchaseCurrency: PurchaseCurrency, contractAddress: item.contractAddress });
                    }
                    break;
                default:
                // code block
            }
            // ItemValidation({TokenPrice:value});
        }
    }
    const ItemValidation = async (data = {}) => {
        var ValidateError = {};

        var Chk_TokenPrice = (typeof data.TokenPrice != 'undefined') ? data.TokenPrice : TokenPrice;
        var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;

        var Collectible_balance = 0;
        if (item && item.tokenowners_current && item.tokenowners_current.balance) {
            Collectible_balance = item.tokenowners_current.balance;
        }

        if (quantity == '') {
            ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
        }
        else if (quantity == 0) {
            ValidateError.NoOfToken = '"Quantity" must be greater than 0';;
        }
        else if (isNaN(quantity) == true) {
            ValidateError.NoOfToken = '"Quantity" must be a number';
        }
        if (quantity > Collectible_balance) {
            ValidateError.NoOfToken =
                '"Quantity" must be below on ' + Collectible_balance;
        }
        // if (quantity > AllowedQuantity) {
        //     ValidateError.NoOfToken =
        //       '"Quantity" must be below on ' + AllowedQuantity;
        //   }

        if (Chk_TokenPrice == '') {
            ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
        }
        else if (Chk_TokenPrice == 0) {
            ValidateError.TokenPrice = '"Token Price" must be greater than 0';;
        }
        else if (isNaN(Chk_TokenPrice) == true) {
            ValidateError.TokenPrice = '"Token Price" must be a number';
        }
        else if (YouWillPay > PurchaseBalance) {
            ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
        }
        else {
            await props.GetUserBal();
            if (YouWillPay > PurchaseBalance) {
                ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
            }
            else {
                delete ValidateError.TokenPrice;
            }
        }
        Set_ValidateError(ValidateError);
        return ValidateError;
    }


    function convert(n) {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    }

    async function FormSubmit() {

        Set_FormSubmitLoading('processing');

        var errors = await ItemValidation();
        var errorsSize = Object.keys(errors).length;

        if (errorsSize != 0) {
            toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
            Set_FormSubmitLoading('');
            return false;
        }


        if (window.ethereum) {
            var web3 = new Web3(window.ethereum)
            if (
                web3
                && web3.eth
            ) {
                if (PurchaseCurrency != config.currencySymbol) {
                    if (PurchaseCurrency == config.tokenSymbol) {
                        var VBitdxContract = new web3.eth.Contract(VALOBITDX, config.ValobitdxAddr);
                        var currAddr = window.web3.eth.defaultAccount;
                        var tokenBal = await VBitdxContract.methods.balanceOf(currAddr).call();
                        var tokenBalance = tokenBal / config.decimalvalues
                        if (tokenBalance >= parseFloat(YouWillPay)) {
                            window.$('.modal').modal('hide');
                            window.$('#PurchaseStep_modal').modal('show');
                        } else {
                            toast.error("Insufficient balance", toasterOption);
                        }

                    }
                    else if (PurchaseCurrency == config.currencySymbol) {

                        if (PurchaseBalance >= parseFloat(YouWillPay)) {
                            window.$('.modal').modal('hide');
                            window.$('#PurchaseStep_modal').modal('show');
                        } else {
                            toast.error("Insufficient balance", toasterOption);
                        }
                    }
                }
                else {

                    var tokenPrice = (item.tokenowners_current && item.tokenowners_current.tokenPrice) ? item.tokenowners_current.tokenPrice : 0

                    if (tokenPrice && tokenPrice > 0) {
                        if (item.type == 721) {
                            if (PurchaseBalance == 0) {
                                toast.error("Insufficient balance", toasterOption);
                                return false;
                            }

                            if (YouWillPay > PurchaseBalance) {
                                toast.error("Insufficient balance", toasterOption);
                                return false;
                            }
                            var tokenContractAddress = item.contractAddress.toString();
                            var tokenType = item.type.toString();
                            var bal = parseInt(item.balance);
                            var web3 = new Web3(window.ethereum);

                            const BEP721Contract = new web3.eth.Contract(BEP721, item.contractAddress)
                            var sendAmount = (item.tokenowners_current.tokenPrice * config.decimalvalues).toString();
                            Set_FormSubmitLoading('processing');

                            var tokenaddress = PurchaseCurrency == config.currencySymbol ?
                                config.singleContract
                                :
                                PurchaseCurrency == config.tokenSymbol ?
                                    config.ValobitdxAddr
                                    :
                                    "";
                            BEP721Contract.methods
                                .saleToken(
                                    item.tokenowners_current.tokenOwner,
                                    item.tokenCounts,
                                    convert(sendAmount),
                                    tokenaddress
                                )
                                .send({
                                    from: props.Accounts,
                                    value: MultipleWei
                                })
                                .then(async (result) => {
                                    Set_FormSubmitLoading('done');

                                    var postData = {
                                        tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                                        UserAccountAddr: UserAccountAddr, // new owner
                                        tokenCounts: item.tokenCounts,
                                        tokenType: item.type,
                                        NoOfToken: item.type == 721 ? 1 : NoOfToken,
                                        transactionHash: result.transactionHash,
                                        PurchaseCurrency: PurchaseCurrency
                                    }
                                    var Resp = await PurchaseNow_Complete_Action(postData);

                                    if (Resp.data && Resp.data.toast && Resp.data.toast.type == 'success') {
                                        toast.success("Collectible purchase successfully", toasterOption)
                                        window.$('.PurchaseNow_modal').modal('hide');
                                        setTimeout(() => { history.push("/my-items") }, 2000);
                                    }
                                })
                                .catch((error) => {
                                    Set_FormSubmitLoading('error');

                                    toast.error('Order not placed', toasterOption);
                                })
                        } else {
                            var tokenContractAddress = item.contractAddress.toString();
                            var tokenType = item.type.toString();
                            var bal = parseInt(item.balance);
                            var web3 = new Web3(window.ethereum);
                            Set_FormSubmitLoading('processing');
                            var tokenPrice = (item.tokenowners_current && item.tokenowners_current.tokenPrice) ? item.tokenowners_current.tokenPrice : 0

                            var owneradd = item.tokenowners_current.tokenOwner
                            var sendAmount = (tokenPrice * config.decimalvalues).toString();
                            var lastAmt = (sendAmount * NoOfToken).toString()
                            var tokenaddress = PurchaseCurrency == config.currencySymbol ?
                                item.contractAddress
                                :
                                PurchaseCurrency == config.tokenSymbol ?
                                    config.ValobitdxAddr
                                    : "";


                            const BEP1155Contract = new web3.eth.Contract(BEP1155, item.contractAddress)
                            BEP1155Contract.methods
                                .saleToken(
                                    item.tokenowners_current.tokenOwner,
                                    item.tokenCounts,
                                    convert(lastAmt),
                                    NoOfToken,
                                    tokenaddress
                                )
                                .send({
                                    from: props.Accounts,
                                    value: MultipleWei
                                })
                                .then(async (result) => {
                                    Set_FormSubmitLoading('done');
                                    var postData = {
                                        tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                                        UserAccountAddr: UserAccountAddr, // new owner
                                        tokenCounts: item.tokenCounts,
                                        tokenType: item.type,
                                        NoOfToken: item.type == 721 ? 1 : NoOfToken,
                                        transactionHash: result.transactionHash,
                                    }
                                    var Resp = await PurchaseNow_Complete_Action(postData);
                                    if (Resp.data && Resp.data.toast && Resp.data.toast.type == 'success') {
                                        toast.success("Collectible purchase successfully", toasterOption)
                                        window.$('.modal').modal('hide');
                                        setTimeout(() => { history.push("/my-items") }, 2000);
                                    }

                                })
                                .catch((error) => {
                                    Set_FormSubmitLoading('error');
                                    toast.error('Order not placed', toasterOption);
                                })
                        }
                    } else {
                        toast.error('Oops something went wrong.!', toasterOption);

                    }


                }
            }
        }
    }
    async function FormSubmit_StepOne() {
        if (window.ethereum) {
            var web3 = new Web3(window.ethereum)
            if (
                web3
                && web3.eth
            ) {
                const BEP1155Contract = new web3.eth.Contract(BEP1155, item.contractAddress)
                setApproveCallStatus('processing');
                if (item.type == 721) {
                    var CoursetroContract = PurchaseCurrency == config.tokenSymbol ?
                        VALOBITDXContract
                        :
                        PurchaseCurrency == config.currencySymbol ?
                            BEP721Contract
                            :
                            VALOBITDXContract

                } else {
                    var CoursetroContract = PurchaseCurrency == config.tokenSymbol ?
                        VALOBITDXContract
                        :
                        PurchaseCurrency == config.currencySymbol ? BEP1155Contract
                            :
                            VALOBITDXContract
                }


                var currAddr = window.web3.eth.defaultAccount;

                if (item.type == 721) {
                    var getAllowance = await VALOBITDXContract.methods.allowance(currAddr, config.singleContract).call();
                } else {
                    var getAllowance = await VALOBITDXContract.methods.allowance(currAddr, item.contractAddress).call();
                }
                const Contractaddress = item.contractAddress;

                var sendVal = parseFloat(MultipleWei) + parseFloat(getAllowance);
                var approvebal = convert(sendVal);
                await CoursetroContract.methods
                    .approve(
                        Contractaddress,
                        approvebal.toString()
                    )
                    .send({
                        from: props.Accounts,
                    })
                    .then(async (result) => {
                        setApproveCallStatus('done');
                        toast.success("Collectible purchase successfully", toasterOption)
                    })
                    .catch((error) => {
                        setApproveCallStatus('tryagain');
                        toast.error('Order not approved', toasterOption);
                    })
            }
        }
    }

    async function FormSubmit_StepTwo() {
        if (window.ethereum) {
            var web3 = new Web3(window.ethereum)
            if (
                web3
                && web3.eth
            ) {
                if (PurchaseCurrency == config.tokenSymbol) {
                    var VBitdxContract = new web3.eth.Contract(VALOBITDX, config.ValobitdxAddr);
                    var currAddr = window.web3.eth.defaultAccount;
                    var tokenBal = await VBitdxContract.methods.balanceOf(currAddr).call();
                    var tokenBalance = tokenBal / config.decimalvalues;
                    if (tokenBalance == 0) {
                        toast.error(PurchaseCurrency + " Token Balance Insufficient", toasterOption)
                        return false;
                    }
                    if (YouWillPay >= tokenBalance) {
                        toast.error(PurchaseCurrency + " Token Balance Insufficient", toasterOption)
                        return false;
                    }
                } else {

                    if (PurchaseBalance == 0) {
                        toast.error("Insufficient" + PurchaseCurrency + "  Balance", toasterOption)
                        return false;
                    }
                    if (YouWillPay >= PurchaseBalance) {
                        toast.error("Insufficient" + PurchaseCurrency + "  Balance", toasterOption)
                        return false;
                    }
                }


                if (item.type == 721) {
                    const BEP721Contract = new web3.eth.Contract(BEP721, item.contractAddress)
                    var sendAmount = (item && item.tokenowners_current.tokenPrice * config.decimalvalues).toString();
                    var saleTokenval = convert(sendAmount);
                    var tokenaddress = PurchaseCurrency == config.tokenSymbol ?
                        config.ValobitdxAddr
                        :
                        PurchaseCurrency == config.currencySymbol ?
                            config.singleContract
                            :
                            "";

                    var status = PurchaseCurrency == config.tokenSymbol ?
                        true
                        :
                        false;
                    setPurchaseCallStatus('processing');
                    BEP721Contract.methods
                        .salewithToken(
                            PurchaseCurrency == config.tokenSymbol ?
                                config.tokenSymbol
                                :
                                config.currencySymbol,
                            item.tokenowners_current.tokenOwner,
                            item.tokenCounts,
                            saleTokenval.toString(),
                            tokenaddress,
                            // status
                        )
                        .send({
                            from: props.Accounts
                        })
                        .then(async (result) => {
                            setPurchaseCallStatus('done');
                            var postData = {
                                tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                                UserAccountAddr: UserAccountAddr, // new owner
                                tokenCounts: item.tokenCounts,
                                tokenType: item.type,
                                NoOfToken: item.type == 721 ? 1 : NoOfToken,
                                transactionHash: result.transactionHash,
                                PurchaseCurrency: PurchaseCurrency,
                            }
                            var Resp = await PurchaseNow_Complete_Action(postData);
                            if (Resp.data && Resp.data.toast && Resp.data.toast.type == 'success') {
                                toast.success("Collectible purchase successfully", toasterOption)
                                window.$('.modal').modal('hide');
                                setTimeout(() => { window.location.reload(); }, 2000);
                            }
                        })
                        .catch((error) => {
                            setPurchaseCallStatus('tryagain');
                            toast.error('Order not placed', toasterOption);
                        })
                } else {
                    const BEP1155Contract = new web3.eth.Contract(BEP1155, item.contractAddress)
                    var sendAmount = (item && item.tokenowners_current.tokenPrice && item.tokenowners_current.tokenPrice * config.decimalvalues).toString();
                    var lastAmt = (sendAmount * NoOfToken).toString();

                    setPurchaseCallStatus('processing');
                    var tokenaddress = "";
                    if (PurchaseCurrency == config.tokenSymbol) {
                        tokenaddress = config.ValobitdxAddr
                    } else {
                        tokenaddress = item.contractAddress;
                    }

                    BEP1155Contract.methods
                        .saleWithToken(
                            item.tokenowners_current.tokenOwner,
                            item.tokenCounts,
                            convert(lastAmt),
                            NoOfToken,
                            biddingtokenaddress == config.ValobitdxAddr ?
                                config.tokenSymbol
                                :
                                config.currencySymbol,
                            tokenaddress
                        )
                        .send({
                            from: props.Accounts
                        })
                        .then(async (result) => {
                            setPurchaseCallStatus('done');
                            var postData = {
                                tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                                UserAccountAddr: UserAccountAddr, // new owner
                                tokenCounts: item.tokenCounts,
                                tokenType: item.type,
                                NoOfToken: NoOfToken,
                                transactionHash: result.transactionHash,
                                PurchaseCurrency: PurchaseCurrency,

                            }
                            var Resp = await PurchaseNow_Complete_Action(postData);
                            if (Resp.data && Resp.data.toast && Resp.data.toast.type == 'success') {
                                toast.success("Collectible purchase successfully", toasterOption)
                                window.$('.modal').modal('hide');
                                setTimeout(() => { window.location.reload(); }, 2000);
                            }
                        })
                        .catch((error) => {
                            setPurchaseCallStatus('tryagain');
                            toast.error('Order not placed', toasterOption);
                        })
                }

            }
        }
    }

    const [item, Set_item] = React.useState(props.item);

    async function balanceCheck() {
        try {
            var web3 = new Web3(window.ethereum);
            var VALOBITDXContract = new web3.eth.Contract(VALOBITDX, config.ValobitdxAddr);
            var currAddr = window.web3.eth.defaultAccount;
            var tokenBal = await VALOBITDXContract.methods.balanceOf(currAddr).call();
            var tokenBalance = tokenBal / config.decimalvalues;
            Set_dethBln(tokenBalance)
        } catch (err) {
        }
    }
    async function getServicefees() {
        try {
            if (window.ethereum) {
                var web3 = new Web3(window.ethereum);
                if (item.type == 721) {
                    var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                    if (PurchaseCurrency == config.currencySymbol) {
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                    } else if (PurchaseCurrency == config.tokenSymbol) {
                        var fee = await CoursetroContract.methods.getValobitdxFee().call();
                    } else {
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                    }
                } else {
                    var CoursetroContract = new web3.eth.Contract(BEP1155, item.contractAddress);
                    if (PurchaseCurrency == config.currencySymbol) {
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                    } else if (PurchaseCurrency == config.tokenSymbol) {
                        var fee = await CoursetroContract.methods.getValobitdxFee().call();
                    } else {
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                    }
                }
                var feeValue = fee / config.decimalvalues;
                setservicefee(feeValue);
            }
        } catch (err) {
        }
    }
    useEffect(() => {
        Set_ValidateError({});
        balanceCheck();
        getServicefees();
        ItemValidation({ TokenPrice: TokenPrice });
    }, [
        TokenPrice
    ]);



    useImperativeHandle(
        ref,
        () => ({
            async PurchaseNow_Click(item, BuyOwnerDetail = {}) {
                window.$('#PurchaseNow_modal').modal('show');
                var curAddr = await getCurAddr();
                var connectwallet = localStorage.getItem("xdtitfnbolav");
                if (!connectwallet) {
                    toast.error("Please connect to a Metamask wallet", toasterOption);
                    return false;
                }
                Set_CalculateLoading("processing")

                PriceCalculate({
                    quantity: 1,
                    price: BuyOwnerDetail.tokenPrice,
                    type: BuyOwnerDetail.type,
                    PurchaseCurrency: BuyOwnerDetail.biddingtoken,
                    contractAddress: item.contractAddress
                });



                var web3 = new Web3(window.ethereum);
                web3.eth.getBalance(props.Accounts)
                    .then(val => {
                        var balance = val / config.decimalvalues;
                        set_PurchaseBalance(balance)
                    })
                set_PurchaseLoad(false)
                if (BuyOwnerDetail && typeof BuyOwnerDetail.tokenOwner != 'undefined') {
                    item.tokenowners_current = {};
                    item.tokenowners_current = BuyOwnerDetail;
                    setPurchaseCurrency(BuyOwnerDetail.biddingtoken)
                    setSaletoken(BuyOwnerDetail.balance)
                }
                if (BuyOwnerDetail.biddingtoken == config.currencySymbol) {
                    if (BuyOwnerDetail.type == 721) {
                        var web3 = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                    } else {
                        var web3 = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP1155, item.contractAddress);
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                    }

                    configFee = fee / config.decimalvalues;
                    setservicefee(configFee);
                    set_feeperc(configFee);
                    var newPrice = BuyOwnerDetail.type == 721 ? BuyOwnerDetail.tokenPrice * config.decimalvalues :
                        NoOfToken * (BuyOwnerDetail.tokenPrice * config.decimalvalues);
                    var per = (newPrice * fee) / 1e20;
                    var mulWei = newPrice + per;
                    Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                    Set_MultipleWei(mulWei);
                    Set_Price(newPrice)
                    web3.eth.getBalance(props.Accounts)
                        .then(val => {
                            var balance = val / config.decimalvalues;
                            set_PurchaseBalance(balance)
                            set_PurchaseLoad(true)
                        })
                } else if (BuyOwnerDetail.biddingtoken == config.tokenSymbol) {

                    if (BuyOwnerDetail.type == 721) {
                        var web3 = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                        var fee = await CoursetroContract.methods.getValobitdxFee().call();
                    } else {
                        var web3 = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP1155, item.contractAddress);
                        var fee = await CoursetroContract.methods.getValobitdxFee().call();

                    }
                    configFee = fee / config.decimalvalues;
                    setservicefee(configFee);
                    set_feeperc(configFee);
                    var newPrice = BuyOwnerDetail.type == 721 ?
                        BuyOwnerDetail.tokenPrice * config.decimalvalues :
                        NoOfToken * (BuyOwnerDetail.tokenPrice * config.decimalvalues);
                    var per = (newPrice * fee) / 1e20;
                    var mulWei = newPrice + per;
                    Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                    var fee = await CoursetroContract.methods.getValobitdxFee().call();
                    var web3 = new Web3(window.ethereum);
                    const VBitdxContract = new web3.eth.Contract(VALOBITDX, config.ValobitdxAddr);
                    var aidibalanceinwei = await VBitdxContract.methods.balanceOf(props.Accounts).call()

                    set_PurchaseBalance(aidibalanceinwei)
                    set_PurchaseLoad(true)
                } else {
                    if (BuyOwnerDetail.type == 721) {
                        var web3 = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                        if (BuyOwnerDetail.biddingtoken == config.currencySymbol) {
                            var fee = await CoursetroContract.methods.getServiceFee().call();
                            web3.eth.getBalance(props.Accounts)
                                .then(val => {
                                    var balance = val / config.decimalvalues;
                                    set_PurchaseBalance(balance)
                                    set_PurchaseLoad(true)
                                })
                        } else if (BuyOwnerDetail.biddingtoken == config.tokenSymbol) {
                            var fee = await CoursetroContract.methods.getValobitdxFee().call();
                            const VBitdxContract = new web3.eth.Contract(VALOBITDX, config.ValobitdxAddr);
                            var aidibalanceinwei = await VBitdxContract.methods.balanceOf(props.Accounts).call()
                            set_PurchaseBalance(aidibalanceinwei)
                            set_PurchaseLoad(true)
                        } else {
                            var fee = await CoursetroContract.methods.getServiceFee().call();
                            web3.eth.getBalance(props.Accounts)
                                .then(val => {
                                    var balance = val / config.decimalvalues;
                                    set_PurchaseBalance(balance)
                                    set_PurchaseLoad(true)
                                })
                        }
                    } else {
                        var web3 = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP1155, item.contractAddress);
                        if (BuyOwnerDetail.biddingtoken == config.currencySymbol) {
                            var fee = await CoursetroContract.methods.getServiceFee().call();
                            web3.eth.getBalance(props.Accounts)
                                .then(val => {
                                    var balance = val / config.decimalvalues;
                                    set_PurchaseBalance(balance)
                                    set_PurchaseLoad(true)
                                })
                        } else if (BuyOwnerDetail.biddingtoken == config.tokenSymbol) {
                            var fee = await CoursetroContract.methods.getValobitdxFee().call();
                            var web3 = new Web3(window.ethereum);
                            const VBitdxContract = new web3.eth.Contract(VALOBITDX, config.ValobitdxAddr);
                            var aidibalanceinwei = await VBitdxContract.methods.balanceOf(props.Accounts).call()
                            set_PurchaseBalance(aidibalanceinwei)
                            set_PurchaseLoad(true)
                        } else {
                            var fee = await CoursetroContract.methods.getServiceFee().call();
                            web3.eth.getBalance(props.Accounts)
                                .then(val => {
                                    var balance = val / config.decimalvalues;
                                    set_PurchaseBalance(balance)
                                    set_PurchaseLoad(true)
                                })
                        }
                    }
                    configFee = fee / config.decimalvalues;
                    setservicefee(configFee);
                    set_feeperc(configFee);
                    var newPrice = BuyOwnerDetail.type == 721 ? BuyOwnerDetail.tokenPrice * config.decimalvalues :
                        NoOfToken * (BuyOwnerDetail.tokenPrice * config.decimalvalues);
                    var per = (newPrice * fee) / 1e20;
                    var mulWei = newPrice + per;
                    Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                }

                if (BuyOwnerDetail.biddingtoken == config.tokenSymbol) {
                    var web3 = new Web3(window.ethereum);
                    const VBitdxContract = new web3.eth.Contract(VALOBITDX, config.ValobitdxAddr);
                    var aidibalanceinwei = await VBitdxContract.methods.balanceOf(props.Accounts).call()
                    set_PurchaseBalance(aidibalanceinwei)
                    set_PurchaseLoad(true)
                } else {
                    var web3 = new Web3(window.ethereum);
                    web3.eth.getBalance(props.Accounts)
                        .then(val => {
                            var balance = val / config.decimalvalues;
                            set_PurchaseBalance(balance)
                            set_PurchaseLoad(true)
                        })
                }
                if (item && item.tokenowners_current && item.tokenowners_current.tokenPrice) {
                    Set_item(item);
                    Set_TokenPrice(item.tokenowners_current.tokenPrice);
                    Set_NoOfToken(1);
                    if (item.type == 721) {
                        var tempbid = item.tokenowners_current.biddingtoken == config.tokenSymbol ?
                            config.ValobitdxAddr
                            :
                            item.tokenowners_current.biddingtoken == config.currencySymbol ?
                                config.singleContract : ""
                    } else {
                        var tempbid = item.tokenowners_current.biddingtoken == config.tokenSymbol ?
                            config.ValobitdxAddr :
                            item.tokenowners_current.biddingtoken == config.currencySymbol ?
                                item.contractAddress : ""

                    }
                    set_biddingtokenaddress(tempbid)

                    var configFee = 0
                    var configFee = 0
                    if (item.tokenowners_current.biddingtoken == config.currencySymbol) {
                        var web3 = new Web3(window.ethereum);
                        if (item.type == 721) {
                            var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                            var fee = await CoursetroContract.methods.getServiceFee().call();
                        } else {
                            var CoursetroContract = new web3.eth.Contract(BEP1155, item.contractAddress);
                            var fee = await CoursetroContract.methods.getServiceFee().call();
                        }
                        configFee = fee / config.decimalvalues;
                        set_feeperc(configFee);
                        web3.eth.getBalance(props.Accounts)
                            .then(val => {
                                var balance = val / config.decimalvalues;
                                set_PurchaseBalance(balance)
                                set_PurchaseLoad(true)
                            })
                    } else if (item.tokenowners_current.biddingtoken == config.tokenSymbol) {
                        var web3 = new Web3(window.ethereum);
                        if (item.type == 721) {
                            var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                            var fee = await CoursetroContract.methods.getValobitdxFee().call();
                        } else {
                            var CoursetroContract = new web3.eth.Contract(BEP1155, item.contractAddress);
                            var fee = await CoursetroContract.methods.getValobitdxFee().call();
                        }
                        configFee = fee / config.decimalvalues;
                        set_feeperc(configFee);

                    } else {
                        var web3 = new Web3(window.ethereum);
                        if (item.type == 721) {
                            var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                        } else {
                            var CoursetroContract = new web3.eth.Contract(BEP1155, item.contractAddress);
                        }
                        var fee = await CoursetroContract.methods.getServiceFee().call();

                        configFee = fee / config.decimalvalues;
                        set_feeperc(configFee);
                    }

                    if (item.tokenowners_current.biddingtoken == config.tokenSymbol) {
                        var web3 = new Web3(window.ethereum);
                        const VBitdxcontract = new web3.eth.Contract(VALOBITDX, config.ValobitdxAddr);
                        var aidibalanceinwei = await VBitdxcontract.methods.balanceOf(props.Accounts).call()
                        set_PurchaseBalance(aidibalanceinwei)
                        set_PurchaseLoad(true)
                    }
                    else {
                        var web3 = new Web3(window.ethereum);
                        web3.eth.getBalance(props.Accounts)
                            .then(val => {
                                var balance = val / config.decimalvalues;
                                set_PurchaseBalance(balance)
                                set_PurchaseLoad(true)
                            })
                    }

                }
                Set_CalculateLoading("")
            }
        }),
    )


    async function closeApprove() {
        Set_CalculateLoading("")
        Set_FormSubmitLoading('');
    }

    return (
        <div>
            <div class="modal fade primary_modal PurchaseNow_modal" id="PurchaseNow_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h5 class="modal-title" id="buy_modalLabel">Checkout</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close9" onClick={buymodal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body px-0">
                            <div className="row mx-0">
                                <div className="col-12 col-sm-3 px-4">
                                    <p className="buy_desc_sm">Owner</p>
                                </div>
                                <div className="col-12 col-md-6 buy_desc_sm_bold">
                                    {item.userprofile && item.userprofile.name ? item.userprofile && item.userprofile.name : halfAddrShow(item && item.tokenowners_current && item.tokenowners_current.tokenOwner)}
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-md-3 px-4">
                                    <p className="buy_desc_sm">Buyer</p>
                                </div>
                                <div className="col-12 col-md-6 buy_desc_sm_bold">
                                    {MyItemAccountAddr ? halfAddrShow(MyItemAccountAddr) : halfAddrShow(UserAccountAddr)}
                                </div>
                            </div>
                            <div className="bor_bot_modal mb-3 px-4 "></div>
                            <form className="bid_form" action="#">
                                {item.type == 721 ? ('') :
                                    <div className="bor_bot_modal mb-3 px-4 ">
                                        <label for="qty">Quantity{" "}({saletokenbal} available)</label>
                                        <div class="mb-3 input_grp_style_1">
                                            <input
                                                type="text"
                                                className="form-control primary_inp text-center"
                                                name="NoOfToken"
                                                id="NoOfToken"
                                                onChange={inputChange}
                                                placeholder="e.g. 2"
                                                autoComplete="off"
                                                value={NoOfToken}
                                                onKeyUp={onKeyUp}
                                            />
                                            {((ValidateError.NoOfToken && CalculateLoading == "") && (NoOfToken == "" || NoOfToken == 0)) && <span className="text-danger">{ValidateError.NoOfToken}</span>}
                                            {!ValidateError.NoOfToken && ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                        </div>
                                    </div>}
                            </form>
                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">Your balance</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">{UserAccountBal} {config.currencySymbol}</p>
                                </div>
                            </div>
                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">Your token balance</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">{dethBln} {config.tokenSymbol}</p>
                                </div>
                            </div>
                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">Price</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">{TokenPrice} {PurchaseCurrency}</p>
                                </div>
                            </div>
                            {/* <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                            <p className="buy_desc_sm">Royality % </p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                            <p className="buy_desc_sm_bold">{item.tokenRoyality} % <span>{item.biddingtoken}</span></p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                            <p className="buy_desc_sm">Royality fee </p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                            <p className="buy_desc_sm_bold">{(((parseFloat((parseFloat(TokenPrice)*(parseFloat(item.tokenRoyality)/100))))*parseFloat(NoOfToken))).toFixed(5)} <span>{item.biddingtoken}</span></p>
                            </div>
                        </div> */}
                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">Service fee</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">{servicefee} %<span>{PurchaseCurrency}</span></p>
                                </div>
                            </div>
                            {/* <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                            <p className="buy_desc_sm">Service fee </p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                            <p className="buy_desc_sm_bold">{(((parseFloat((parseFloat(TokenPrice)*(parseFloat(servicefee)/100))))*parseFloat(NoOfToken))).toFixed(5)} <span>{item.biddingtoken}</span></p>
                            </div>
                        </div> */}
                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">You will pay</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">{YouWillPay} <span>{PurchaseCurrency}</span></p>
                                </div>
                            </div>
                            <form className="px-4">
                                <div className="text-center">
                                    {(CalculateLoading == 'processing' || !PurchaseLoad) && <i class="fa fa-spinner mr-3 spinner_icon text-white" aria-hidden="true" id="circle1"></i >}
                                    <Button
                                        type="button"
                                        className="primary_btn btn-block"
                                        onClick={() => FormSubmit()}
                                        disabled={(FormSubmitLoading == 'processing' || CalculateLoading == 'processing' || !PurchaseLoad)}
                                    >
                                        {FormSubmitLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                    Proceed to payment
                                </Button>
                                    <Button className="cancel_btn  btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade primary_modal PurchaseStep_modal" id="PurchaseStep_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="PurchaseStepCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h5 class="modal-title" id="PurchaseStepLabel">Follow Steps</h5>
                            <button onClick={() => closeApprove()} type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div className="text-center">
                                    <p className="mt-3 purchase_desc text-center">Approve the transaction</p>
                                    <Button
                                        type="button"
                                        onClick={() => FormSubmit_StepOne()}
                                        className="btn-block btn_outline_red"
                                        disabled={(ApproveCallStatus == 'processing' || ApproveCallStatus == 'done')}
                                    >
                                        {ApproveCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {ApproveCallStatus == 'init' && 'Approve'}
                                        {ApproveCallStatus == 'processing' && 'In-progress...'}
                                        {ApproveCallStatus == 'done' && 'Done'}
                                        {ApproveCallStatus == 'tryagain' && 'Try Again'}
                                    </Button>
                                </div>
                                <div className="text-center my-3">
                                    <p className="mt-3 purchase_desc text-center">Send transaction with your wallet</p>
                                    <Button
                                        type="button"
                                        onClick={() => FormSubmit_StepTwo()}
                                        className="btn-block btn_outline_red"
                                        disabled={(ApproveCallStatus != 'done' || PurchaseCallStatus == 'processing' || PurchaseCallStatus == 'done')}
                                    >
                                        {PurchaseCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {PurchaseCallStatus == 'init' && 'Purchase'}
                                        {PurchaseCallStatus == 'processing' && 'In-progress...'}
                                        {PurchaseCallStatus == 'done' && 'Done'}
                                        {PurchaseCallStatus == 'tryagain' && 'Try Again'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

