import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import SidebarLinksAfterlogin from "components/Header/SidebarLinksAfterlogin.js";
import HeaderDashboard from "components/Header/HeaderDashboard";
import WalletModal from "components/WalletModal.js";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const drawerWidth = 240;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(0deg)"
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(180deg)"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 7.5 + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  grow: {
    flexGrow: 1
  }
});

const CreateMultiple =(props) => {
  const [open,setOpen] =useState(true)
   const [anchorEl,setAnchorEl] =useState(null)



  const handleDrawerOpen = () => {
    console.log("data---------------")
    setOpen(!open)
  };

  const handleDrawerClose = () => {
        setOpen(true)
  };

  const handleMenu = event => {
    console.log("----event",event)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
setAnchorEl(null);
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

    const { classes, theme } = props;
    // const open = Boolean(anchorEl);    
    return (
      <div className={classes.root}>
        <ScrollToTopOnMount/>
        <CssBaseline />
        
        {/* Header APP */}
        <HeaderDashboard 
          classes={classes}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          handleMenu={handleMenu}
          handleClose={handleClose}
          anchorEl={anchorEl}
        />
        
       {/*  side Menu */}
        <SidebarLinksAfterlogin classes1={classes} open={open}/>
        <div className="overlay"></div>
        <main className={classes.content + " dashContent"}>
          <div className={classes.toolbar} />
          <div className="dashPages">              
            <GridContainer>              
              <GridItem xs={12} sm={12} md={9} className="m-auto">
                <div class="d-flex align-items-center">
                  <Link class="goback_link" to="/create">
                    <i class="bi bi-chevron-left"></i><span>Go back</span>
                  </Link>
                </div>
                <div>
                  <h2 className="inner_title text-center">Create Multiple NFT</h2>
                  <div className="container">
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <label className="primary_label">Preview</label>
                        <div className="single_collectible">
                          <div className="item">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="masonry_likes">
                                <i className="fas fa-heart mr-2"></i>
                                <span>27</span>
                              </div>                              
                            </div>
                            <img src={require("../assets/images/img_02.jpg")} alt="Collections" className="img-fluid" />
                            <h2>My Mango</h2>
                            <div className="d-flex justify-content-between align-items-end">
                              <div>
                                <h3><span>0.061 BNB</span>  4 of 7</h3>
                                <h4>Bid <span>3.25 BUSD</span></h4>
                              </div>
                              <div className="d-flex creators_details">
                                <img src={require("../assets/images/user_01.png")} alt="User" className="img-fluid" />
                                <img src={require("../assets/images/user_02.png")} alt="User" className="img-fluid" />
                                <img src={require("../assets/images/user_03.png")} alt="User" className="img-fluid" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={8}>
                        <form>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <div className="d-flex justify-content-between align-items-start">
                                  <div>
                                    <label className="primary_label" htmlFor="inputEmail4">Upload file</label>
                                    <p className="form_note">PNG, GIF, WEBP, MP4 or MP3. Max 30mb.</p>
                                  </div>
                                  <div className="file_btn primary_btn">Upload
                                    <input className="inp_file" type="file" name="file"/>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <div className="d-flex justify-content-between align-items-start">
                                <div>
                                  <label className="primary_label" htmlFor="inputEmail4">Put on Sale</label>
                                  <p className="form_note">You’ll receive bids on this item</p>
                                </div>
                                <label className="switch toggle_custom">
                                  <input type="checkbox"/>
                                  <span className="slider"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <div className="d-flex justify-content-between align-items-start">
                                <div>
                                  <label className="primary_label" htmlFor="inputEmail4">Unlock Once Purchased</label>
                                  <p className="form_note">Content will be unlocked after<br/> successful transaction</p>
                                </div>
                                <label className="switch toggle_custom">
                                  <input type="checkbox"/>
                                  <span className="slider"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                                <div className="d-flex justify-content-between align-items-start">
                                  <div>
                                    <label className="primary_label" htmlFor="inputEmail4">Choose Collection</label>
                                  </div>
                                  <Button className="primary_btn" data-toggle="modal" data-target="#choose_collection_modal">Create BEP 721</Button>
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <div className="d-flex justify-content-between align-items-start">
                                <div>
                                  <label className="primary_label" htmlFor="inputEmail4">Instant on Sale</label>
                                </div>
                                <label className="switch toggle_custom">
                                  <input type="checkbox"/>
                                  <span className="slider"></span>
                                </label>
                              </div>
                              <p className="form_note pr-5 mt-2">Enter the price for which the item will be instantly sold</p>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <div className="d-flex justify-content-between align-items-start">
                                <Button className="nft_create_btn active">Fixed Price</Button>
                                <Button className="nft_create_btn">Timed Auction</Button>
                                <Button className="nft_create_btn">Ongoing Auction</Button>
                                {/* <div>
                                  <label className="primary_label" htmlFor="inputEmail4">Instant Sale Price</label>
                                  <p className="form_note">Enter the price for which the item<br/> will be instantly sold</p>
                                </div>
                                <label className="switch toggle_custom">
                                  <input type="checkbox"/>
                                  <span className="slider"></span>
                                </label> */}
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <input type="text" className="primary_inp" id="unlockdesc" name="unlockdesc" placeholder="Digital key,code to redeem or link to a file..."/>
                              <p className="form_note">Tip: Markdown syntax is supported</p>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="primary_label" htmlFor="name">Instant Price</label>
                              <input type="text" className="primary_inp" id="price" placeholder="Enter the price of one piece"/>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="primary_label" htmlFor="minimumbid">Minimum bid</label>
                              <input type="text" className="primary_inp" id="minimumbid" placeholder="Enter minimum bid"/>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label className="primary_label" htmlFor="minimumbid">Starting Date</label>
                                  <DatePicker className="primary_inp" selected={startDate} onChange={(date) => setStartDate(date)} selectsStart dateFormat="dd/MM/yyyy h:mm aa" showTimeInput/>
                                </div>
                                <div className="form-group col-md-6">
                                  <label className="primary_label" htmlFor="minimumbid">Expiration Date</label>
                                  <DatePicker className="primary_inp" selected={endDate} onChange={(date) => setEndDate(date)} selectsEnd  minDate={startDate} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput/>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="primary_label" htmlFor="name">Name</label>
                              <input type="text" className="primary_inp" id="name" placeholder="e.g. Redeemable"/>
                            </div>
                          </div>
                          <div  className="form-row">
                            <div className="form-group col-md-12">
                              <label className="primary_label" htmlFor="desccription">Description <span className="text-muted">(Optional)</span></label>
                              <input type="text" className="primary_inp" id="desccription" placeholder="e.g. After purchasing..."/>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label className="primary_label" htmlFor="name">Royalties</label>
                              <div className="inp_grp">
                                <input type="text" className="primary_inp" id="name" placeholder="0.00"/>
                                <p className="inp_append">%</p>
                              </div>
                            </div>                           
                          </div>
                          <div className="form-row">                            
                            <div className="form-group col-md-12">
                              <label className="primary_label" htmlFor="desccription">Properties <span className="text-muted">(Optional)</span></label>
                              <input type="text" className="primary_inp" id="desccription" placeholder="e.g. size"/>
                            </div>
                          </div>
                          <div>
                            <Button className="primary_btn" data-toggle="modal" data-target="#create_item_modal">Create item</Button>
                          </div>
                        </form>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </GridItem>             
            </GridContainer>
          </div>
        </main>
        
        <WalletModal/>       

      {/* Choose Collection Modal */}
      <div class="modal fade primary_modal" id="choose_collection_modal" tabindex="-1" role="dialog" aria-labelledby="choose_collection_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="choose_collection_modalLabel">Collection</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="d-flex align-items-center">
                <div class="hex center">
                  <div class="hex__shape">
                    <div class="hex__shape">
                      <div class="hex__shape">
                        <img src={require("../assets/images/img_02.jpg")} alt="logo" className="img-fluid"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="font_16 font_600 line_20 mb-1">We recommend an image of at least 400x400. Gifs work too.</p>
                  <div className="file_btn primary_btn d-inline-block">Choose File
                    <input className="inp_file" type="file" name="file"/>
                  </div>
                </div>
              </div>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">Display Name <span className="text-muted">(30 available)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Enter token name"/>
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="desccription">Symbol <span className="text-muted">(required)</span></label>
                    <input type="text" className="form-control primary_inp" id="desccription" placeholder="Enter token symbol"/>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">Description <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Spread some words about token collection"/>                  
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="desccription">Short url</label>
                    <input type="text" className="form-control primary_inp" id="desccription" defaultValue="spideybarter.io/"/>
                  </div>
                </div>
                <div className="text-center">
                  <Button className="create_btn">Create Collection</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Create Item Modal */}
      <div class="modal fade primary_modal" id="create_item_modal" tabindex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="create_item_modalLabel">Follow Steps</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">                         
              <form> 
              <div className="media approve_media">
              <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
              {/* <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
              <div className="media-body">
                <p className="mt-0 approve_text">Approve</p> 
                <p className="mt-0 approve_desc">Approve performing transactions with your wallet</p>
              </div>
            </div>
              <div className="text-center my-3">
              <Button className="btn_outline_red btn-block">Done</Button>                
              </div>
              <div className="media approve_media">
              <i className="fas fa-check mr-3" aria-hidden="true"></i>
              <div className="media-body">
                <p className="mt-0 approve_text">Upload files & Mint token</p> 
                <p className="mt-0 approve_desc">Call contract method</p>
              </div>
            </div>
              <div className="text-center my-3">
              <Button className="create_btn btn-block">Start</Button>                
              </div>

              <div className="media approve_media">
              <i className="fas fa-check mr-3" aria-hidden="true"></i>
              <div className="media-body">
                <p className="mt-0 approve_text">Sign sell order</p> 
                <p className="mt-0 approve_desc">Sign sell order using tour wallet</p>
              </div>
            </div>
              <div className="text-center my-3">
              <Button className="create_btn btn-block" disabled>Start</Button>                
              </div>

              <div className="media approve_media">
              <i className="fas fa-check mr-3" aria-hidden="true"></i>
              <div className="media-body">
                <p className="mt-0 approve_text">Sign lock order</p> 
                <p className="mt-0 approve_desc">Sign lock order using tour wallet</p>
              </div>
            </div>
              <div className="text-center mt-3">
              <Button className="create_btn btn-block" disabled>Start</Button>                
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      </div>
    );
}

CreateMultiple.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(CreateMultiple);
