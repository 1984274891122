let NODE_ENV      = "demo";
let Front_URL     = "";
let Back_URL      = "";
let v1Url         = "";
let limit         = "12";
let limitMax      = "3000";
let decimalvalues = 1000000000000000000;
let toFixed       = 6;
var IPFS_IMG      = "https://ipfs.io/ipfs";

let toasterOption = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

//let networkVersion      = "1";
let networkVersion      = "4";
let currencySymbol      = "ETH";
let tokenSymbol         = "VBIT";
let qualTokenSymbol     = "WETH";
//const ownerAddr         = "0xb54225d63f5be0ec3f282ac836bcd318e4a28745";
// const singleContract    = "0x6523EB427Fcd5Dc154aC930c688d452cFdFd7728";//08-02-2022 0xB1e6b523b22537107F456FE09b191f72c5A1eD74 --testnet
// const multipleContract  = "0x6a612C620e3f27622090Aa6C9874D6494466B947";//0xf6FFa015BfFFf65c621c3306BD3B8D9804b9Aeb7
// const DETHtokenAddr     = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"; //0x571fee77DF70Ee5D1C2E42b828fdE91e47253159
// const ValobitdxAddr     = "0xB8366948B4A3F07BcBf14EB1739daA42A26b07c4";//0x9845fAB4542b1d65F52557cD9C01340e2b307C8a
// const usdtaddress       = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
// const stakingaddress    = "0x517f1712450ec9C53Ed7cC6Cc40264a90c217995";
// const multi_stakingaddress = "0xfdE567088Fa06Ac60C94fB0E1a36aa27D99FE091";
  const ipfsurl           = "https://ipfs.io/ipfs";
//  const networkUrl           = "https://mainnet.infura.io/v3/a472928282e9473cb9379ddd415389b8";
const singleContract    = "0xB1e6b523b22537107F456FE09b191f72c5A1eD74";//08-02-2022 0xB1e6b523b22537107F456FE09b191f72c5A1eD74 --testnet
const multipleContract  = "0xD6b4C137DE64f0EBe550e1bb12c760c276200011";//0xf6FFa015BfFFf65c621c3306BD3B8D9804b9Aeb7
const DETHtokenAddr     = "0x571fee77DF70Ee5D1C2E42b828fdE91e47253159"; //0x571fee77DF70Ee5D1C2E42b828fdE91e47253159
const ValobitdxAddr     = "0xe5C616a33c1f767F6b9f4c0E00B319d091e92935";//0x9845fAB4542b1d65F52557cD9C01340e2b307C8a
const usdtaddress       = "0x74242193252f66f00571d3c23A3A9b442A807aEb";
const ownerAddr         = "0xdC6A32D60002274A16A4C1E93784429D4F7D1C6a";
const stakingaddress    = "0x9D3624e6eE5bda772deE88Cf65A19100E7486891";
const multi_stakingaddress = "0x09F3b58CbEe32494c4bC8cBAE1F5f971a137e234";
const networkUrl           = "https://rinkeby.infura.io/v3/a5708dc9c2534171a670ed6855a98493";
// var tokenAddr = {
//   Valobitdx: "0xB8366948B4A3F07BcBf14EB1739daA42A26b07c4",
// };
var tokenAddr = {
  Valobitdx: "0xe5C616a33c1f767F6b9f4c0E00B319d091e92935",//local
};
const singleType        = 721;
const multipleType      = 1155;

if (NODE_ENV === "production") {
    Front_URL   = "https://nft.valobitdx.io";
    Back_URL    = "https://api.valobitdx.io";
    v1Url       = "https://api.valobitdx.io/v1";
    IPFS_IMG    = "https://ipfs.io/ipfs";

} else if (NODE_ENV === "demo") {
  Front_URL     = 'https://vbixnft-frontend.pages.dev';
  Back_URL      = 'https://vbixnftapi.wealwin.com';
  v1Url         = 'https://vbixnftapi.wealwin.com/v1';
  IPFS_IMG      = "https://ipfs.io/ipfs";
} else {
    Front_URL   = "http://localhost:3000";
    Back_URL    = "http://localhost:2087";
    v1Url       = "http://localhost:2087/v1";
   // Back_URL    = 'http://212.71.233.123:2055';
  //v1Url         = 'http://212.71.233.123:2055/v1';
    IPFS_IMG    = "https://ipfs.io/ipfs";
}

let key = {
  Front_URL: Front_URL,
  Back_URL: Back_URL,
  v1Url: v1Url,
  vUrl: v1Url,
  ipfsurl: ipfsurl,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  networkVersion: networkVersion,
  currencySymbol: currencySymbol,
  toasterOption: toasterOption,
  limit: limit,
  limitMax: limitMax,
  singleContract: singleContract,
  multipleContract: multipleContract,
  DETHtokenAddr: DETHtokenAddr,
  ValobitdxAddr:ValobitdxAddr,
  ownerAddr: ownerAddr,
  singleType: singleType,
  multipleType: multipleType,
  tokenAddr: tokenAddr,
  stakingaddress:stakingaddress,
  multi_stakingaddress: multi_stakingaddress,
  usdtaddress:usdtaddress,
  tokenSymbol: tokenSymbol,
  qualTokenSymbol:qualTokenSymbol,
  IPFS_IMG: IPFS_IMG,
  networkUrl:networkUrl
};

export default key;
