import React, {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle
} from 'react';
import {
    useHistory
} from "react-router-dom";
import { Button, TextField } from '@material-ui/core';

import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import BEP1155 from '../../ABI/BEP1155.json'
import BEP721 from '../../ABI/BEP721.json';
import VALOBITDX from '../../ABI/VALOBITDX.json';
import config from '../../lib/config';
import Select from "react-select";


import {
    AddLikeAction,
    GetLikeDataAction,
    TokenPriceChange_update_Action
} from '../../actions/v1/token';

import {
    getCurAddr,
    halfAddrShow
} from '../../actions/v1/User';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;
var web3              = new Web3(window.ethereum);
const VALOBITDXContract = new web3.eth.Contract(VALOBITDX,config.ValobitdxAddr)
const BEP721Contract    = new web3.eth.Contract(BEP721, config.singleContract)

const price = [
    { value: 'ETH', label: 'ETH' },
    { value: 'VBIT', label: 'VBIT' }
  ];

export const PutOnSaleRef = forwardRef((props, ref) => {

  var {
        item,
        UserAccountAddr,
        UserAccountBal,
        Set_NoOfToken,
        NoOfToken
    } = props;

    const history                                      = useHistory();
    const [BuyerName, Set_BuyerName]                   = useState('');
    const [blns, Set_blns]                             = useState('');
    const [dethBln, Set_dethBln]                       = useState('');
    const [bidProfile1, Set_bidProfile1]               = useState([]);
    const [FormSubmitLoading, Set_FormSubmitLoading]   = useState('');
    const [ValidateError, Set_ValidateError]           = useState({});
    const [YouWillGet, Set_YouWillGet]                 = useState(0);
    const [TokenPrice, Set_TokenPrice]                 = useState(0);
    const [TokenPrice_Initial, Set_TokenPrice_Initial] = useState(0);
    const [biddingtoken, set_biddingtoken]             = useState(config.currencySymbol)
    const [servicefee, setservicefee]                  = useState(0);
  const [PurchaseCurrency, setPurchaseCurrency]        = useState(config.tokenSymbol);
  const [TokenBalance, Set_TokenBalance]                = useState(0);



    const inputChange = async(e) => {
        var provider = window.ethereum;
        var web3 = new Web3(provider);
        if(e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
          var value = e.target.value;
          switch(e.target.name) {
            case 'TokenPrice':
                Set_TokenPrice(value);
                if(value != '' && isNaN(value) == false && value > 0) {
                    var weii =await web3.utils.toWei((value).toString())

                    if(biddingtoken==config.tokenSymbol){
                        var web3 = new Web3(window.ethereum);
                        if(item.type==721){
                            var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                            var fee = await CoursetroContract.methods.getValobitdxFee().call();
                          }else{
                            var CoursetroContract = new web3.eth.Contract(BEP1155, item.contractAddress);
                            var fee = await CoursetroContract.methods.getValobitdxFee().call();
                          }
                        var per = (weii * fee) / 1e20;
                    Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
                    }else if (biddingtoken==config.currencySymbol){
                        var web3 = new Web3(window.ethereum);
                        if(item.type==721){
                            var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                            var fee = await CoursetroContract.methods.getServiceFee().call();
                          }else{
                            var CoursetroContract = new web3.eth.Contract(BEP1155, item.contractAddress);
                            var fee = await CoursetroContract.methods.getServiceFee().call();
                          }
                        var per = (weii * fee) / 1e20; 
                    Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
                    }else{
                        var web3 = new Web3(window.ethereum);
                        if(item.type==721){
                            var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                              if(biddingtoken==config.currencySymbol){
                                  var fee = await CoursetroContract.methods.getServiceFee().call();
                                } else if(biddingtoken==config.tokenSymbol){
                                  var fee = await CoursetroContract.methods.getValobitdxFee().call();
                                }else{
                                  var fee = await CoursetroContract.methods.getServiceFee().call();
                                }
                                var per = (weii * fee) / 1e20;
                            Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
        
                          }else{
                            var CoursetroContract = new web3.eth.Contract(BEP1155, item.contractAddress);
                              if(biddingtoken==config.currencySymbol){
                                  var fee = await CoursetroContract.methods.getServiceFee().call();
                                } else if(biddingtoken==config.tokenSymbol){
                                  var fee = await CoursetroContract.methods.getValobitdxFee().call();
                                }else{
                                  var fee = await CoursetroContract.methods.getServiceFee().call();
                                }
                                var per = (weii * fee) / 1e20;
                    Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
  
                          }
                    }
                }
                ItemValidation({TokenPrice:value});
              break;
            default:
              // code block
          }
        }
    }
    const SelectBidcurrency = (e) => {
        var filter=e.target.value;
        set_biddingtoken(filter)
        getTokenval(filter)
      }
     
      async function getTokenval(filter){
        var web3              = new Web3(window.ethereum);
        if(filter==config.currencySymbol){
            if(item.type==721){
                var BEP721Contract = new web3.eth.Contract(BEP721, config.singleContract);
                var fee = await BEP721Contract.methods.getServiceFee().call();    
                var feeValue = fee/config.decimalvalues;
                setservicefee(feeValue)
                var weii=TokenPrice*config.decimalvalues;
                var per = (weii * fee) / 1e20;
              Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
            }else{
                var BEP1155Contract = new web3.eth.Contract(BEP1155, item.contractAddress);
                var fee = await BEP1155Contract.methods.getServiceFee().call();
                var feeValue = fee/config.decimalvalues;
                setservicefee(feeValue);
                var weii=TokenPrice*config.decimalvalues;
                var per = (weii * fee) / 1e20;
              Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
  
            }
        }else if(filter==config.tokenSymbol){
          if(item.type==721){
              var BEP721Contract = new web3.eth.Contract(BEP721, config.singleContract);
              var fee = await BEP721Contract.methods.getValobitdxFee().call();    
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue)
              var weii=TokenPrice*config.decimalvalues;
              var per = (weii * fee) / 1e20;
            Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
          }else{
              var BEP1155Contract = new web3.eth.Contract(BEP1155, item.contractAddress);
              var fee = await BEP1155Contract.methods.getValobitdxFee().call();
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue);
              var weii=TokenPrice*config.decimalvalues;
              var per = (weii * fee) / 1e20;
            Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
  
          }
        }else{
            if(item.type==721){
                var BEP721Contract = new web3.eth.Contract(BEP721, config.singleContract);
                if(filter==config.currencySymbol){
                    var fee = await BEP721Contract.methods.getServiceFee().call();
                  } else if(filter==config.tokenSymbol){
                    var fee = await BEP721Contract.methods.getValobitdxFee().call();
                  }else{
                    var fee = await BEP721Contract.methods.getServiceFee().call();
                  }
                  var weii=TokenPrice*config.decimalvalues;
                  var feeValue = fee/config.decimalvalues;
                  setservicefee(feeValue)
                  var per = (weii * fee) / 1e20;
              Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
  
            }else{
                var BEP1155Contract = new web3.eth.Contract(BEP1155, item.contractAddress);
                if(filter==config.currencySymbol){
                    var fee = await BEP1155Contract.methods.getServiceFee().call();
                  } else if(filter==config.owntokenSymbol){
                    var fee = await BEP1155Contract.methods.getValobitdxFee().call();
                  }else{
                    var fee = await BEP1155Contract.methods.getServiceFee().call();
                  }
                  var weii=TokenPrice*config.decimalvalues;
                  var feeValue = fee/config.decimalvalues;
                  setservicefee(feeValue)
                  var per = (weii * fee) / 1e20;
                Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
  
            }
            var VbitdxContract   = new web3.eth.Contract(VALOBITDX, config.ValobitdxAddr);
            var currAddr         = window.web3.eth.defaultAccount;
            var decimal          = await VbitdxContract.methods.decimals().call();
            var tokenBal         = await VbitdxContract.methods.balanceOf(currAddr).call();
            var tokenBalance      = tokenBal / config.decimalvalues;
            Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
        }
      }
    const onKeyUp = (e) => {
        var charCode = e.keyCode;
        console.log("charCode:",charCode)
        if((charCode>47 && charCode <58) || (charCode>95 && charCode <106)){
          var ValidateError = {};
          Set_ValidateError(ValidateError);
        }else{
          var ValidateError = {};
          ValidateError.TokenPrice = '"Token Price" must be a number';
          Set_TokenPrice("");
          Set_ValidateError(ValidateError);
        //   return false;
        }
      }

      const ItemValidation = async (data={}) => {
        var ValidateError = {};

        var Chk_TokenPrice = (typeof data.TokenPrice!='undefined')?data.TokenPrice:TokenPrice;
    
        if(Chk_TokenPrice == '') {
            ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
        }
        else if(Chk_TokenPrice == 0) {
            ValidateError.TokenPrice = '"Token Price" must be greater than 0';;
        } 
        else if(isNaN(Chk_TokenPrice) == true) {
          ValidateError.TokenPrice = '"Token Price" must be a number';
        }
        else if(Math.sign( TokenPrice)<0) {
            ValidateError.TokenPrice = 'Price must be a possitive number';
          }
        else if(TokenPrice_Initial > 0 && Chk_TokenPrice >= TokenPrice_Initial) {
          ValidateError.TokenPrice = '"Token Price" must be less than '+TokenPrice_Initial;
        }else{
            delete ValidateError.TokenPrice;
        }
        Set_ValidateError(ValidateError);
        return ValidateError;
    }
    function convert(n){
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead,decimal,pow] = n.toString()
            .replace(/^-/,"")
            .replace(/^([0-9]+)(e.*)/,"$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
    }

    async function FormSubmit(){
        var errors = await ItemValidation();
        var errorsSize = Object.keys(errors).length;
        if(errorsSize != 0) {
            toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
            return false;
        }

        if(window.ethereum) {
            var web3 = new Web3(window.ethereum)
            if(
                web3
                && web3.eth
            ) {
                if(item.type==721){
                var CoursetroContract = new web3.eth.Contract(
                    BEP721,
                    config.singleContract
                );
                Set_FormSubmitLoading('processing');
                var newtoken = (TokenPrice * config.decimalvalues).toString()
                var orderAmt=convert(newtoken)
                CoursetroContract.methods
                .orderPlace(
                    props.item.tokenCounts,
                    orderAmt
                )
                .send({ from: props.Accounts })
                .then(async (result) => {
                    Set_FormSubmitLoading('done');
                    console.log('result : ', result);
                    var postData = {
                        tokenOwner: UserAccountAddr,
                        tokenCounts: props.item.tokenCounts,
                        tokenPrice: TokenPrice,
                        biddingtoken:biddingtoken,
                        blockHash: result.blockHash,
                        transactionHash: result.transactionHash
                    }
                    var Resp = await TokenPriceChange_update_Action(postData)
                    if (Resp.data && Resp.data.RetType && Resp.data.RetType=='success') {
                        toast.success("Collectible price changed successfully", toasterOption)
                        window.$('#PutOnSale_modal').modal('hide');
                        setTimeout(() => {window.location.reload(false)}, 2000);
                    }
                })
                .catch((error) => {
                    Set_FormSubmitLoading('error');
                    console.log('error : ', error);
                    toast.error('Order not placed', toasterOption)
                })

            }else{
                var CoursetroContract = new web3.eth.Contract(BEP1155, item.contractAddress);
                Set_FormSubmitLoading('processing');
                var newtoken=(TokenPrice * config.decimalvalues).toString()
                var orderAmt=convert(newtoken)
                CoursetroContract.methods
                .orderPlace(
                    props.item.tokenCounts,
                    orderAmt
                )
                .send({ from: props.Accounts })
                .then(async (result) => {
                    Set_FormSubmitLoading('done');
                    console.log('result : ', result);
                    var postData = {
                        tokenOwner: UserAccountAddr,
                        tokenCounts: props.item.tokenCounts,
                        tokenPrice: TokenPrice,
                        biddingtoken:biddingtoken,
                        blockHash: result.blockHash,
                        transactionHash: result.transactionHash
                    }
                    var Resp = await TokenPriceChange_update_Action(postData)
                    if (Resp.data && Resp.data.RetType && Resp.data.RetType=='success') {
                        toast.success("Collectible price changed successfully", toasterOption)
                        window.$('#PutOnSale_modal').modal('hide');
                        setTimeout(() => { window.location.reload(false) }, 2000);
                    }
                })
                .catch((error) => {
                    Set_FormSubmitLoading('error');
                    console.log('error : ', error);
                    toast.error('Order not placed', toasterOption)
                })
            }
            }
        }
    }

    
    useEffect(() => {
        getfee()
        Set_ValidateError({});
    }, []);

    async function getfee(){
        try{
            if (window.ethereum) {
              var web3 = new Web3(window.ethereum);
              if(item.type==721){
                var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                if(PurchaseCurrency==config.currencySymbol){
                  var fee = await CoursetroContract.methods.getServiceFee().call();
                } else if(PurchaseCurrency==config.tokenSymbol){
                  var fee = await CoursetroContract.methods.getValobitdxFee().call();
                }else{
                  var fee = await CoursetroContract.methods.getServiceFee().call();
                }
              }else{
                var CoursetroContract = new web3.eth.Contract(BEP1155, item.contractAddress);
                if(PurchaseCurrency==config.currencySymbol){
                  var fee = await CoursetroContract.methods.getServiceFee().call();
                } else if(PurchaseCurrency==config.owntokenSymbol){
                  var fee = await CoursetroContract.methods.getValobitdxFee().call();
                }else{
                  var fee = await CoursetroContract.methods.getServiceFee().call();
                }
              }
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue);
            }
          }catch(err){
          }
   }

    useImperativeHandle(
        ref,
        () => ({
            async PutOnSale_Click(item,ownerdetail) {
                getfee(item)
                console.log("put_on_sale:",ownerdetail)
                var connectwallet=localStorage.getItem("xdtitfnbolav");
                if(!connectwallet){
                toast.error("Please connect to a Metamask wallet", toasterOption);
                  return false;
                }
                props.Set_HitItem(item);
                
                  if(ownerdetail && ownerdetail.biddingtoken) {
                    if(ownerdetail && ownerdetail.biddingtoken==config.qualTokenSymbol){
                      setPurchaseCurrency(config.currencySymbol)
                      set_biddingtoken(config.currencySymbol)
                    }else{
                      setPurchaseCurrency(ownerdetail.biddingtoken)
                      set_biddingtoken(ownerdetail.biddingtoken)
                    }
                    }
                  if(ownerdetail.type==721){
                    var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                    if(ownerdetail && ownerdetail.biddingtoken==config.currencySymbol){
                      var fee = await CoursetroContract.methods.getServiceFee().call();
                    } else if(ownerdetail && ownerdetail.biddingtoken==config.tokenSymbol){
                      var fee = await CoursetroContract.methods.getValobitdxFee().call();
                    }else{
                      var fee = await CoursetroContract.methods.getServiceFee().call();
                    }
                    var feeValue = fee/config.decimalvalues;
                    setservicefee(feeValue);
                    Set_TokenPrice_Initial(ownerdetail.tokenPrice);
                    Set_ValidateError({});
                    window.$('#PutOnSale_modal').modal('show');
                  }else{
                    var CoursetroContract = new web3.eth.Contract(BEP1155, item.contractAddress);
                    if(ownerdetail && ownerdetail.biddingtoken==config.currencySymbol){
                      var fee = await CoursetroContract.methods.getServiceFee().call();
                    } else if(ownerdetail && ownerdetail.biddingtoken==config.tokenSymbol){
                      var fee = await CoursetroContract.methods.getValobitdxFee().call();
                    }else{
                      var fee = await CoursetroContract.methods.getServiceFee().call();
                    }
                    var feeValue = fee/config.decimalvalues;
                    setservicefee(feeValue);
                    Set_TokenPrice_Initial(ownerdetail.tokenPrice);
                    Set_ValidateError({});
                    window.$('#PutOnSale_modal').modal('show');
                  }
                Set_TokenPrice_Initial(ownerdetail.tokenPrice);
                Set_ValidateError({});
                window.$('#PutOnSale_modal').modal('show');
            }
        }),
    )
    return (
        <div class="modal fade primary_modal" id="PutOnSale_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h5 class="modal-title" id="accept_modalLabel">{TokenPrice_Initial==0 || TokenPrice_Initial==null ?'Put On Sale':'Change Price'}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close9" onClick={()=>window.location.reload(false)}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body px-2">
                        <div className="img_accept text-center">
                            {
                                item && item!=null && item!=undefined && item!="" && item.image && item.image.split('.').pop() == "mp4" ?
                                  <video src={`${config.ipfsurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid" controls />
                                :<div>
                                {( item && item!=null && item!=undefined && item!="" && item.image && item.image.split('.').pop() == 'mp3') ?(
                                  <>
                                    <img src={`${config.Back_URL}/images/music.png`} alt="Collections" className="img-fluid img_full_w"/>
                                    <audio src={`${config.ipfsurl}/${item.ipfsimage}`} type="audio/mp3" controls className="audio">
                                    </audio>
                                    </>
                                ):(
                                  <img src={`${config.ipfsurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
                                )}
                                </div>
                             }
                        </div>
                        <p className="text-center accept_desc" >
                            <span className="buy_desc_sm" styel={{ fontSize: 12 }}>You are about to Place Order for</span>
                            <span className="buy_desc_sm_bold pl-1 bold_red owner_break">{item.tokenName}</span>
                            <span className="buy_desc_sm pl-2" styel={{ fontSize: 12 }} >for</span><br />
                            <span className="buy_desc_sm_bold pl-1 bold_red owner_break" styel={{ fontSize: 10 }}>
                                {
                                item.userprofile && item.userprofile.name ?
                                <span >{item.userprofile.name}</span>
                                :
                                item&&item.tokenowners_current&&item.tokenowners_current.tokenOwner &&
                                 <span >{halfAddrShow(item&&item.tokenowners_current&&item.tokenowners_current.tokenOwner)}</span>                    
                                }
                            </span>
                        </p>
                        <form className="bid_form" action="#">
                        <div className="bor_bot_modal mb-3">
                            <div className="form-row px-3">
                                <div className="form-group col-md-8">
                                                                
                                <label className="primary_label" for="qty">Enter Price  </label>
                                <div class="mb-3 input_grp_style_1">
                                    <input
                                        type="text"
                                        className="primary_inp"
                                        name="TokenPrice"
                                        id="TokenPrice"
                                        onChange={inputChange}
                                        placeholder="e.g. 10"
                                        autoComplete="off"
                                        // onKeyUp={onKeyUp}
                                        // value={NoOfToken}
                                    />
                                            
                                    {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                </div>
                            </div>
                            <div  className="form-group col-md-4">
                                    <label className="d-block">&nbsp;</label>
                                <select value={biddingtoken} class="d-block custom-select primary_inp" id="TokenCategory1" name="TokenCategory" onChange={SelectBidcurrency}  >
                               {
                                 price.map((item) => {
                                   return (
                                   <option  value={item.label} >{item.label}</option>
                                   )
                                 })
                               }
                         </select> 
                                    </div>
                                </div>
                               
                            </div>
                        </form>
                        {/* <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Royality fee</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{item.tokenRoyality} % <span>{biddingtoken}</span></p>
                            </div>
                        </div> */}
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Service fee</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{servicefee}% <span>{biddingtoken}</span></p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">You will get</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{YouWillGet} <span>{biddingtoken}</span></p>
                            </div>
                        </div>
                        <form className="px-4">
                            <div className="text-center">
                                <Button
                                    type="button"
                                    className="btn_outline_red btn-block"
                                    onClick={() => FormSubmit()}
                                    disabled={(FormSubmitLoading=='processing')}
                                >
                                    {FormSubmitLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                    Sign sell order
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
})

