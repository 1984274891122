/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { NavLink,useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import { Link } from 'react-scroll'
import { AppBar, Toolbar, IconButton, Hidden, Drawer, Button,} from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const history = useHistory();

async function Launchapp(){
  window.location.href="https://valobitdx.io/"
}

  return (
    <div className="home_page_menu">
      <Hidden lgUp>
        <List className={classes.list + " main_navbar"}>       
          <ListItem className={classes.listItem}>
            <NavLink to="/"  color="transparent" className={classes.navLink}>Trade</NavLink>
          </ListItem>
          <ListItem className={classes.listItem}>
            <NavLink to="/"  color="transparent" className={classes.navLink}>Earn</NavLink>
          </ListItem>
          <ListItem className={classes.listItem}>
            <NavLink to="/"  color="transparent" className={classes.navLink}>NFT Marketplace</NavLink>
          </ListItem>

          {/* <ListItem className={classes.listItem}>
            <Link to="why" spy={true} smooth={true} offset={-100} duration={250} color="transparent" className={classes.navLink}>Features</Link>
          </ListItem>

          <ListItem className={classes.listItem}>
            <Link to="product" spy={true} smooth={true} offset={-100} duration={250} color="transparent" className={classes.navLink}>Product & Services</Link>
          </ListItem>

          <ListItem className={classes.listItem}>
            <Link to="token" spy={true} smooth={true} offset={-100} duration={250} color="transparent" className={classes.navLink}>Token Details</Link>
          </ListItem>

          <ListItem className={classes.listItem}>
            <Link to="roadmap" spy={true} smooth={true} offset={-100} duration={250} color="transparent" className={classes.navLink}>Road Map</Link>
          </ListItem> */}

          {/* <ListItem className={classes.listItem + " menu_dropdown"}>
            <CustomDropdown
              noLiPadding
              buttonText="Contract"
              dropdownList={[
                <a href="https://github.com/" target="_blank" className={classes.dropdownLink}>
                  GitHub
                </a>,        
                <a href="https://bscscan.com/" target="_blank" className={classes.dropdownLink}>
                  BscScan
                </a>,
                <a href="https://etherscan.io/tokens" target="_blank" className={classes.dropdownLink}>
                  Token Tracker
                </a>,
              ]}
            />
          </ListItem> */}

          {/* <ListItem className={classes.listItem}>
            <Link to="contact" spy={true} smooth={true} offset={-100} duration={250} color="transparent" className={classes.navLink}>Contact Us</Link>
          </ListItem> */}

          {/* <ListItem className={classes.listItem}>
            <Button className="primary_btn"><Link to="/buy" color="transparent" className="nav-link p-0">Launch App</Link></Button>
          </ListItem> */}
        </List>
      </Hidden>
      <List>
        <ListItem className={classes.listItem} onClick={()=>Launchapp()}>
          <Button className="home_primary_btn" type="submit" ><Link to="/https://valobitdx.io/" color="transparent" className="nav-link p-0">Launch App</Link></Button>
        </ListItem>
      </List>
    </div>
  );
}
