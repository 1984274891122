import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, TextField } from '@material-ui/core';
import Countdown from "react-countdown";
import moment from "moment"
import stakeabi from "../../ABI/staking"
import multistackabi from "../../ABI/Multistake_ABI";
import config from '../../lib/config';
import isEmpty from '../../lib/isEmpty';
import Web3 from 'web3';
import {
  getCurAddr, halfAddrShow , updateclaimdate
} from '../../actions/v1/User';

import {
  TokenCounts_Get_Detail_Action,updatewithdraw,unstaketoken
} from '../../actions/v1/token';
import axios from 'axios';
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;


export default function TokenItem(props) {

  const [usdval, setusd] = useState(0);
  const [usdtbal, setusdtbal] = useState(0);
  const [vbitbal, setvbitbal] = useState(0);
  const [stakestatus , setstakestatus] = useState(false);
  const [withstatus , setwithstatus] = useState(false);
  const [mybal, setmybal] = useState(0);


  async function showAllwithPro(data) {
    console.log("click_token:", item)
    var curAddr = await getCurAddr();
    var payload = {
      curAddr: curAddr,
      tokenCounts: data.tokenCounts
    };
    TokenCounts_Get_Detail_Call(payload);
  }
  const TokenCounts_Get_Detail_Call = async (payload) => {
    var curAddr = await getCurAddr();
    var Resp = await TokenCounts_Get_Detail_Action(payload);
    if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {
      var TokenResp = Resp.data.Detail.Resp;
      if(
        TokenResp
        && TokenResp.Token
        && TokenResp.Token[0]
        && TokenResp.Token[0].tokenowners_current
      ) {
        for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
          const element = TokenResp.Token[0].tokenowners_current[i];
          if(element.balance > 0 && element.tokenPrice > 0 && element.tokenOwner != curAddr) {
            Set_BuyOwnerDetailFirst(element);
            break;
          }
        }
      }
      Set_tokenCounts_Detail(TokenResp);    
      if(TokenResp.Bids) {
        Set_Bids(TokenResp.Bids);
      }
      let ageSum = 0;
      var tokenowners_all = TokenResp.Token[0].tokenowners_all;
      console.log("balnce:",tokenowners_all)
      if (tokenowners_all && tokenowners_all.length > 0) {
        for (let i = 0; i < tokenowners_all.length; i++) {
          if(tokenowners_all[i].balance>0){
            ageSum += tokenowners_all[i].balance;
          }
          console.log("tokenowners_all", tokenowners_all[i].balance, ageSum)
        }
      }
      var IndexVal = -1;
      if(TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        IndexVal = tokenowners_all.findIndex(val => val.tokenOwner.toString() == curAddr.toString());
      }
      var newMyTokenBalance = 0;
      if(IndexVal > -1) {
        newMyTokenBalance = tokenowners_all[IndexVal].balance
        Set_MyTokenBalance(newMyTokenBalance);
        setmybal(newMyTokenBalance)
        Set_MyTokenDetail(tokenowners_all[IndexVal]);
      }
      else {
        newMyTokenBalance = 0;
        Set_MyTokenBalance(0);
        setmybal(0)
        Set_MyTokenDetail({});
      }
      if(ageSum) {
        Set_AllowedQuantity(ageSum - newMyTokenBalance);
      }
      else {
        Set_AllowedQuantity(0);
      }
      if(TokenResp.Token && TokenResp.Token[0]) {
        Set_item(TokenResp.Token[0]);
      } 
    }
  }
 

  useEffect(() => {
    getUsd();
    getrewards();
    const interval = setInterval(async() => {
      getrewards();
    }, 15);
    return () => clearInterval(interval);
  }, [])
  async function getUsd() {
    await axios.get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
      .then(async (response2) => {
        console.log(response2.data.USD, "tokenlis")
        setusd(response2.data.USD)
      })
  }

  async function getrewards(){
    let CoursetroContract = ""
    if(window.ethereum && UserAccountAddr && UserAccountAddr!="") {
        var web3 = new Web3(window.ethereum);
        if (web3&&web3.eth) {
          var connectwallet=localStorage.getItem("xdtitfnbolav");
          if(!connectwallet){

          }else{
           if(item.tokenCounts==1647460543){
            CoursetroContract = new web3.eth.Contract(stakeabi, config.stakingaddress);
          }else if(item.tokenCounts==1650863870){
            CoursetroContract = new web3.eth.Contract(stakeabi, config.stakingaddress);
          }else{
            CoursetroContract = new web3.eth.Contract(multistackabi, config.multi_stakingaddress);
          }
           let pid = item.tokenCounts==1647460543 ? 0 : item.pid!=undefined && item.pid!=null && item.pid!="" ? item.pid : 0 
           
          }
       }
     }
     var IndexVal = -1;
      if(item.tokenowners_all && UserAccountAddr) {
        var tokenowners_all = item.tokenowners_all;
        IndexVal = tokenowners_all.findIndex(val => val.tokenOwner.toString() == UserAccountAddr.toString());
      }
      var newMyTokenBalance = 0;
      if(IndexVal > -1) {
        newMyTokenBalance = tokenowners_all[IndexVal].balance
        Set_MyTokenBalance(newMyTokenBalance);
        setmybal(newMyTokenBalance)
        console.log(newMyTokenBalance,"=============newMyTokenBalancenewMyTokenBalance")
        Set_MyTokenDetail(tokenowners_all[IndexVal]);
      }
      else {
        newMyTokenBalance = 0;
        Set_MyTokenBalance(0);
        setmybal(0)
        Set_MyTokenDetail({});
      }
  }

  async function withdraw(item){
    setwithstatus(true)
    console.log(item,"====itemmmmmm")
    let datass = {
      "tokenCounts" : item.tokenCounts,
      "UserAccountAddr" : UserAccountAddr
    }
    let updaterect = await updateclaimdate(datass)
    updaterect = updaterect && updaterect.data && updaterect.data.data && updaterect.data.data
    //if(item && item.claimstatus){
    let CoursetroContract = ""
    var curdate = moment(new Date())
    var endclaim = moment(updaterect && updaterect.claimenddate && updaterect.claimenddate);
    //var startclaim = moment({ year :2022, month :6, day :9, hour :13, minute :0, second :0, millisecond :0});
    var startclaim = moment(updaterect && updaterect.claimstartdate && updaterect.claimstartdate);
    let diffclaim =  endclaim.diff(curdate)
    let anotherdiff = startclaim.diff(curdate)
   if(parseFloat(anotherdiff)<=0 &&  parseFloat(diffclaim)>0){
        setwithstatus(true)
          if(window.ethereum) {
              var web3 = new Web3(window.ethereum);
              if (web3&&web3.eth) {
                var connectwallet=localStorage.getItem("xdtitfnbolav");
                if(!connectwallet){
                  setwithstatus(false)
                }else{
                  console.log("===dssdfsdfdsfdsfdsfdsfdsfdfdsfsdfsdfdsf")
                    if(item.tokenCounts==1647460543){
                      CoursetroContract = new web3.eth.Contract(stakeabi, config.stakingaddress);
                    }else if(item.tokenCounts==1650863870){
                      CoursetroContract = new web3.eth.Contract(stakeabi, config.stakingaddress);
                    }else{
                      CoursetroContract = new web3.eth.Contract(multistackabi, config.multi_stakingaddress);
                    }
                   let pid = item.tokenCounts==1647460543 ? 0 : item.pid!=undefined && item.pid!=null && item.pid!="" ? item.pid : 0 
                     let rest = await CoursetroContract.methods.withdraw(pid).send({from: UserAccountAddr})
                      .then(async (resulty) => { 
                        console.log(resulty,"===============resultyresulty")
                        if(resulty && resulty.transactionHash){
                          var datas = {
                            gainusdt : item.gainusdt,
                            gainvbid : item.gainvbit,
                            transactionHash : resulty.transactionHash,
                            address : UserAccountAddr,
                            type : 0
                          }
                          var withadd = await updatewithdraw(datas);
                          toast.success("Successfully Completed", toasterOption);
                          window.location.reload();
                        }
                     })
                      .catch((error) => {
                        setwithstatus(false)
                        toast.error("Failed Tryagain Later", toasterOption);
                     })
                }
             }
           } 
    }else{
      setwithstatus(false)
        toast.error("Withdraw temporarily stop due to site maintenance", toasterOption);
    }
    // }else{
    //   toast.error("Withdraw temporarily stop due to site maintenance", toasterOption);
    // }
    
  }

  async function alert(){
    toast.error("Connect Wallet to Proceed Further", toasterOption);
  }

  async function unstake(item){
    let CoursetroContract = ""
    setstakestatus(true)
    if(window.ethereum) {
        var web3 = new Web3(window.ethereum);
        if (web3&&web3.eth) {
          var connectwallet=localStorage.getItem("xdtitfnbolav");
          if(!connectwallet){
            setstakestatus(false)
          }else{
             if(item.tokenCounts==1647460543){
              CoursetroContract = new web3.eth.Contract(stakeabi, config.stakingaddress);
            }else if(item.tokenCounts==1650863870){
              CoursetroContract = new web3.eth.Contract(stakeabi, config.stakingaddress);
            }else{
              CoursetroContract = new web3.eth.Contract(multistackabi, config.multi_stakingaddress);
            }
             let pid = item.tokenCounts==1647460543 ? 0 : item.pid!=undefined && item.pid!=null && item.pid!="" ? item.pid : 0 
             let rewards = await CoursetroContract.methods.unstake(pid).send({from: UserAccountAddr})
              .then(async (resulty) => { 
                console.log(resulty,"===============resultyresulty")
                if(resulty && resulty.transactionHash){
                  var datas = {
                    address : UserAccountAddr,
                    tokenCounts : item.tokencount
                  }
                  var withadd = await unstaketoken(datas);
                  toast.success("Successfully Completed", toasterOption);
                  window.location.reload();
                }
             })
              .catch((error) => {
                setstakestatus(false)
                toast.error("Failed Tryagain Later", toasterOption);
             })
          }
       }
     }
  }

  async function buyToken() {
    console.log('buyToken')
  }
  var {
    item,
    LikedTokenList,
    hitLike,
    UserAccountAddr,
    UserAccountBal,
    PutOnSale_Click,
    PlaceABid_Click,
    PurchaseNow_Click,
    Burn_Click,
    CancelOrder_Click,
    WalletConnected,
    ShareSocial_Click,
    SubmitReport_Click,
    //by muthu
    Set_item,
    Set_Bids,
    Set_BuyOwnerDetailFirst,
    Set_tokenCounts_Detail,
    Set_MyTokenBalance,
    Set_MyTokenDetail,
    Set_AllowedQuantity,
    Bids
  } = props;
  async function buyToken() {
    console.log('buyToken')
  }

  var {
    item,
    LikedTokenList,
    hitLike,
    UserAccountAddr,
    UserAccountBal,
    PutOnSale_Click,
    PlaceABid_Click,
    PurchaseNow_Click,
    Burn_Click,
    Stake_Click,
    CancelOrder_Click,
    WalletConnected,
    ShareSocial_Click,
    SubmitReport_Click,
    Set_item,
    Set_Bids,
    Set_BuyOwnerDetailFirst,
    Set_tokenCounts_Detail,
    Set_MyTokenBalance,
    Set_MyTokenDetail,
    Set_AllowedQuantity,
    Bids
  } = props;
  const renderer1 = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span></span>
    } else {
      return <span>{days}d  {hours}h {minutes}m {seconds}s to start</span>;
    }
  };
  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span></span>
    } else {
      return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
    }
  };

  var currDate = new Date();
  var startdate = new Date(item.clocktime);
  var enddate = new Date(item.endclocktime);
  var auction = "false";
  var finish = "";
  var enddate1 = "";
  var showlist = "true";
  var display_item = 0;
  if (item.type == 721 && item.PutOnSaleType == "TimedAuction") {
    auction = "true";
    var a = moment(item.clocktime);
    var b = moment(item.endclocktime);
    var c = moment();
    a.diff(b); // 86400000
    var diffInMs = a.diff(c);
    finish = b.diff(c);
    enddate1 = parseFloat(diffInMs);
    display_item = a.diff(c)
    if (finish > 0) {
      showlist = "true";
    } else {
      var auctionTxt = "Ended";
      showlist = "false";
    }
  }
  
  
  return (
    <div>
    {props.from=="stake" && item && ( ((item.contractAddress == "0x6a612C620e3f27622090Aa6C9874D6494466B947" && item.tokenCounts==1647460543)||(item.contractAddress == "0x6a612C620e3f27622090Aa6C9874D6494466B947" && item.tokenCounts==1654466819)) || ((item.contractAddress == "0xf6FFa015BfFFf65c621c3306BD3B8D9804b9Aeb7" && item.tokenCounts==1650863870)||(item.contractAddress == "0xD6b4C137DE64f0EBe550e1bb12c760c276200011" && item.tokenCounts==1657782803))) ? // item.tokenCounts==1647460543? 
     <div className="grid_view_single">
        <div className="grid_view_single_first">
          <a href={((WalletConnected == true && item.tokenowners_current &&
           (item.PutOnSaleType == 'FixedPrice' || item.PutOnSaleType == 'TimedAuction' || item.PutOnSaleType == 'UnLimitedAuction') 
           && item.tokenowners_current.tokenOwner && item.tokenowners_current.balance > 0 ) ?
          config.Front_URL+"/info/" + item.tokenCounts
          :
          "javascript:void(0)")
          }>
            {
              item.image.split('.').pop() == "mp4" ?
                <video src={`${config.ipfsurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid" controls />
                :
                item.image.split('.').pop() == "mp3" ?
                  <>
                    <img src={`${config.Back_URL}/images/music.png`} alt="" className="img-fluid " />
                    {/* <audio src={`${config.ipfsurl}/${item.ipfsimage}`} type="audio/mp3" controls className="audio" >
                      </audio> */}
                  </>
                  :
                  <img src={`${config.ipfsurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
            }
          </a>
          <div>
            <h2>{item.tokenName}</h2>                                        
          </div>                                      
        </div>
        <hr />
        <div className="grid_view_single_second">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <p>APY</p>
            <div className="d-flex align-items-center"><p className="stake_ptext">36 Months</p></div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <p>Earn</p>
            <div className="d-flex align-items-center"><p className="stake_ptext">{item.tokenCounts==1647460543 ? "25000 VBIT" : "5500 VBIT Per Month"} </p></div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <p>Earn</p>
            <div className="d-flex align-items-center"><p className="stake_ptext">{item.tokenCounts==1647460543 ? "333.33 USDT": "75 USDT Per Month"}</p></div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-3">
              <p>VBIT Earned</p>
              <div className="d-flex align-items-center"><p className="stake_ptext">{item.gainvbit && item.gainvbit!=null && item.gainvbit!=undefined && item.gainvbit!="" && item && item.stakedetails && item.stakedetails.status=="Active"? parseFloat(item.gainvbit).toFixed(4) : 0} VBIT</p></div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-3">
              <p>USDT Earned</p>
              <div className="d-flex align-items-center"><p className="stake_ptext">{item.gainusdt && item.gainusdt!=null && item.gainusdt!=undefined && item.gainusdt!="" && item && item.stakedetails && item.stakedetails.status=="Active"? parseFloat(item.gainusdt).toFixed(4) : 0} USDT</p></div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div>
              <p></p>
              <h4></h4>
            </div>
            {withstatus && item && item.stakedetails && item.stakedetails.status=="Active"?
                <Button className="harvest_btn">In Progress</Button> 
              : item && item.stakedetails && item.stakedetails.status=="Active" ?
                <Button className="harvest_btn" type="button" onClick={()=>withdraw(item)}>Claim</Button> 
              :
              ""  
            }
          </div>
         {/* <label className="mb-2">Start Earning</label> */}
         {/* <Button className="primary_btn w-100" type="button" onClick={()=>unstake(item)}>Unstake</Button> */}
         {/* <Button className="primary_btn w-100" type="button" disabled>Stake</Button> */}
         {/* <Button className="harvest_btn" type="button">Claim</Button> */}

          {(!localStorage.getItem("xdtitfnbolav"))?
              <Button className="primary_btn w-100" type="button" data-toggle="modal" data-target="#wallet_modal">Connect Wallet</Button>
            :
            stakestatus && item && item.stakedetails && item.stakedetails.status=="Active"? 
              <Button className="primary_btn w-100" type="button" >In Progress</Button>
              :  item && item.stakedetails && item.stakedetails.status=="Active"? 
              ""
              : mybal && parseFloat(mybal)>0 ?
                <Button className="primary_btn w-100" type="button" onClick={() => Stake_Click(item,item.tokenowners_current)}>Stake</Button>
              : localStorage.getItem("xdtitfnbolav") ?
              ""              
              :
              <Button className="primary_btn w-100" type="button" data-toggle="modal" data-target="#wallet_modal">Connect Wallet</Button>
            } 
        </div>
        
        {/*
<hr />
          <div className="grid_view_single_third">
         <div className="d-flex align-items-center justify-content-center">                                        
            <a class="accordian_link" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              Details
            </a>
          </div>
          <div class="collapse" id="collapseExample">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <h3>Total Liquidity:</h3>
                <h4>1,506,680</h4>
              </div>
              <div className="d-flex justify-content-between align-items-start">
                <h3>Ends in:</h3>
                <div className="text-right">
                  <p><Link to="/liquidity" target="_blank">blocks <i class="bi bi-clock"></i></Link></p>
                  <p><Link to="/liquidity" target="_blank">See Token Info <i class="bi bi-box-arrow-up-right"></i></Link></p>                                              
                  <p><Link to="/liquidity" target="_blank">View Contract <i class="bi bi-box-arrow-up-right"></i></Link></p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      :(props.from!="stake" || props.from==undefined || props.from==null || props.from=="") &&
      <div className="item">
        <div className="d-flex justify-content-end">

          {/* <div className="d-flex creators_details">
              <img src={require("../../assets/images/follower_1.png")} alt="User" className="img-fluid" />
              <img src={require("../../assets/images/follower_2.png")} alt="User" className="img-fluid" />
              <img src={require("../../assets/images/follower_3.png")} alt="User" className="img-fluid" />
            </div> */}
          <div class="dropdown" onClick={() => showAllwithPro(item)}>
            <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner &&item.tokenowners_current.balance > 0&& item.tokenowners_current.tokenOwner == UserAccountAddr) &&
                <a class="dropdown-item" href="javascript:void(0)" onClick={() => PutOnSale_Click(item, item.tokenowners_current)} href="#">{item.tokenowners_current.tokenPrice > parseFloat(0) ? 'Change price' : 'Put on sale'}</a>
              }
              {(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner == UserAccountAddr) && item.tokenowners_current.balance > 0&&item.tokenowners_current.tokenPrice > 0 &&
                <a class="dropdown-item" href="javascript:void(0)" onClick={() => CancelOrder_Click(item, item.tokenowners_current)} href="#">Cancel Order</a>
              }
              {(WalletConnected == true && item.tokenowners_current && (item.PutOnSaleType == 'FixedPrice' || item.PutOnSaleType == 'TimedAuction' || item.PutOnSaleType == 'UnLimitedAuction') && item.tokenowners_current.tokenOwner && item.tokenowners_current.balance > 0 && item.tokenowners_current.tokenOwner == UserAccountAddr) &&
                <a class="dropdown-item" href="javascript:void(0)" onClick={() => Burn_Click(item, item.tokenowners_current)} href="#"> Burn</a>
              }
               {/*{(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.balance > 0 && item.tokenowners_current.tokenOwner == UserAccountAddr) && (item.stakedetails || (item.stakedetails && item.stakedetails.status &&  item.stakedetails.status!="Active") || (item.stakedetails==undefined || item.stakedetails==null || item.stakedetails=="")) &&
                <a class="dropdown-item" href="javascript:void(0)" onClick={() => Stake_Click(item,item.tokenowners_current)} href="#"> Stake</a>
              }*/}
              {(WalletConnected == true && item.tokenowners_current && item.PutOnSaleType == 'FixedPrice' && item.tokenowners_current.tokenPrice > parseFloat(0) && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr) &&
                <a class="dropdown-item" href="javascript:void(0)" onClick={() => PurchaseNow_Click(item, item.tokenowners_current)} href="#">Purchase Now</a>
              }
              {(WalletConnected == true && item.tokenowners_current && (item.PutOnSale == false || item.PutOnSaleType == 'FixedPrice' || (item.PutOnSaleType == 'TimedAuction' && showlist == "true" && display_item <= 0) || item.PutOnSaleType == 'UnLimitedAuction') && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr) &&
                Bids
                && Bids.myBid
                && !Bids.myBid.status
                ?
                <div className="menu_itm dropdown-item">
                  <span onClick={() => PlaceABid_Click(item)}>New Bid</span>
                </div>
                :
                Bids
                  && Bids.myBid
                  && Bids.myBid.status
                  && Bids.myBid.status == 'pending' && (item.PutOnSale == false || item.PutOnSaleType == 'FixedPrice' || (item.PutOnSaleType == 'TimedAuction' && showlist == "true" && display_item <= 0) || item.PutOnSaleType == 'UnLimitedAuction') && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr ?
                  <div className="menu_itm dropdown-item"  >
                    <span onClick={() => PlaceABid_Click(item)}>Edit bid </span>
                  </div>
                  :
                  <div></div>
              }
              {(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr) &&
                <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#share_modal" onClick={() => ShareSocial_Click(item)}>Share</a>
              }
              {(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr) &&
                <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#report_modal" onClick={() => SubmitReport_Click(item)}>Report</a>
              }
              {(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner == UserAccountAddr) &&
                <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#share_modal" onClick={() => ShareSocial_Click(item)}>Share</a>
              }
            </div>
          </div>
        </div>
        <div className="nft_img_panel">
          <a href={((WalletConnected == true && item.tokenowners_current &&
           (item.PutOnSaleType == 'FixedPrice' || item.PutOnSaleType == 'TimedAuction' || item.PutOnSaleType == 'UnLimitedAuction') 
           && item.tokenowners_current.tokenOwner && item.tokenowners_current.balance > 0 ) ?
          config.Front_URL+"/info/" + item.tokenCounts
          :
          "javascript:void(0)")
          }>
            {
              item.image.split('.').pop() == "mp4" ?
                <video src={`${config.ipfsurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid" controls />
                :
                item.image.split('.').pop() == "mp3" ?
                  <>
                    <img src={`${config.Back_URL}/images/music.png`} alt="" className="img-fluid " />
                    {/* <audio src={`${config.ipfsurl}/${item.ipfsimage}`} type="audio/mp3" controls className="audio" >
                      </audio> */}
                  </>
                  :
                  <img src={`${config.ipfsurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
            }
          </a>
          {item && item.PutOnSaleType == "TimedAuction" && showlist == "true" && display_item > 0 &&
            <div className="countdown_panel">
              <div className="countdown_panel_div">
                <Countdown
                  date={startdate}
                  autoStart={true}
                  onStart={() => Date.now()}
                  renderer={renderer1}
                ></Countdown>
              </div>
            </div>
          }
          {item && item.PutOnSaleType == "TimedAuction" && showlist == "true" && display_item <= 0 &&
            <div className="countdown_panel">
              <div className="countdown_panel_div">
                <Countdown
                  date={enddate}
                  autoStart={true}
                  onStart={() => startdate}
                  renderer={renderer}
                ></Countdown>
                {/* <span className="left_text">Left</span> */}
              </div>
            </div>
          }
          {showlist == 'false' && auction == "true" &&
            <div className="countdown_panel">
              <div className="countdown_panel_div">
                <span>{auctionTxt}</span>
              </div>
            </div>
          }
          {(item.PutOnSale == true && item.PutOnSaleType != 'FixedPrice') &&
            <p className="mb-0">Auction</p>
          }
        </div>
        <a href={((WalletConnected == true && item.tokenowners_current &&item.tokenowners_current.balance > 0)
           ? config.Front_URL+"/info/" + item.tokenCounts
           :
           "javascript:void(0)")
           }>
          <h2>{item.tokenName}</h2>
        </a>

        <div className="creator_details">
          {(item.tokenCreatorInfo && item.tokenCreatorInfo.curraddress && item.tokenCreatorInfo.image) ?
            <a href={`${config.Front_URL}/user/${item.tokenCreatorInfo.curraddress}`} data-toggle="tooltip" data-placement="top" title={`Creator : ${item.tokenCreatorInfo.name}`}>
              <img src={`${config.Back_URL}/profile/${item.tokenCreatorInfo.image}`} alt="Collections" className="img-fluid" />
            </a>
            :
            <a href={`${config.Front_URL}/user/${item.tokenCreator}`} data-toggle="tooltip" data-placement="top" title={`Creator : ${item.tokenCreator}`}>
              <img src={`${config.Back_URL}/images/noimage.png`} alt="User" className="img-fluid imgRound" />
            </a>
          }
          {(isEmpty(item.tokenCreatorInfo && item.tokenCreatorInfo.name)) ?
            <p>{halfAddrShow(item.tokenCreator)}</p>
            :
            <p>{item.tokenCreatorInfo && item.tokenCreatorInfo.name}</p>

          }
        </div>

        <hr className="nft_hr" />
        <div className="d-flex justify-content-between align-items-end">
          <div>
            <h3>

              {(item.PutOnSale == true && item.PutOnSaleType == 'FixedPrice' && item && item.tokenowners_current && item.tokenowners_current.tokenPrice > 0) &&
                <span>
                  {item && item.tokenowners_current && parseFloat(item.tokenowners_current.tokenPrice)} {item.biddingtoken}
                </span>
              }
              {(item.PutOnSale == true && item.PutOnSaleType == 'TimedAuction') &&
                <span>{parseFloat(item.minimumBid)} {item.biddingtoken} </span>
              }
              {
                (
                  item.TabName != '' && (item.TabName == 'onsale' || item.TabName == 'created' || item.TabName == 'owned')
                )
                  ?
                  " " + item.tokenowners_my_balance + ' of ' + item.tokenowners_my_quantity
                  :
                  (
                    item.TabName != ''
                  )
                    ?
                    " " + item.tokenowners_all_balance + ' of ' + item.tokenQuantity
                    :
                    (
                      item.tokenowners_sale_balance > 0
                    )
                      ?
                      " " + item.tokenowners_sale_balance + ' of ' + item.tokenQuantity
                      :
                      " " + item.tokenowners_all_balance + ' of ' + item.tokenQuantity
              }
              {/* {item&&item.tokenowners_current&&item.tokenowners_current.quantity} of {item&&item.tokenowners_current&&item.tokenowners_current.quantity} */}
            </h3>
            <h4>
              {(item.PutOnSale == false || ((item.PutOnSale == true && item.PutOnSaleType) == 'FixedPrice' && (item && item.tokenowners_current && item.tokenowners_current.tokenPrice) == 0)) &&
                <span>Not for sale </span>
              }
              {((item.PutOnSale == true && item.PutOnSaleType) == 'FixedPrice' &&
                (item && item.tokenowners_current && item.tokenowners_current.tokenPrice) > 0) &&
                <span>Place a Bid</span>
              }
              {(item.PutOnSale == true && item.PutOnSaleType == 'TimedAuction') &&
                <span>Minimum Bid </span>
              }
              {(item.PutOnSale == true && item.PutOnSaleType == 'UnLimitedAuction') &&
                <span>Open for Bids </span>
              }
            </h4>
            {(item.PutOnSale == true && item.PutOnSaleType == 'FixedPrice'
              && item && item.tokenowners_current && item.tokenowners_current.tokenPrice > 0) &&
              <h4>${(item && item.tokenowners_current && parseFloat(item.tokenowners_current.tokenPrice) * usdval).toFixed(config.toFixed)}
              </h4>
            }
            {(item.PutOnSale == true && item.PutOnSaleType == 'TimedAuction') &&
              <h4>${(item && parseFloat(item.minimumBid) * usdval).toFixed(config.toFixed)}
              </h4>}

          </div>
          <div className="">
            {/* <div className="masonry_likes">
                          <i class="bi bi-chat-dots mr-2"></i>
                          <span>0</span>
                        </div> */}
            <div className="masonry_likes" onClick={() => hitLike(item)}>
              {
                (LikedTokenList.findIndex(tokenCounts => (tokenCounts.tokenCounts === item.tokenCounts)) > -1) ?
                  (<i className="fas fa-heart mr-2"></i>) :
                  (<i className="bi bi-heart mr-2"></i>)
              }
              <span class={item.tokenCounts + '-likecount'}>{item.likecount}</span>
            </div>
          </div>
        </div>
      </div>
    }
    </div>
  )
}