import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import FooterHome from "components/Footer/FooterHome.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styled from "../../node_modules/styled-components";
import { Pie } from "react-chartjs-3";
import styles from "assets/jss/material-kit-react/views/home.js";
import { Link,useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import Typist from 'react-typist';

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const data = {
  labels: ['Seed Sale (5%)', 'Private Sale (15%)', 'Public Sale (6.4%)', 'Pancake Liquidity (1.6%)', 'Liquidity Mining(20%)', 'Marketing(10%)','Team(10%)', 'Advisor(2%)', 'Development(10%)', 'Ecosystem Development(20%)'],
  datasets: [
    {
      label: 'Token Distribution',
      data: [5, 15, 6.4, 1.6, 20, 10, 10, 2, 10, 20 ],
      backgroundColor: [
        '#31951e',
        '#cf3810',
        '#a9ab00',
        '#0b96b0',
        '#49ca57',
        '#e27938',
        '#b8ab1c',
        '#2ebd88',
        '#09409a',
        '#07121d',

      ],
      borderColor: [
        '#31951e',
        '#cf3810',
        '#a9ab00',
        '#0b96b0',
        '#49ca57',
        '#e27938',
        '#b8ab1c',
        '#2ebd88',
        '#09409a',
        '#07121d',
      ],
      borderWidth: 1,
      hoverOffset: 4,
      devicePixelRatio: 10, 
    },
  ],
};

const options = {
  legend: {
    display: false,
    // "display": true,
    // "position": "bottom",
    // "fullWidth": true,
    // "reverse": false,
    // "labels": {
    //   "fontColor": "#000"
    // }
  }
};

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const history = useHistory();

  const [expanded, setExpanded] = React.useState('panel1');

 async function explore(){
   window.location.href="https://nft.valobitdx.io/nft"
 }
 async function launchApp(){
  window.location.href="https://valobitdx.io/"
 }
 async function buynow(){
  window.location.href="https://exchange.valobitdx.io/#/exchange"
 }
  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { ...rest } = props;

  var roadmap_settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const [count, setCount] = useState(1);
  useEffect(() => {
    // document.title = `You clicked ${count} times`;
    // console.log("Count: " + count);
    setCount(1);
  }, [count]);

// async function connectWallet(){
//   history.push("/nft");
// }

  return (
    <div className="home_header">      
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />    
      <ScrollToTopOnMount/>
      <div className="page-header header-filter border_botom_every_section">
        <div className={classes.container}>
          <GridContainer className="mt-5 ">
            <GridItem xs={12} sm={12} md={6} lg={6} data-aos="fade-up" data-aos-duration="2000" className="text-center m-auto">             
              <h1>Create, Buy, Sell, <br /> Swap and Farm NFTs</h1>
              <p>Trade, earn, and win crypto on the most popular decentralized platform in the galaxy.</p>
              <div className="home_banner_butn_section d-flex justify-content-start">
              {/* <Button className="home_primary_btn mt-3" onClick={()=>connectWallet()}>Connect Wallet</Button>
              <Button className="home_primary_btn mt-3 ml-5 trasparant_bg">Trade Now</Button> */}
              </div>
              
              
            </GridItem> 
            <GridItem xs={12} sm={12} md={6} lg={6} data-aos="fade-up" data-aos-duration="2000" className="text-center m-auto">             
             <div className="home_banner_v_bit">
             <img src={require("../assets/images/v_bit/home_image.png")} alt="Icon" className="img-fluid" />
             </div>
              
            </GridItem>            
          </GridContainer>
        </div>        
      </div>

      <div className="main">

     
        <div className="global_dis_section border_botom_every_section" name="why">
          <div className="container"> 
            <div className="section">
              <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <div className="text-left user_section_content_v_bit">                    
                    <h1 className="main_title">Used by millions. <br />Trusted with billions.</h1>
                    <p className="main_para">Valobitdx has the most users of any decentralized platform, ever. And those users are now entrusting the platform with over $9.2 billion in funds.</p>
                      <span className="will_accept">Will you join them?</span>
                    
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <div className="user_section_v-bit">
                    <div className="card_v_bit">
                    <img src={require("../assets/images/v_bit/user_1.png")} alt="Icon" className="img-fluid" />
                    <div>
                      <h3>2.8 million<span>Users</span></h3>
                      <p>in the last 30 days</p>
                      </div>
                    </div>
                    <div className="card_v_bit">
                    <img src={require("../assets/images/v_bit/user_2.png")} alt="Icon" className="img-fluid" />
                    <div>
                      <h3>31 million<span>Trades</span></h3>
                      <p>made in the last 30 days</p>
                      </div>
                    </div>
                    <div className="card_v_bit">
                    <img src={require("../assets/images/v_bit/user_3.png")} alt="Icon" className="img-fluid" />
                    <div>
                      <h3>$9.2 billion <span>Staked</span></h3>
                      <p>Total Value Locked</p>
                      </div>
                    </div>
                  </div>
                  
                </GridItem>
              </GridContainer>
              
            </div>
          </div>
        </div>

        {/* Product Section */}
        <div className="product_section section border_botom_every_section" name="product">
          <div className="container"> 
            <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
              <GridItem xs={12} sm={12} md={6} lg={6}>
              <img src={require("../assets/images/v_bit/coin_icon.png")} alt="Icon" className="img-fluid _img_secton" />
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
              <div className="text-left user_section_content_v_bit">                    
                    <h1 className="main_title">Trade anything. No<br />registration, no hassle.</h1>
                    <p className="main_para">Trade any token on Binance Smart Chain in seconds, just by connecting your wallet.</p>
                    <div className="home_banner_butn_section_ d-flex justify-content-start">
              <Button className="home_primary_btn mt-3">Trade Now</Button>
       
              </div>
                    
                  </div>
              </GridItem>
            </GridContainer>
          </div>
          
        </div>
        {/* Product Section */}
        <div className="product_section section border_botom_every_section" name="product">
          <div className="container"> 
            <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
             
              <GridItem xs={12} sm={12} md={6} lg={6}>
              <div className="text-left user_section_content_v_bit">                    
                    <h1 className="main_title">Earn passive income <br />with crypto.</h1>
                    <p className="main_para">Valobitdx makes it easy to make your crypto work for you.</p>
                    <div className="home_banner_butn_section d-flex justify-content-start">
              <Button className="home_primary_btn mt-3">Explore</Button>
       
              </div>
                    
                  </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
              <img src={require("../assets/images/v_bit/sytem_pc_img.png")} alt="Icon" className="img-fluid _img_secton" />
              </GridItem>
            </GridContainer>
          </div>
          
        </div>
         {/* Product Section */}
         <div className="product_section section" name="product">
          <div className="container"> 
            <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
              <GridItem xs={12} sm={12} md={6} lg={6}>
              <img src={require("../assets/images/v_bit/v-bit_coin.png")} alt="Icon" className="img-fluid _img_secton _img_secton_1" />
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
              <div className="text-left user_section_content_v_bit">                    
                    <h1 className="main_title">Valobitdex makes our world<br />go round.</h1>
                    <p className="main_para">Valobitdex token is at the heart of the Valobitdex ecosystem. Buy it, win it, farm it, spend it, stake it... heck, you can even vote with it!</p>
                    <div className="home_banner_butn_section d-flex justify-content-start">
                  <Button className="home_primary_btn mt-3" type="submit" onClick={()=>buynow()}>Buy Now</Button>
       
              </div>
                    
                  </div>
              </GridItem>
            </GridContainer>
          </div>
          
        </div>
        {/* Product Section */}
        <div className="product_section section border_botom_every_section" name="product">
          <div className="container"> 
            <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
              <GridItem sm={12} md={12} lg={12}>
            <div className="market_count_section">
              <div>
                <p>Total Supply</p>
                <h2>224,297,341</h2>
              </div>
              <div>
                <p>Burned to date</p>
                <h2>233,585,274</h2>
              </div>
              <div>
                <p>Market cap</p>
                <h2>$4.6 billion</h2>
              </div>
              <div>
                <p>Current emissions</p>
                <h2>15/block</h2>
              </div>
            </div>
              </GridItem>
              
            </GridContainer>
          </div>
          
        </div>
        <div className="global_dis_section border_botom_every_section" name="why">
          <div className="container"> 
            <div className="section">
              <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <div className="text-left user_section_content_v_bit">                    
                    <h1 className="main_title">Advanced Tools <br />for Traders</h1>
                    <p className="main_para">Thousands of traders choose MedexSwap over the alternatives because it automatically routes your trades through the best DEXs, giving you the best outcome, every time.</p>
                      <ul className="list_advance">
                        <li>Limit Order</li>
                        <li>Swap</li>
                        <li>Stop Loss</li>
                        <li>Launch Sniper</li>
                      </ul>
                      <div className="home_banner_butn_section d-flex justify-content-start">
                  <Button className="home_primary_btn mt-3" type="submit" onClick={()=>launchApp()}>Launch App</Button>
       
              </div>
                    
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <div className="user_section_v-bit_2">
                  <img src={require("../assets/images/v_bit/advance_tool_img.png")} alt="Icon" className="img-fluid exchange_img" />
                  </div>
                  
                </GridItem>
              </GridContainer>
              
            </div>
          </div>
        </div>
        <div className="global_dis_section border_botom_every_section" name="why">
          <div className="container"> 
            <div className="section">
              <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <div className="">
                  <img src={require("../assets/images/v_bit/last_banner_7.png")} alt="Icon" className="img-fluid tiger_img" />
                  </div>
                  
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <div className="text-left user_section_content_v_bit">                    
                    <h1 className="main_title">Valobitdex Marketplace</h1>
                      <p className="main_para">The enter platforms offer high quality NFTs made by carefully curated artists and musicians. Explore a diverse selection of amazing art!</p>
                      
                <div className="home_banner_butn_section d-flex justify-content-start">
                  <Button className="home_primary_btn mt-3" type="submit" onClick={()=>explore()}>Explore</Button>
                </div>
                    
                  </div>
                </GridItem>
              </GridContainer>
              
            </div>
          </div>
        </div>
      
       

        

        
        
        
        <div name="contact">
          <FooterHome />
        </div>
      </div>
    </div>
  );
}
