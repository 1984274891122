import React, {
  forwardRef,
  useImperativeHandle
} from 'react';

import Web3 from 'web3';
import $ from 'jquery';
import config from '../../lib/config';

import {
  AddLikeAction,
  GetLikeDataAction
} from '../../actions/v1/token';
import BEP1155 from '../../ABI/BEP1155.json';
import BEP721   from  '../../ABI/BEP721.json';
import VALOBITDX from '../../ABI/VALOBITDX.json';
import stakeabi from "../../ABI/staking"
import multistackabi from "../../ABI/Multistake_ABI";

import {
  getCurAddr
} from '../../actions/v1/User';
import {
  convertion,
  StakeField,
  TokenCounts_Get_Detail_Action
} from '../../actions/v1/token';
import { Button, TextField } from '@material-ui/core';
import {
  useHistory
} from "react-router-dom";
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

var web3              = new Web3(window.ethereum);
const VALOBITDXContract = new web3.eth.Contract(VALOBITDX,config.ValobitdxAddr)
const BEP721Contract    = new web3.eth.Contract(BEP721, config.singleContract)

const price = [
  { value: 'ETH', label: 'ETH' },
  { value: 'VALOBIT', label: 'VALOBIT' }
];

export const StakeRef = forwardRef((props, ref) => {
  const history = useHistory();
  const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');
  const [onwer_price,set_owner_price]=React.useState({})
  const [burnLoading, setBurnLoading] = React.useState('empty');
  const[noofitems,setnoofitem]=React.useState(1);
  const [showingLoader, setshowingLoader] = React.useState(false);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = React.useState({});
  const [OwnersDetailFirst, Set_OwnersDetailFirst] = React.useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = React.useState({});
  const [Bids, Set_Bids] = React.useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = React.useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = React.useState(0);
  var  [item1, Set_item1] = React.useState({}); 
  const [stakedata,setStakedata] = React.useState(0);

  var {
      item,
      UserAccountAddr,
      GetUserBal,
      Set_item
  } = props;
  async function FormSubmit(items,Staketoken){
    let CoursetroContract   = '' ; 
    setBurnLoading('processing');
      console.log("FormSubmit:",Staketoken)
      if(window.ethereum) {
        var web3 = new Web3(window.ethereum);
        if (web3&&web3.eth) {
          let use_address = window.web3.eth.defaultAccount;
          const BEP1155Contract   = new web3.eth.Contract(BEP1155, Staketoken.contractAddress)
          var connectwallet=localStorage.getItem("xdtitfnbolav");
          if(!connectwallet){
            setBurnLoading("try")
            toast.error("Please connect to a Metamask wallet", toasterOption);
            return false;
          }
          if(Staketoken.tokenCounts==1647460543){
            CoursetroContract = new web3.eth.Contract(stakeabi, config.stakingaddress);
          }else if(Staketoken.tokenCounts==1650863870){
            CoursetroContract = new web3.eth.Contract(stakeabi, config.stakingaddress);
          }else{
            CoursetroContract = new web3.eth.Contract(multistackabi, config.multi_stakingaddress);
          }
          if(1>MyTokenDetail.balance){
            setBurnLoading("try")
            toast.error('Your Token Balance is Low',toasterOption)
            return false;
          }
          setBurnLoading('processing');
          let s_add = Staketoken.tokenCounts==1647460543 ? config.stakingaddress : Staketoken.tokenCounts== 1650863870 ? config.stakingaddress : config.multi_stakingaddress
          BEP1155Contract.methods.setApprovalForAll(s_add,true).send({from: use_address})
            .then(async (resulty) => { 
          let pid = Staketoken.tokenCounts==1647460543 ? 0 : Staketoken.pid!=null && Staketoken.pid!=undefined && Staketoken.pid!="" ?Staketoken.pid : 0
          CoursetroContract
            .methods
            .stake(
              pid,
              Staketoken.tokenCounts
            )
            .send({from: use_address})
            .then(async (result) => {
              setBurnLoading('done');
              var postData = {
                tokenOwner: use_address,
                tokenCounts: Staketoken.tokenCounts,
                blockHash: result && result.blockHash?result.blockHash:"",
                transactionHash: result && result.transactionHash?result.transactionHash:"",
                contractAddress:Staketoken.contractAddress,
                type:Staketoken.type,
                balance:1,
                currAddr:use_address,
                quant:Staketoken.balance
              }
              var updateStakeField = await StakeField(postData)
              if(updateStakeField){
               toast.success("Staked Successfully", toasterOption);
               document.getElementById('closestake').click()
               window.location.reload()
              }
            })
            .catch((error) => {
              setBurnLoading("try")
              toast.error("Approve failed", toasterOption);
          })
        }) .catch((error) => {
              setBurnLoading("try")
              toast.error("Approve failed", toasterOption);
          })
       }
     }

  }
  const TokenCounts_Get_Detail_Call = async (payload) => {
    console.log("jaskjkdlasjldjlasldsa",item)
    var curAddr = await getCurAddr();
    setshowingLoader(true)
    var Resp = await TokenCounts_Get_Detail_Action(payload);
    setTimeout(() => {
      setshowingLoader(false)
    }, 2000);
    console.log(payload,"==alll==",Resp,curAddr)
    if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {

      var TokenResp = Resp.data.Detail.Resp;
      console.log("check all", TokenResp)
       if (TokenResp.Token && TokenResp.Token[0]) {
        props.Set_item(TokenResp.Token[0]);
      }
      if (
        TokenResp
        && TokenResp.Token
        && TokenResp.Token[0]
        && TokenResp.Token[0].tokenowners_current
      ) {
        for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
          const element = TokenResp.Token[0].tokenowners_current[i];
          set_owner_price(element)
          if (element.tokenPrice > 0 && element.tokenOwner != curAddr) {
            Set_BuyOwnerDetailFirst(element);
            break;
          }
          if (element.tokenPrice > 0 && element.tokenOwner == curAddr) {
            Set_OwnersDetailFirst(element);
            break;
          }
        }
      }
      Set_tokenCounts_Detail(TokenResp);
      if (TokenResp.Bids) {
        Set_Bids(TokenResp.Bids);
      }

      var IndexVal = -1;
      if (TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        IndexVal = tokenowners_all.findIndex(val => (val.tokenOwner.toString() == curAddr.toString() && val.balance > 0));
      }
      // console.log("check all val")
    
      if (IndexVal > -1) {
        // console.log("check all val1",tokenowners_all)
        Set_MyTokenBalance(tokenowners_all[IndexVal].balance);
        Set_MyTokenDetail(tokenowners_all[IndexVal])
      }
      else {
        Set_MyTokenDetail({});
        Set_MyTokenBalance(0);
      }

     
    
  }
  }
  const handleChange = (e) => {
    console.log("@@@@@@@@@@",e.target.value)
    
      if(MyTokenDetail>=e && e.target && typeof e.target.value != 'undefined'){
        setnoofitem(e.target.value)
        setBurnLoading('init');
      }
      else if(e.target.value == 0){
        setBurnLoading('zero');
        setnoofitem(0)
      }
      else if(e.target.value == "" ){
        setBurnLoading('empty');
        setnoofitem("")
      }else if(e.target.value == undefined ){
        setBurnLoading('empty');
        setnoofitem("")
      }
      else{
        setBurnLoading('errors');
        setnoofitem("")
      }
  }
  useImperativeHandle(
      ref,
      () => ({
          async Stake_Click(item,stakedata) {
            var connectwallet=localStorage.getItem("xdtitfnbolav");
            if(!connectwallet){
              toast.error("Please connect to a Metamask wallet", toasterOption);
              return false;
            }
            setStakedata(stakedata);
              var curAddr = await getCurAddr();
                  var payload = {
                    curAddr: curAddr,
                    tokenCounts: item.tokenCounts
                  };
                  TokenCounts_Get_Detail_Call(payload);
              window.$('#stake_token_modal').modal('show');
             
          },
      }),
  )

  return (
      <div class="modal fade primary_modal" id="stake_token_modal" tabindex="-1" role="dialog" aria-labelledby="burn_token_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
    <h5 class="modal-title" id="burn_token_modalLabel">Stake token</h5>
    <button type="button" id="closestake" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body px-0">
      <form className="bid_form">
      <div className="px-4">
          </div> 
        <div className="px-4">
          <p className="text-light">Are you sure to Stake this token? This action cannot be undone. Token will be Staked to Your Wallet</p>
        </div>  
        <div className="px-4 mt-4 pt-2">               
        
          <div className="text-center mt-3">
            <Button 
              className="burn_btn_red primary_btn btn-block" 
              onClick={(() => FormSubmit(item,MyTokenDetail))}
              disabled = { (burnLoading == 'done' || burnLoading=='processing' ||burnLoading == 'errors') }
              >
                {burnLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                {burnLoading == 'init' && 'Continue'}
                {burnLoading == 'processing' && 'In-progress...'}
                {burnLoading == 'done' && 'Done'}
                {burnLoading == 'try' && 'Try Again'}
                {burnLoading == 'errors' && 'Check Balance'}
                {burnLoading == 'empty' && "Continue"} 
                
              </Button>
            <Button className="btn_outline_grey cancel_btn btn-block" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>Cancel</Button>
          </div>
        </div>
      

      </form>
    </div>
  </div>
  </div>
</div>
  )
})

