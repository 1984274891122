import React, { useState, useEffect,useRef } from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withStyles } from "@material-ui/core/styles";
import { Link ,useHistory,useParams} from "react-router-dom";
import { Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import SidebarLinksAfterlogin from "components/Header/SidebarLinksAfterlogin.js";
import HeaderDashboard from "components/Header/HeaderDashboard";
import WalletModal from "components/WalletModal.js";
import Countdown, { zeroPad } from "react-countdown";
import Select from "react-select";
import stakeabi from "../ABI/staking"
import multistakeabi from "../ABI/Multistake_ABI"
import $ from 'jquery';
import axios from 'axios';
import config from '../lib/config';

import {
    getWallet,
    halfAddrShow,
    getCurAddr,
    FollowChange_Action,
    changeReceiptStatus_Action,
    ParamAccountAddr_Detail_Get,
    User_FollowList_Get_Action,
    User_Following_List_Action,
    User_Followers_List_Action,
    User_Follow_Get_Action,
    Activity_List_Action,
    UserProfile_Update_Action
  } from '../actions/v1/User';
  
  import {
    CollectiblesList_Stake,
    Bidding_Detail_Action,
    ReportRequest,
    getstatus
  } from '../actions/v1/token';
  

import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { StakeRef } from './separate/stake';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import HeaderSearch from './separate/HeaderSearch'
import VALOBITDX from '../ABI/VALOBITDX.json';
import TokenItem from './separate/Token-Item';
import BidPopup from './separate/Bid-Popup';
import ConnectWallet from './separate/Connect-Wallet';

import ReactLoading from 'react-loading';

import Web3 from 'web3';
import '@metamask/legacy-web3'

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;
const drawerWidth = 240;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(0deg)"
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(180deg)"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 7.5 + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  grow: {
    flexGrow: 1
  }
});

const NFT =(props) => {
  const [open,setOpen] =useState(true)
   const [anchorEl,setAnchorEl] =useState(null)

   const history             = useHistory();
   const { ...rest }         = props;
   const [providerss,set_providers]=useState(null)
 
   var LikeForwardRef        = useRef();
   var PutOnSaleForwardRef   = useRef();
   var PurchaseNowForwardRef = useRef();
   var WalletForwardRef      = useRef();
   var ReportForwardRef      = useRef();
   var ShareForwardRef       = useRef();
   var PlaceABidForwardRef   = useRef();
  const StakeForwardRef      = useRef();
   var CancelOrderForwardRef = useRef();
   var BurnForwardRef        = useRef();
   var { paramUsername, paramAddress } = useParams();
   if(typeof paramUsername == 'undefined') { paramUsername = ''; }
   if(typeof paramAddress == 'undefined') { paramAddress = ''; }
 
   const [description,setdescription]  = React.useState("");
   const [ParamAccountCustomUrl, Set_ParamAccountCustomUrl] = useState(paramUsername);
   const [ParamAccountAddr, Set_ParamAccountAddr]           = useState(paramAddress);
   const [UserNotFound, Set_UserNotFound]                   = useState(false);
   const [FollowingUserList, Set_FollowingUserList]         = useState([]);
   const [WalletConnected, Set_WalletConnected]             = useState('false');
   const [UserAccountAddr, Set_UserAccountAddr]             = useState('');
   const [UserAccountBal, Set_UserAccountBal]               = useState(0);
   const [AddressUserDetails, Set_AddressUserDetails]       = useState({});
   const [Accounts, Set_Accounts]                           = useState('');
   const [MyItemAccountAddr, Set_MyItemAccountAddr]         = useState('');
   const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState('');
   const [HitItem, Set_HitItem]                             = useState({});
   const [LikedTokenList, setLikedTokenList]                = useState([]);
   const [OnSale_Count, Set_OnSale_Count]                   = useState(0);
   const [OnSale_List, Set_OnSale_List]                     = useState([]);
   const [Collectibles_Count, Set_Collectibles_Count]       = useState(0);
   const [Collectibles_List, Set_Collectibles_List]         = useState([]);
   const [Created_Count, Set_Created_Count]                 = useState(0);
   const [Created_List, Set_Created_List]                   = useState([]);
   const [Owned_Count, Set_Owned_Count]                     = useState(0);
   const [Owned_List, Set_Owned_List]                       = React.useState([]);
   const [Liked_Count, Set_Liked_Count]                     = useState(0);
   const [Liked_List, Set_Liked_List]                       = useState([]);
 
   const [Bids, Set_Bids] = useState([]);
   const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
   const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
   const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
   const [MyTokenDetail, Set_MyTokenDetail] = useState({});
   const [AllowedQuantity, Set_AllowedQuantity] = useState(0);
   const [YouWillPay, Set_YouWillPay] = useState(0);
   const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
   const [YouWillGet, Set_YouWillGet] = useState(0);
   const [item, Set_item] = useState({}); 
   const [tokenCounts, Set_tokenCounts] = useState(0);
   const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
   const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
   const [NoOfToken, Set_NoOfToken] = useState(1);
   const [ValidateError, Set_ValidateError] = useState({});
   const [TokenBalance, Set_TokenBalance] = useState(0);
   const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
   const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
 
   const [followingCount, Set_followingCount] = useState(0);
   const [followersCount, Set_followersCount] = useState(0);
   const [followingStatus, Set_followingStatus] = useState(true);
   const [loadMoreBtn, Set_loadMoreBtn] = useState(true);
   const [followersPage, Set_followersPage] = useState(1);
   const [followingPage, Set_followingPage] = useState(1);
   const [followersStatus, Set_followersStatus] = useState(true);
   const [FollowerUserList, Set_FollowerUserList] = React.useState([]);
   const [loadMoreBtn1, Set_loadMoreBtn1] = useState(true);
   const [is_follow, Set_is_follow] = useState("no");
   const [ActivityList, Set_ActivityList] = React.useState([]);
   const [Activitycount, Set_Activitycount] = React.useState(0);
   const [CurTabName, Set_CurTabName] = React.useState('created');
 
   const [TokenFile, setTokenFile]                 = useState("");
   const [TokenFilePreReader, setTokenFilePreReader] = useState("");
   const [TokenFilePreUrl, setTokenFilePreUrl]     = useState("");
   const [coverimage, setCoverimage]               = useState("");
   const [MyItemcoverimage,Set_MyItemcoverimage]   = useState("");
   const [totaluserbidAmt,setTotaluserbidAmt]      = useState(0);
   const [walletaddr, setWalletaddr]               = useState("");
   const [ShareUrl, setShareUrl] = React.useState("");
   const [Show, setShow] = React.useState("");
     
   async function CorrectDataGet(Resp,Target) {
    var RetData = { count : 0, list : [] }
    if (
      Resp
      && Resp.data
      && Resp.data.Target
      && Resp.data.list
      && Resp.data.list[0]
    ) {
      // if(Resp.data.Target == 'Count' && Resp.data.list[0].count) {
      //   RetData.count = Resp.data.list[0].count;
      // }
      // else
       if(Resp.data.Target == 'List' && Resp.data.list[0]) {
        RetData.list = Resp.data.list;
      }
    }
    if(
      Resp
      && Resp.data
      && Resp.data.Target
      && Resp.data.changeStatusList) {
        changeReceiptStatus_Call(Resp.data.changeStatusList);
    }
    return RetData;
  }
  async function changeReceiptStatus_Call(list) {
    var web3 = new Web3(window.ethereum);
    list.map(async (item) => {
      if (item && typeof item.checkAdd != "undefined" && item.checkAdd.hashValue) {
        try {
          var data = await web3.eth.getTransactionReceipt(item.checkAdd.hashValue);
          var hashValue = item.checkAdd.hashValue;
          if (data == null) {
          } else {
            if(data.status == '0x0') {
            } else {
              var payload = {
                status: 'true',
                hashValue: hashValue
              }
              await changeReceiptStatus_Action(payload);
            }
          }
        }
        catch(err) {
          // console.log('err', err);
        }
      }
    })
  }

   async function Tab_Click(TabName) {
     Set_CurTabName(TabName);
     await Tab_Data_Call('List',TabName);
     await Tab_Data_Call('Count',TabName);
   }
   async function Tab_Data_Call(Target, TabName, init=false) {
    var web3 = new Web3(config.networkUrl);
    var CoursetroContract = new web3.eth.Contract(stakeabi, config.stakingaddress);
    var Multistack_Contract = new web3.eth.Contract(multistakeabi, config.multi_stakingaddress);
    let rewards = await CoursetroContract.methods.poolInfo(0).call();

    var stakeadd = rewards && rewards[0] ? rewards[0] : ""
       var ReqData = {
         Addr:MyItemAccountAddr,
         MyItemAccountAddr:MyItemAccountAddr,
         ParamAccountAddr:ParamAccountAddr,
         UserAccountAddr:UserAccountAddr,
         stakeadd: stakeadd,
         Target:Target,
         TabName:TabName,
         init:init,
         from:'My-Items'
       };
       var Resp = {};
       Resp = await CollectiblesList_Stake(ReqData);
       var RespNew = await CorrectDataGet(Resp);
       if(
         (Target=='Count' && typeof RespNew.count != 'undefined')
         ||
         (Target=='List' && RespNew.list)
       ) {
           if(Target=='Count') { Set_Owned_Count(RespNew && RespNew.count ? RespNew.count : 1 ); }
          // if(Target == 'List') { Set_Owned_List(RespNew.list); }
           let totarry = RespNew.list
           if(Target == 'List') {
              let poollength = await Multistack_Contract.methods.PoolLength().call();
              if(parseInt(poollength)>0){
                for(let i =0 ; i<poollength;i++ ){
                let contractadd = await Multistack_Contract.methods.poolInfo(i).call()
                contractadd = JSON.stringify(contractadd)
                contractadd = JSON.parse(contractadd)
                console.log(contractadd,"======checking1")
                let rec_det = await getanother(RespNew.list,contractadd,TabName,Target,init)
                console.log(rec_det,"====checking2")
                totarry = rec_det && rec_det!=undefined ? totarry.concat(rec_det) : totarry
                if(parseInt(i)+parseInt(1)==poollength){
                  totarry = totarry.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                      t.tokenCounts === value.tokenCounts && t.tokenowners_current.tokenOwner === value.tokenowners_current.tokenOwner
                    ))
                  )
                  console.log(totarry,"========checking3")
                  let uniquearray = await getrewards(totarry);
                  //const interval = setInterval(async() => {
                    console.log(uniquearray,"======checking4")
                      uniquearray = await getrewards(uniquearray)
                      console.log(uniquearray,"======checking5")
                  //}, 3000);
                }
              }
            }else{
              await getrewards(RespNew.list);
            }
          }

       }
      return true
   }

   async function getrewards(array){
    let status_rec = await getstatus()
    console.log(array,"==========status_recstatus_recstatus_rec")
    let unique_status = status_rec && status_rec.result && status_rec.result.data?status_rec.result.data:""; 
    let uniquearr = [];
    for (const item of array){
        let uniqueitem = item;
        let gainusdt = 0 ;
        let gainvbit = 0 ; 
        let CoursetroContract = "" ;
         var web3 = new Web3(window.ethereum);
         if (web3&&web3.eth) {
          if(UserAccountAddr && UserAccountAddr!=undefined && UserAccountAddr!=null && UserAccountAddr!=""){
            if(item.tokenCounts==1647460543){
              CoursetroContract = new web3.eth.Contract(stakeabi, config.stakingaddress);
            }else if(item.tokenCounts==1650863870){
              CoursetroContract = new web3.eth.Contract(stakeabi, config.stakingaddress);
            }else{
              CoursetroContract = new web3.eth.Contract(multistakeabi, config.multi_stakingaddress);
            }  
              let pid = item.tokenCounts==1647460543 ? 0 : item.pid ? item.pid : 0
              let itemstatus = item.tokenCounts==1647460543 ? unique_status.status555 : item.tokenCounts==1650863870 ? unique_status.status555 : unique_status.status250
              let rewards = await CoursetroContract.methods.availableReward(pid,UserAccountAddr).call();
               if(rewards && rewards[0]){
                 gainusdt = rewards[0]/10000000000000000;   //extra 10 zeros
                 gainvbit = rewards[1]/1000000000000000000;
                 uniqueitem.gainusdt = gainusdt;
                 uniqueitem.gainvbit = gainvbit;
                 uniqueitem.claimstatus = itemstatus;
                 uniquearr.push(uniqueitem);
                 if(uniquearr.length == array.length){
                  Set_Owned_List(uniquearr)
                  return uniquearr
                 }
               }
             }else{
                 uniqueitem.gainusdt = 0;
                 uniqueitem.gainvbit = 0;
                 uniqueitem.claimstatus = false;
                 uniquearr.push(uniqueitem);
                 if(uniquearr.length == array.length){
                  Set_Owned_List(uniquearr)
                  return uniquearr
                 }
             }
            }
          }
    
   }

   async function getanother(list,contractadd,TabName,Target,init){
       var ReqData1 = {
         Addr:MyItemAccountAddr,
         MyItemAccountAddr:MyItemAccountAddr,
         ParamAccountAddr:ParamAccountAddr,
         UserAccountAddr:UserAccountAddr,
         stakeadd: contractadd.nftToken,
         Target:Target,
         TabName:TabName,
         init:init,
         from:'My-Items'
       };
       var Resp1 = {};
       Resp1 = await CollectiblesList_Stake(ReqData1);
       var RespNew1 = await CorrectDataGet(Resp1);
       let arr = [];
       for (let i =0 ; i<RespNew1.list.length;i++){
        if(RespNew1.list[i]){
        arr.push(RespNew1.list[i])
        if((parseInt(RespNew1.list.length)) == arr.length){
          return arr
        }
      }else{
        if((parseInt(RespNew1.list.length)) == arr.length){
          return arr
        }
       }
      }
   }
 
   async function FollowingTab(address, pos, isFollow) {
     var currAddr = await getCurAddr();
     var web3 = new Web3(window.ethereum);
     var Payload = {};
     Payload.currAddr = currAddr;
     Payload.ParamAccountAddr = address;
 
     var msg = "I would like to follow user: "+address
     if(isFollow=="yes"){
       msg = "I would like to stop following user: "+address
     }
 
     await  web3.eth.personal.sign(msg, currAddr)
     var Resp = await FollowChange_Action(Payload);
     var foll = (Resp && Resp.data && Resp.data.ChangeType)?Resp.data.ChangeType:"no";
 
     if((ParamAccountAddr !="" && currAddr !="" && currAddr==ParamAccountAddr) || (ParamAccountAddr=="" || !ParamAccountAddr)){
       //same user
       FollowingUserList.splice(pos, 1);
       Set_followingStatus(false);
       Set_FollowingUserList(FollowingUserList);
       Set_followingStatus(true);
 
       var followingcnt = (Resp && Resp.data && Resp.data.following)?Resp.data.following:"0";
       var followerscnt = (Resp && Resp.data && Resp.data.followers)?Resp.data.followers:"0";
       Set_followingCount(followingcnt);
       Set_followersCount(followerscnt);
     } else {
       //another user
       FollowingUserList[pos].isFollow = foll;
       Set_followingStatus(false);
       Set_FollowingUserList(FollowingUserList);
       Set_followingStatus(true);
     }
     if(foll=="yes"){
       toast.success("Follow successfully", toasterOption);
     }else{
       toast.success("Un-Follow successfully", toasterOption);
     }
     
   }
   async function FollowChange_Call() {
 
     var web3 = new Web3(window.ethereum);
     var currAddr = await getCurAddr();
     var Payload = {};
     Payload.currAddr = currAddr;
     Payload.ParamAccountAddr = ParamAccountAddr;
 
     var msg = "I would like to follow user: "+ParamAccountAddr
     if(is_follow=="yes"){
       msg = "I would like to stop following user: "+ParamAccountAddr
     }
 
     await  web3.eth.personal.sign(msg, currAddr)
     var Resp = await FollowChange_Action(Payload);
 
     var foll = (Resp && Resp.data && Resp.data.ChangeType)?Resp.data.ChangeType:"no";
     Set_is_follow(foll);
 
     if(foll=="yes"){
       toast.success("Follow successfully", toasterOption);
     } else {
       toast.success("Un-Follow successfully", toasterOption);
     }
     Check_follow();
     if(CurTabName == 'followers') {
       Get_Followers_List();
     }
     else if(CurTabName == 'following') {
       Get_Following_List();
     }
 
     //User_FollowList_Get_Call();
   }
 
 
   useEffect(() => {
    Tab_Data_Call('Count','created',true);
    Tab_Data_Call('List','created');
   }, []);

   async function getinterval(OwnedList){
      if(OwnedList && OwnedList.length>0){
       let uniquearray = await getrewards(OwnedList);
     }
   }
 
   const Get_MyItemAccountAddr_Details = async (payload) => {
     var Resp = await ParamAccountAddr_Detail_Get(payload);
     if(Resp.data&&Resp.data.User&&Resp.data.User.coverimage){
       Set_MyItemcoverimage(Resp.data.User.coverimage);
     }
     
     if(
       Resp
       && Resp.data
       && Resp.data.User
       && Resp.data.User.curraddress
     ) {
       Set_MyItemAccountAddr(Resp.data.User.curraddress);
       if(Resp.data.User) {
         Set_MyItemAccountAddr_Details(Resp.data.User);
       }
     }
     else {
       ///toast.warning('User not found', toasterOption);
       //Set_UserNotFound(true);
     }
   }
   async function BiddingAmout(){
     try{
       var currAddr = window.web3.eth.defaultAccount;
       var payload={currAddr}
       var Resp = await Bidding_Detail_Action(payload);
       if(Resp&&Resp.data){
         setTotaluserbidAmt(Resp.data.res1)
       }
     }catch(err){
     }
   }
 
   async function  getwalletlist(){
     try{
       var currAddr = window.web3.eth.defaultAccount;
       var payload={
         address:currAddr
       }
       var {result} = await getWallet(payload)
       if(result&&result.data){
         setWalletaddr(result.data.address)
       }
     }catch(err){}
   }
   const AfterWalletConnected = async () => {
     var currAddr = await getCurAddr();
     BiddingAmout();
    // getwalletlist()
     //if(typeof currAddr != 'undefined'){
       if(ParamAccountAddr || ParamAccountCustomUrl) {
         if(ParamAccountAddr && ParamAccountAddr.toString() === currAddr.toString()) {
           Set_MyItemAccountAddr(ParamAccountAddr);
           Get_MyItemAccountAddr_Details({addr:currAddr});
         }
         else {
           var payload = {}
           if(ParamAccountAddr) {
             payload.addr = ParamAccountAddr;
           }
           else if(ParamAccountCustomUrl){
             payload.customurl = ParamAccountCustomUrl;
           }
           await Get_MyItemAccountAddr_Details(payload);
         }
       }
       else {
         //Set_MyItemAccountAddr(currAddr);
         //Get_MyItemAccountAddr_Details({addr:currAddr});
       }
 
       window.$('#AfterWalletConnected_two').click();
       // After set state value not getting so above click function i used.
       // After this onclick state value getting purfectly (By Raj)
    // }
    
    
   }
 
   async function AfterWalletConnected_two() {
    setTimeout(() => {
       Tab_Data_Call('Count','created',true);
       Tab_Data_Call('List','created');
    }, 600);
   }
 
   async function User_FollowList_Get_Call() {
     var resp = await User_FollowList_Get_Action({
       target:'following',
       addr:MyItemAccountAddr,
       follower:MyItemAccountAddr
     });
     if(
       resp
       && resp.data
       && resp.data.list
     ) {
       var MyFollowingList = resp.data.list;
       Set_FollowingUserList(MyFollowingList);
       var FollowIndex = MyFollowingList.findIndex(e => e.follower == MyItemAccountAddr);
     }
     else {
       Set_FollowingUserList([]);
     }
   }
   async function GetUserBal() {
     await WalletForwardRef.current.GetUserBal();
   }
 
   const loadFollowing = async (e) => {
 
     var currAddr = await getCurAddr();
     var resp = await User_Following_List_Action({
       addr:MyItemAccountAddr,
       loginUser:currAddr,
       page: followingPage
     });
     
     if(
       resp
       && resp.data
       && resp.data.list
     ) {
       var List = resp.data.list;
       if(List && List.length > 0){
         Set_followingStatus(false);
         var totalList = FollowingUserList.concat(List);
         Set_FollowingUserList(totalList);
         Set_followingStatus(true);
       }else{
         Set_loadMoreBtn(false);
       }
       
     }
   }
   async function Check_follow(){
     var data = {
       useraddress:UserAccountAddr,
       followaddress:MyItemAccountAddr,
     }
     var resp = await User_Follow_Get_Action(data);
     var foll = (resp && resp.data && resp.data.isFollow)?resp.data.isFollow:"no";
     var followingcnt = (resp && resp.data && resp.data.following)?resp.data.following:"0";
     var followerscnt = (resp && resp.data && resp.data.followers)?resp.data.followers:"0";
     Set_is_follow(foll);
     Set_followingCount(followingcnt);
     Set_followersCount(followerscnt);
   }

   async function copyToClipboard(e) {
    var textField = document.createElement('textarea')
    textField.innerText = MyItemAccountAddr;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toast.success("Copied Successfully", toasterOption);
  }
 
   async function FollowerTab(address, pos, isFollow) {
 
     var web3 = new Web3(window.ethereum);
     var currAddr = await getCurAddr();
     var currentUser ="no";
     if((ParamAccountAddr !="" && currAddr !="" && currAddr==ParamAccountAddr) || (ParamAccountAddr=="" || !ParamAccountAddr)){
       currentUser ="yes";
     }
     var msg = "I would like to follow user: "+address
     if(isFollow=="yes"){
       msg = "I would like to stop following user: "+address
     }
 
     var Payload = {};
     Payload.currAddr = currAddr;
     Payload.ParamAccountAddr = address;
     Payload.currentUser = currentUser;
     await  web3.eth.personal.sign(msg, currAddr)
     var Resp = await FollowChange_Action(Payload);
 
     var foll = (Resp && Resp.data && Resp.data.ChangeType)?Resp.data.ChangeType:"no";
     FollowerUserList[pos].isFollow = foll;
     Set_followersStatus(false);
     Set_FollowerUserList(FollowerUserList);
     Set_followersStatus(true);
 
     if(currentUser=="yes"){
       var followingcnt = (Resp && Resp.data && Resp.data.following)?Resp.data.following:"0";
       var followerscnt = (Resp && Resp.data && Resp.data.followers)?Resp.data.followers:"0";
       Set_followingCount(followingcnt);
       Set_followersCount(followerscnt);
     }
     if(foll=="yes"){
       toast.success("Follow successfully", toasterOption);
     }
     else{
       toast.success("Un-Follow successfully", toasterOption);
     }
     Check_follow();
   }
 
   const loadFollowers = async (e) => {
     var currAddr = await getCurAddr();
     var resp = await User_Followers_List_Action({
       addr:MyItemAccountAddr,
       loginUser:currAddr,
       page: followersPage
     });
     
     if(
       resp
       && resp.data
       && resp.data.list
     ) {
       var MyFollowerList = resp.data.list;
       if(MyFollowerList && MyFollowerList.length > 0){
         Set_followersStatus(false);
         var totalList = FollowerUserList.concat(MyFollowerList);
         Set_FollowerUserList(totalList);
         Set_followersStatus(true);
       }else{
         Set_loadMoreBtn1(false);
       }
     }
   }
 
   async function Get_Followers_List() {
     Set_CurTabName('followers');
     var currAddr = await getCurAddr();
     var resp = await User_Followers_List_Action({
       addr:MyItemAccountAddr,
       loginUser:currAddr
     });
     
     if(
       resp
       && resp.data
       && resp.data.list
     ) {
       var MyFollowingList = resp.data.list;
       Set_FollowerUserList(MyFollowingList);
     }
     else {
       Set_FollowerUserList([]);
     }
   }
 
   async function Get_Following_List() {
     Set_CurTabName('following');
     var currAddr = await getCurAddr();
     var resp = await User_Following_List_Action({
       addr:MyItemAccountAddr,
       loginUser:currAddr
     });
     
     if(
       resp
       && resp.data
       && resp.data.list
     ) {
       var MyFollowingList = resp.data.list;
       Set_FollowingUserList(MyFollowingList);
     }
     else {
       Set_FollowingUserList([]);
     }
   }
   async function Get_Activity_list(){
     Set_CurTabName('activity');
     var resp = await Activity_List_Action({
       addr:MyItemAccountAddr
     });
    
     if(
       resp
       && resp.data
       && resp.data.list
     ) {
       var List = resp.data.list;
       if (List.length>0) {
         Set_Activitycount(List.length)
       }
       Set_ActivityList(List);
     }
     else {
       Set_ActivityList([]);
     }
 
   }
   const inputChange = (e) => {
    if(e && e.target && typeof e.target.value != 'undefined') {
        var value = e.target.value;
        setdescription(value);
        if(e.target.value !=""){
            Set_ValidateError({});
        }
    }
  }
   async function onprofileshare(){
     var url = config.Front_URL
     setShareUrl(url);
     window.$('#share_modal').modal('show');
     setShow(true)
   }
   const ReportValidation = async (data={}) => {
    var ValidateError = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    if(description == '' || typeof description=="undefined") {
        ValidateError.description = '"Description" is not allowed to be empty';
    }
    Set_ValidateError(ValidateError);
    return ValidateError;
}
   async function submituserreport(){
    var errors = await ReportValidation();
    var errorsSize = Object.keys(errors).length;
    if(errorsSize != 0) {
        toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
        return false;
    }
    var currAddr = await getCurAddr();
    var reqData ={
            reportuser:currAddr,
            description:description,
            type:"profile",
            spanuser:(MyItemAccountAddr != UserAccountAddr) && UserAccountAddr && UserAccountAddr!="" && UserAccountAddr!=undefined && UserAccountAddr!=null? MyItemAccountAddr:UserAccountAddr,
        }
        var response = await ReportRequest(reqData);
        if(response && response.status=="true"){
            toast.success("Successfully submit your report", toasterOption);
            setdescription("");
            setTimeout(function(){
                window.$('#report_page_modal').modal('hide');
            },100);
            setTimeout(function(){
            window.location.reload(false);
          },400);
        }else{
            toast.error('Oops something went wrong.!', toasterOption);
        }
  }


    // Countdown Timer
    const currentDate = new Date();
    const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

    const renderer = ({ days, hours, minutes, seconds }) => {
      return (
        <div className="timer_panel">
          <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">d</span></span>
          <span className="timer_dots"> </span>
          <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">h</span></span>
          <span className="timer_dots"> </span>
          <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">m</span></span>
          <span className="timer_dots"> </span>
          <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">s</span></span>
        </div>
      );
    };
    const { classes, theme } = props;


  const handleDrawerOpen = () => {
    setOpen(!open)
  };
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
setAnchorEl(null);
  };
    return (
      <div className={classes.root}>
      <div id="AfterWalletConnected_two" onClick={() => AfterWalletConnected_two()}>&nbsp;</div>

<ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
        <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_WalletConnected = {Set_WalletConnected}
        Set_UserAccountAddr = {Set_UserAccountAddr}
        Set_UserAccountBal = {Set_UserAccountBal}
        Set_AddressUserDetails = {Set_AddressUserDetails}
        Set_Accounts = {Set_Accounts}
        Set_MyItemAccountAddr = {Set_MyItemAccountAddr}
        Set_tokenCounts = {Set_tokenCounts}
        Set_item = {Set_item}
        Set_tokenCounts_Detail = {Set_tokenCounts_Detail}
        Set_MyTokenBalance = {Set_MyTokenBalance}
        Set_Bids = {Set_Bids}
        Set_AccepBidSelect = {Set_AccepBidSelect}
        Set_tokenBidAmt = {Set_tokenBidAmt}
        Set_NoOfToken = {Set_NoOfToken}
        Set_ValidateError = {Set_ValidateError}
        Set_TokenBalance = {Set_TokenBalance}
        Set_YouWillPay = {Set_YouWillPay}
        Set_YouWillPayFee = {Set_YouWillPayFee}
        Set_YouWillGet = {Set_YouWillGet}
        Set_BidApply_ApproveCallStatus = {Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus = {Set_BidApply_SignCallStatus}
        WalletConnected = {WalletConnected}
        UserAccountAddr = {UserAccountAddr}
        UserAccountBal = {UserAccountBal}
        AddressUserDetails = {AddressUserDetails}
        Accounts = {Accounts}
        MyItemAccountAddr = {MyItemAccountAddr}
        tokenCounts = {tokenCounts}
        item = {item}
        tokenCounts_Detail = {tokenCounts_Detail}
        MyTokenBalance = {MyTokenBalance}
        Bids = {Bids}
        AccepBidSelect = {AccepBidSelect}
        tokenBidAmt = {tokenBidAmt}
        NoOfToken = {NoOfToken}
        ValidateError = {ValidateError}
        TokenBalance = {TokenBalance}
        YouWillPay = {YouWillPay}
        YouWillPayFee = {YouWillPayFee}
        YouWillGet = {YouWillGet}
        BidApply_ApproveCallStatus = {BidApply_ApproveCallStatus}
        BidApply_SignCallStatus = {BidApply_SignCallStatus}
        AllowedQuantity = {AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
      <BidPopup
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        Set_NoOfToken={Set_NoOfToken}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        AllowedQuantity={AllowedQuantity}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
       <BurnRef
        ref={BurnForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
      />
      <StakeRef
        ref={StakeForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Set_item={Set_item}
        Accounts={Accounts}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <ReportNowRef
        UserAccountAddr={UserAccountAddr}
        ref={ReportForwardRef}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />
        <ScrollToTopOnMount/>
        <CssBaseline />
        
        {/* Header APP */}
        <HeaderDashboard 
          classes={classes}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          handleMenu={handleMenu}
          handleClose={handleClose}
          anchorEl={anchorEl}
        />
        
       {/*  side Menu */}
        <SidebarLinksAfterlogin classes1={classes} open={open}/>
        <div className="overlay"></div>
        <main className={classes.content + " dashContent"}>
          <div className={classes.toolbar} />
          <div className="dashPages">  
            <div className="d-flex align-items-center mb-4 justify-content-between">
              <h2 className="inner_title">Stake Item</h2>
              <Link class="goback_link" to="/nft">
                <i class="bi bi-chevron-left"></i><span>Back</span>
              </Link>
            </div>    
            <GridContainer>              
              <GridItem xs={12} sm={12} md={12}>
               
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="owned" role="tabpanel" aria-labelledby="owned-tab">
                  <div className="proposal_panel_overall">
                    {(Owned_Count > 0 || Owned_List.length > 0) ?(
                    <div className="text-center py-5">
                      <div className="row following_row">          
                        {
                         Owned_List && Owned_List.length>0 && Owned_List.map((item) => {
                            return (
                              <div className="col-12 col-md-6 col-xl-3">
                                <div className="single_collectible">
                                   <TokenItem
                                    item={item}
                                    Set_item = {Set_item}
                                    Set_HitItem={Set_HitItem}
                                    LikedTokenList={LikedTokenList}
                                    hitLike={LikeForwardRef.current.hitLike}
                                    UserAccountAddr={UserAccountAddr}
                                    UserAccountBal={UserAccountBal}
                                    PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                    PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                    PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                    WalletConnected={WalletConnected}
                                    CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                    Burn_Click={BurnForwardRef.current.Burn_Click}
                                    Stake_Click={StakeForwardRef.current.Stake_Click}
                                    Set_Bids={Set_Bids}
                                    Bids = {Bids}
                                    Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                    Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                    Set_MyTokenBalance = {Set_MyTokenBalance}
                                    Set_MyTokenDetail={Set_MyTokenDetail}
                                    Set_AllowedQuantity={Set_AllowedQuantity}
                                    Set_YouWillPay = {Set_YouWillPay}
                                    Set_YouWillPayFee = {Set_YouWillPayFee}
                                    Set_YouWillGet = {Set_YouWillGet}
                                    from = "stake"
                                  /> 
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    ):(
                    "No Items Found"
                    )}
                  </div>
                </div>
              </div>
              </GridItem>             
            </GridContainer>
          </div>
        </main>
        
        <WalletModal/>    
   

      </div>
    );
}

NFT.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(NFT);
