import React, { useState, useEffect,useRef } from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withStyles } from "@material-ui/core/styles";
import { Link ,useHistory,useParams} from "react-router-dom";
import { Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import SidebarLinksAfterlogin from "components/Header/SidebarLinksAfterlogin.js";
import HeaderDashboard from "components/Header/HeaderDashboard";
import WalletModal from "components/WalletModal.js";
import Countdown, { zeroPad } from "react-countdown";
import Select from "react-select";

import $ from 'jquery';
import axios from 'axios';
import config from '../lib/config';

import {
    getWallet,
    halfAddrShow,
    getCurAddr,
    FollowChange_Action,
    changeReceiptStatus_Action,
    ParamAccountAddr_Detail_Get,
    User_FollowList_Get_Action,
    User_Following_List_Action,
    User_Followers_List_Action,
    User_Follow_Get_Action,
    Activity_List_Action,
    UserProfile_Update_Action
  } from '../actions/v1/User';
  
  import {
    CollectiblesList_MyItems,
    Bidding_Detail_Action,
    ReportRequest
  } from '../actions/v1/token';
  

import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { StakeRef } from './separate/stake';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import HeaderSearch from './separate/HeaderSearch'
import VALOBITDX from '../ABI/VALOBITDX.json';
import TokenItem from './separate/Token-Item';
import BidPopup from './separate/Bid-Popup';
import ConnectWallet from './separate/Connect-Wallet';

import ReactLoading from 'react-loading';

import Web3 from 'web3';
import '@metamask/legacy-web3'

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;
const drawerWidth = 240;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(0deg)"
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(180deg)"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 7.5 + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  grow: {
    flexGrow: 1
  }
});

const NFT =(props) => {
  const [open,setOpen] =useState(true)
   const [anchorEl,setAnchorEl] =useState(null)

   const history             = useHistory();
   const { ...rest }         = props;
   const [providerss,set_providers]=useState(null)
 
   var LikeForwardRef        = useRef();
   var PutOnSaleForwardRef   = useRef();
   var PurchaseNowForwardRef = useRef();
   var WalletForwardRef      = useRef();
   var ReportForwardRef      = useRef();
   var ShareForwardRef       = useRef();
   var PlaceABidForwardRef   = useRef();
  const StakeForwardRef      = useRef();
   var CancelOrderForwardRef = useRef();
   var BurnForwardRef        = useRef();
   var { paramUsername, paramAddress } = useParams();
   if(typeof paramUsername == 'undefined') { paramUsername = ''; }
   if(typeof paramAddress == 'undefined') { paramAddress = ''; }
 
   const [description,setdescription]  = React.useState("");
   const [ParamAccountCustomUrl, Set_ParamAccountCustomUrl] = useState(paramUsername);
   const [ParamAccountAddr, Set_ParamAccountAddr]           = useState(paramAddress);
   const [UserNotFound, Set_UserNotFound]                   = useState(false);
   const [FollowingUserList, Set_FollowingUserList]         = useState([]);
   const [WalletConnected, Set_WalletConnected]             = useState('false');
   const [UserAccountAddr, Set_UserAccountAddr]             = useState('');
   const [UserAccountBal, Set_UserAccountBal]               = useState(0);
   const [AddressUserDetails, Set_AddressUserDetails]       = useState({});
   const [Accounts, Set_Accounts]                           = useState('');
   const [MyItemAccountAddr, Set_MyItemAccountAddr]         = useState('');
   const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState('');
   const [HitItem, Set_HitItem]                             = useState({});
   const [LikedTokenList, setLikedTokenList]                = useState([]);
   const [OnSale_Count, Set_OnSale_Count]                   = useState(0);
   const [OnSale_List, Set_OnSale_List]                     = useState([]);
   const [Collectibles_Count, Set_Collectibles_Count]       = useState(0);
   const [Collectibles_List, Set_Collectibles_List]         = useState([]);
   const [Created_Count, Set_Created_Count]                 = useState(0);
   const [Created_List, Set_Created_List]                   = useState([]);
   const [Owned_Count, Set_Owned_Count]                     = useState(0);
   const [Owned_List, Set_Owned_List]                       = useState([]);
   const [Liked_Count, Set_Liked_Count]                     = useState(0);
   const [Liked_List, Set_Liked_List]                       = useState([]);
 
   //by muthu
   const [Bids, Set_Bids] = useState([]);
   const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
   const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
   const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
   const [MyTokenDetail, Set_MyTokenDetail] = useState({});
   const [AllowedQuantity, Set_AllowedQuantity] = useState(0);
   const [YouWillPay, Set_YouWillPay] = useState(0);
   const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
   const [YouWillGet, Set_YouWillGet] = useState(0);
   const [item, Set_item] = useState({}); 
   const [tokenCounts, Set_tokenCounts] = useState(0);
   const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
   const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
   const [NoOfToken, Set_NoOfToken] = useState(1);
   const [ValidateError, Set_ValidateError] = useState({});
   const [TokenBalance, Set_TokenBalance] = useState(0);
   const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
   const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
 
   const [followingCount, Set_followingCount] = useState(0);
   const [followersCount, Set_followersCount] = useState(0);
   const [followingStatus, Set_followingStatus] = useState(true);
   const [loadMoreBtn, Set_loadMoreBtn] = useState(true);
   const [followersPage, Set_followersPage] = useState(1);
   const [followingPage, Set_followingPage] = useState(1);
   const [followersStatus, Set_followersStatus] = useState(true);
   const [FollowerUserList, Set_FollowerUserList] = React.useState([]);
   const [loadMoreBtn1, Set_loadMoreBtn1] = useState(true);
   const [is_follow, Set_is_follow] = useState("no");
   const [ActivityList, Set_ActivityList] = React.useState([]);
   const [Activitycount, Set_Activitycount] = React.useState(0);
   const [CurTabName, Set_CurTabName] = React.useState('onsale');
 
   const [TokenFile, setTokenFile]                 = useState("");
   const [TokenFilePreReader, setTokenFilePreReader] = useState("");
   const [TokenFilePreUrl, setTokenFilePreUrl]     = useState("");
   const [coverimage, setCoverimage]               = useState("");
   const [MyItemcoverimage,Set_MyItemcoverimage]   = useState("");
   const [totaluserbidAmt,setTotaluserbidAmt]      = useState(0);
   const [walletaddr, setWalletaddr]               = useState("");
   const [ShareUrl, setShareUrl] = React.useState("");
   const [Show, setShow] = React.useState("");
     
   async function CorrectDataGet(Resp,Target) {
    var RetData = { count : 0, list : [] }
    if (
      Resp
      && Resp.data
      && Resp.data.Target
      && Resp.data.list
      && Resp.data.list[0]
    ) {
      if(Resp.data.Target == 'Count' && Resp.data.list[0].count) {
        RetData.count = Resp.data.list[0].count;
      }
      else if(Resp.data.Target == 'List' && Resp.data.list[0]) {
        RetData.list = Resp.data.list;
      }
    }
    if(
      Resp
      && Resp.data
      && Resp.data.Target
      && Resp.data.changeStatusList) {
        changeReceiptStatus_Call(Resp.data.changeStatusList);
    }
    return RetData;
  }
  async function changeReceiptStatus_Call(list) {
    var web3 = new Web3(window.ethereum);
    list.map(async (item) => {
      if (item && typeof item.checkAdd != "undefined" && item.checkAdd.hashValue) {
        try {
          var data = await web3.eth.getTransactionReceipt(item.checkAdd.hashValue);
          var hashValue = item.checkAdd.hashValue;
          if (data == null) {
          } else {
            if(data.status == '0x0') {
            } else {
              var payload = {
                status: 'true',
                hashValue: hashValue
              }
              await changeReceiptStatus_Action(payload);
            }
          }
        }
        catch(err) {
          // console.log('err', err);
        }
      }
    })
  }

   async function Tab_Click(TabName) {
     Set_CurTabName(TabName);
     await Tab_Data_Call('List',TabName);
     await Tab_Data_Call('Count',TabName);
   }
   async function Tab_Data_Call(Target, TabName, init=false) {
     console.log("neeeeeeeeeeeemyitems",MyItemAccountAddr)
 
     if(MyItemAccountAddr ) {
       var ReqData = {
         Addr:MyItemAccountAddr,
         MyItemAccountAddr:MyItemAccountAddr,
         ParamAccountAddr:ParamAccountAddr,
         UserAccountAddr:UserAccountAddr,
         Target:Target,
         TabName:TabName,
         init:init,
         from:'My-Items'
       };
 console.log("neeeeeeeeeeeemyitems",ReqData)
       var Resp = {};
       Resp = await CollectiblesList_MyItems(ReqData);
       var RespNew = await CorrectDataGet(Resp);
       console.log(RespNew,Target,"============================neeeeeeeeeeee")
       if(
         (Target=='Count' && typeof RespNew.count != 'undefined')
         ||
         (Target=='List' && RespNew.list)
       ) {
         if(TabName=='collectibles') {
           if(Target=='Count') { Set_Collectibles_Count(RespNew.count); }
           if(Target == 'List') { Set_Collectibles_List(RespNew.list); }
         }
         else if(TabName=='onsale') {
           if(Target=='Count') { Set_OnSale_Count(RespNew.count); }
           if(Target == 'List') { Set_OnSale_List(RespNew.list); }
         }
         else if(TabName=='created') {
           console.log("RespNew.list",RespNew.list)
           if(Target=='Count') { Set_Created_Count(RespNew.count); }
           if(Target == 'List') { Set_Created_List(RespNew.list); }
         }
        else if(TabName=='owned') {
           if(Target=='Count') { Set_Owned_Count(RespNew.count); }
           if(Target == 'List') { Set_Owned_List(RespNew.list); }
           console.log(RespNew.list,"==============owned")
         }
         else if(TabName=='liked') {
           if(Target=='Count') { Set_Liked_Count(RespNew.count); }
           if(Target == 'List') { Set_Liked_List(RespNew.list); }
         }
       }
     }
     return true;
   }
 
   async function FollowingTab(address, pos, isFollow) {
     var currAddr = await getCurAddr();
     var web3 = new Web3(window.ethereum);
     var Payload = {};
     Payload.currAddr = currAddr;
     Payload.ParamAccountAddr = address;
 
     var msg = "I would like to follow user: "+address
     if(isFollow=="yes"){
       msg = "I would like to stop following user: "+address
     }
 
     await  web3.eth.personal.sign(msg, currAddr)
     var Resp = await FollowChange_Action(Payload);
     var foll = (Resp && Resp.data && Resp.data.ChangeType)?Resp.data.ChangeType:"no";
 
     if((ParamAccountAddr !="" && currAddr !="" && currAddr==ParamAccountAddr) || (ParamAccountAddr=="" || !ParamAccountAddr)){
       //same user
       FollowingUserList.splice(pos, 1);
       Set_followingStatus(false);
       Set_FollowingUserList(FollowingUserList);
       Set_followingStatus(true);
 
       var followingcnt = (Resp && Resp.data && Resp.data.following)?Resp.data.following:"0";
       var followerscnt = (Resp && Resp.data && Resp.data.followers)?Resp.data.followers:"0";
       Set_followingCount(followingcnt);
       Set_followersCount(followerscnt);
     } else {
       //another user
       FollowingUserList[pos].isFollow = foll;
       Set_followingStatus(false);
       Set_FollowingUserList(FollowingUserList);
       Set_followingStatus(true);
     }
     if(foll=="yes"){
       toast.success("Follow successfully", toasterOption);
     }else{
       toast.success("Un-Follow successfully", toasterOption);
     }
     
   }
   async function FollowChange_Call() {
 
     var web3 = new Web3(window.ethereum);
     var currAddr = await getCurAddr();
     console.log("follow_data:",currAddr,ParamAccountAddr)
     var Payload = {};
     Payload.currAddr = currAddr;
     Payload.ParamAccountAddr = ParamAccountAddr;
 
     var msg = "I would like to follow user: "+ParamAccountAddr
     if(is_follow=="yes"){
       msg = "I would like to stop following user: "+ParamAccountAddr
     }
 
     await  web3.eth.personal.sign(msg, currAddr)
     var Resp = await FollowChange_Action(Payload);
 
     var foll = (Resp && Resp.data && Resp.data.ChangeType)?Resp.data.ChangeType:"no";
     Set_is_follow(foll);
 
     if(foll=="yes"){
       toast.success("Follow successfully", toasterOption);
     } else {
       toast.success("Un-Follow successfully", toasterOption);
     }
     Check_follow();
     if(CurTabName == 'followers') {
       Get_Followers_List();
     }
     else if(CurTabName == 'following') {
       Get_Following_List();
     }
 
     //User_FollowList_Get_Call();
   }
 
 
   useEffect(() => {
     // console.log('useEffect');
   }, []);
 
   const Get_MyItemAccountAddr_Details = async (payload) => {
     var Resp = await ParamAccountAddr_Detail_Get(payload);
     if(Resp.data&&Resp.data.User&&Resp.data.User.coverimage){
       Set_MyItemcoverimage(Resp.data.User.coverimage);
     }
     
     if(
       Resp
       && Resp.data
       && Resp.data.User
       && Resp.data.User.curraddress
     ) {
       Set_MyItemAccountAddr(Resp.data.User.curraddress);
       console.log("###########",Resp.data.User.curraddress)
       if(Resp.data.User) {
         Set_MyItemAccountAddr_Details(Resp.data.User);
       }
     }
     else {
       toast.warning('User not found', toasterOption);
       Set_UserNotFound(true);
     }
   }
   async function BiddingAmout(){
     try{
       var currAddr = window.web3.eth.defaultAccount;
       var payload={currAddr}
       var Resp = await Bidding_Detail_Action(payload);
       if(Resp&&Resp.data){
         setTotaluserbidAmt(Resp.data.res1)
       }
     }catch(err){
     }
   }
 
   async function  getwalletlist(){
     try{
       var currAddr = window.web3.eth.defaultAccount;
       var payload={
         address:currAddr
       }
       var {result} = await getWallet(payload)
       if(result&&result.data){
         setWalletaddr(result.data.address)
       }
     }catch(err){}
   }
   const AfterWalletConnected = async () => {
     var currAddr = await getCurAddr();
     console.log("wallet_list",currAddr)
     BiddingAmout();
     getwalletlist()
     if(typeof currAddr != 'undefined'){
       if(ParamAccountAddr || ParamAccountCustomUrl) {
         if(ParamAccountAddr && ParamAccountAddr.toString() === currAddr.toString()) {
           console.log("parmadskldl",ParamAccountAddr,currAddr)
           Set_MyItemAccountAddr(ParamAccountAddr);
           Get_MyItemAccountAddr_Details({addr:currAddr});
         }
         else {
           var payload = {}
           if(ParamAccountAddr) {
             payload.addr = ParamAccountAddr;
           }
           else if(ParamAccountCustomUrl){
             payload.customurl = ParamAccountCustomUrl;
           }
           await Get_MyItemAccountAddr_Details(payload);
         }
       }
       else {
         console.log("@@@@@@@@@",currAddr)
         Set_MyItemAccountAddr(currAddr);
         Get_MyItemAccountAddr_Details({addr:currAddr});
       }
 
       window.$('#AfterWalletConnected_two').click();
       // After set state value not getting so above click function i used.
       // After this onclick state value getting purfectly (By Raj)
     }
    
    
   }
 
   async function AfterWalletConnected_two() {
     // User_FollowList_Get_Call();
     Check_follow();
     Get_Activity_list();
     try{
       LikeForwardRef.current.getLikesData();
     }catch(err){
 
     }
   
     await Tab_Data_Call('Count','onsale',true);
     await Tab_Data_Call('List','onsale');
     await Tab_Data_Call('Count','created');
     await Tab_Data_Call('Count','owned');
     await Tab_Data_Call('Count','liked');
   }
 
   async function User_FollowList_Get_Call() {
     var resp = await User_FollowList_Get_Action({
       target:'following',
       addr:MyItemAccountAddr,
       follower:MyItemAccountAddr
     });
     if(
       resp
       && resp.data
       && resp.data.list
     ) {
       var MyFollowingList = resp.data.list;
       Set_FollowingUserList(MyFollowingList);
       var FollowIndex = MyFollowingList.findIndex(e => e.follower == MyItemAccountAddr);
     }
     else {
       Set_FollowingUserList([]);
     }
   }
   async function GetUserBal() {
     await WalletForwardRef.current.GetUserBal();
   }
 
   const loadFollowing = async (e) => {
 
     var currAddr = await getCurAddr();
     var resp = await User_Following_List_Action({
       addr:MyItemAccountAddr,
       loginUser:currAddr,
       page: followingPage
     });
     
     if(
       resp
       && resp.data
       && resp.data.list
     ) {
       var List = resp.data.list;
       if(List && List.length > 0){
         Set_followingStatus(false);
         var totalList = FollowingUserList.concat(List);
         Set_FollowingUserList(totalList);
         Set_followingStatus(true);
       }else{
         Set_loadMoreBtn(false);
       }
       
     }
   }
   async function Check_follow(){
     var data = {
       useraddress:UserAccountAddr,
       followaddress:MyItemAccountAddr,
     }
     var resp = await User_Follow_Get_Action(data);
     var foll = (resp && resp.data && resp.data.isFollow)?resp.data.isFollow:"no";
     var followingcnt = (resp && resp.data && resp.data.following)?resp.data.following:"0";
     var followerscnt = (resp && resp.data && resp.data.followers)?resp.data.followers:"0";
     Set_is_follow(foll);
     Set_followingCount(followingcnt);
     Set_followersCount(followerscnt);
   }

   async function copyToClipboard(e) {
    var textField = document.createElement('textarea')
    textField.innerText = MyItemAccountAddr;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toast.success("Copied Successfully", toasterOption);
  }
 
   async function FollowerTab(address, pos, isFollow) {
 
     var web3 = new Web3(window.ethereum);
     var currAddr = await getCurAddr();
     var currentUser ="no";
     if((ParamAccountAddr !="" && currAddr !="" && currAddr==ParamAccountAddr) || (ParamAccountAddr=="" || !ParamAccountAddr)){
       currentUser ="yes";
     }
     var msg = "I would like to follow user: "+address
     if(isFollow=="yes"){
       msg = "I would like to stop following user: "+address
     }
 
     var Payload = {};
     Payload.currAddr = currAddr;
     Payload.ParamAccountAddr = address;
     Payload.currentUser = currentUser;
     await  web3.eth.personal.sign(msg, currAddr)
     var Resp = await FollowChange_Action(Payload);
 
     var foll = (Resp && Resp.data && Resp.data.ChangeType)?Resp.data.ChangeType:"no";
     FollowerUserList[pos].isFollow = foll;
     Set_followersStatus(false);
     Set_FollowerUserList(FollowerUserList);
     Set_followersStatus(true);
 
     if(currentUser=="yes"){
       var followingcnt = (Resp && Resp.data && Resp.data.following)?Resp.data.following:"0";
       var followerscnt = (Resp && Resp.data && Resp.data.followers)?Resp.data.followers:"0";
       Set_followingCount(followingcnt);
       Set_followersCount(followerscnt);
     }
     if(foll=="yes"){
       toast.success("Follow successfully", toasterOption);
     }
     else{
       toast.success("Un-Follow successfully", toasterOption);
     }
     Check_follow();
   }
 
   const loadFollowers = async (e) => {
     var currAddr = await getCurAddr();
     var resp = await User_Followers_List_Action({
       addr:MyItemAccountAddr,
       loginUser:currAddr,
       page: followersPage
     });
     
     if(
       resp
       && resp.data
       && resp.data.list
     ) {
       var MyFollowerList = resp.data.list;
       if(MyFollowerList && MyFollowerList.length > 0){
         Set_followersStatus(false);
         var totalList = FollowerUserList.concat(MyFollowerList);
         Set_FollowerUserList(totalList);
         Set_followersStatus(true);
       }else{
         Set_loadMoreBtn1(false);
       }
     }
   }
 
   async function Get_Followers_List() {
     Set_CurTabName('followers');
     var currAddr = await getCurAddr();
     var resp = await User_Followers_List_Action({
       addr:MyItemAccountAddr,
       loginUser:currAddr
     });
     
     if(
       resp
       && resp.data
       && resp.data.list
     ) {
       var MyFollowingList = resp.data.list;
       Set_FollowerUserList(MyFollowingList);
     }
     else {
       Set_FollowerUserList([]);
     }
   }
 
   async function Get_Following_List() {
     Set_CurTabName('following');
     var currAddr = await getCurAddr();
     var resp = await User_Following_List_Action({
       addr:MyItemAccountAddr,
       loginUser:currAddr
     });
     
     if(
       resp
       && resp.data
       && resp.data.list
     ) {
       var MyFollowingList = resp.data.list;
       Set_FollowingUserList(MyFollowingList);
     }
     else {
       Set_FollowingUserList([]);
     }
   }
   async function Get_Activity_list(){
     Set_CurTabName('activity');
     var resp = await Activity_List_Action({
       addr:MyItemAccountAddr
     });
    
     if(
       resp
       && resp.data
       && resp.data.list
     ) {
       var List = resp.data.list;
       if (List.length>0) {
         Set_Activitycount(List.length)
       }
       Set_ActivityList(List);
     }
     else {
       Set_ActivityList([]);
     }
 
   }
   const inputChange = (e) => {
    if(e && e.target && typeof e.target.value != 'undefined') {
        var value = e.target.value;
        setdescription(value);
        if(e.target.value !=""){
            Set_ValidateError({});
        }
    }
  }
   async function onprofileshare(){
     var url = config.Front_URL
     setShareUrl(url);
     window.$('#share_modal').modal('show');
     setShow(true)
   }
   const ReportValidation = async (data={}) => {
    var ValidateError = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    if(description == '' || typeof description=="undefined") {
        ValidateError.description = '"Description" is not allowed to be empty';
    }
    Set_ValidateError(ValidateError);
    return ValidateError;
}
   async function submituserreport(){
    var errors = await ReportValidation();
    console.log("######",errors)
    var errorsSize = Object.keys(errors).length;
    if(errorsSize != 0) {
        toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
        return false;
    }
    var currAddr = await getCurAddr();
    var reqData ={
            reportuser:currAddr,
            description:description,
            type:"profile",
            spanuser:(MyItemAccountAddr != UserAccountAddr) && UserAccountAddr && UserAccountAddr!="" && UserAccountAddr!=undefined && UserAccountAddr!=null? MyItemAccountAddr:UserAccountAddr,
        }
        var response = await ReportRequest(reqData);
        console.log(response,"==============================")
        if(response && response.status=="true"){
            toast.success("Successfully submit your report", toasterOption);
            setdescription("");
            setTimeout(function(){
                window.$('#report_page_modal').modal('hide');
            },100);
            setTimeout(function(){
            window.location.reload(false);
          },400);
        }else{
            toast.error('Oops something went wrong.!', toasterOption);
        }
  }


    // Countdown Timer
    const currentDate = new Date();
    const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

    const renderer = ({ days, hours, minutes, seconds }) => {
      return (
        <div className="timer_panel">
          <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">d</span></span>
          <span className="timer_dots"> </span>
          <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">h</span></span>
          <span className="timer_dots"> </span>
          <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">m</span></span>
          <span className="timer_dots"> </span>
          <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">s</span></span>
        </div>
      );
    };
    const { classes, theme } = props;


  const handleDrawerOpen = () => {
    console.log("data---------------")
    setOpen(!open)
  };
  const handleMenu = event => {
    console.log("----event",event)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
setAnchorEl(null);
  };
    return (
      <div className={classes.root}>
      <div id="AfterWalletConnected_two" onClick={() => AfterWalletConnected_two()}>&nbsp;</div>

<ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
        <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_WalletConnected = {Set_WalletConnected}
        Set_UserAccountAddr = {Set_UserAccountAddr}
        Set_UserAccountBal = {Set_UserAccountBal}
        Set_AddressUserDetails = {Set_AddressUserDetails}
        Set_Accounts = {Set_Accounts}
        Set_MyItemAccountAddr = {Set_MyItemAccountAddr}
        Set_tokenCounts = {Set_tokenCounts}
        Set_item = {Set_item}
        Set_tokenCounts_Detail = {Set_tokenCounts_Detail}
        Set_MyTokenBalance = {Set_MyTokenBalance}
        Set_Bids = {Set_Bids}
        Set_AccepBidSelect = {Set_AccepBidSelect}
        Set_tokenBidAmt = {Set_tokenBidAmt}
        Set_NoOfToken = {Set_NoOfToken}
        Set_ValidateError = {Set_ValidateError}
        Set_TokenBalance = {Set_TokenBalance}
        Set_YouWillPay = {Set_YouWillPay}
        Set_YouWillPayFee = {Set_YouWillPayFee}
        Set_YouWillGet = {Set_YouWillGet}
        Set_BidApply_ApproveCallStatus = {Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus = {Set_BidApply_SignCallStatus}
        WalletConnected = {WalletConnected}
        UserAccountAddr = {UserAccountAddr}
        UserAccountBal = {UserAccountBal}
        AddressUserDetails = {AddressUserDetails}
        Accounts = {Accounts}
        MyItemAccountAddr = {MyItemAccountAddr}
        tokenCounts = {tokenCounts}
        item = {item}
        tokenCounts_Detail = {tokenCounts_Detail}
        MyTokenBalance = {MyTokenBalance}
        Bids = {Bids}
        AccepBidSelect = {AccepBidSelect}
        tokenBidAmt = {tokenBidAmt}
        NoOfToken = {NoOfToken}
        ValidateError = {ValidateError}
        TokenBalance = {TokenBalance}
        YouWillPay = {YouWillPay}
        YouWillPayFee = {YouWillPayFee}
        YouWillGet = {YouWillGet}
        BidApply_ApproveCallStatus = {BidApply_ApproveCallStatus}
        BidApply_SignCallStatus = {BidApply_SignCallStatus}
        AllowedQuantity = {AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
      <BidPopup
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        Set_NoOfToken={Set_NoOfToken}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        AllowedQuantity={AllowedQuantity}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
       <BurnRef
        ref={BurnForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
      />
      <StakeRef
        ref={StakeForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <ReportNowRef
        UserAccountAddr={UserAccountAddr}
        ref={ReportForwardRef}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />
        <ScrollToTopOnMount/>
        <CssBaseline />
        
        {/* Header APP */}
        <HeaderDashboard 
          classes={classes}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          handleMenu={handleMenu}
          handleClose={handleClose}
          anchorEl={anchorEl}
        />
        
       {/*  side Menu */}
        <SidebarLinksAfterlogin classes1={classes} open={open}/>
        <div className="overlay"></div>
        <main className={classes.content + " dashContent"}>
          <div className={classes.toolbar} />
          <div className="dashPages">  
            <div className="d-flex align-items-center mb-4 justify-content-between">
              <h2 className="inner_title">My Items</h2>
              <Link class="goback_link" to="/nft">
                <i class="bi bi-chevron-left"></i><span>Back</span>
              </Link>
            </div>    
            <GridContainer>              
              <GridItem xs={12} sm={12} md={12}>
              <div>
                <GridContainer className="mx-0">
                  <GridItem xs={12} sm={12} md={12} className="px-0">
                    <div className="items_bg_pink">
                      <div className="item_prof cursorPointer">
                        {
                          MyItemAccountAddr_Details && MyItemAccountAddr_Details.image && MyItemAccountAddr_Details.image != ''
                            ?
                            <img src={config.Back_URL + '/profile/' + MyItemAccountAddr_Details.image} alt="logo" id="imgPreview" className="img-fluid" width="100" />
                            :
                            <img src={require("../assets/images/profilepic.jpg")} alt="profile" id="imgPreview" className="img-fluid items_profile" />
                        }
                        {
                          (MyItemAccountAddr != '' && MyItemAccountAddr != UserAccountAddr) ? '' :
                            <Link to="/edit-profile">
                              <div className="profile_edit_panel item_prof_panel">
                                <div className="profile_edit_icon">
                                  <i class="fas fa-pencil-alt"></i>
                                </div>
                                {/* <input type="file" name="photograph" id="photo" required="true" /> */}
                              </div>
                            </Link>
                        }
                      </div>

                      {(MyItemAccountAddr_Details && (MyItemAccountAddr_Details.name!="")) &&
                       <p><span className="address_text">{MyItemAccountAddr_Details.name}</span></p>
                      }
                      {(MyItemAccountAddr_Details && (MyItemAccountAddr_Details.customurl!="")) &&
                       <p><span className="address_text">@{MyItemAccountAddr_Details.customurl}</span></p>
                      }
                      <div className="crd_link_">
                        {(MyItemAccountAddr_Details && (MyItemAccountAddr_Details.instagram!="")) && 
                        <a href={MyItemAccountAddr_Details.instagram} target="_blank">
                          <span className="address_text"><i className="fab fa-instagram notes_fa mr-2"></i></span>
                        </a>
                        }
                        {(MyItemAccountAddr_Details && (MyItemAccountAddr_Details.twitter!="")) && 
                        <a href={MyItemAccountAddr_Details.twitter} target="_blank">
                          <span className="address_text"><i className="fab fa-twitter notes_fa mr-2"></i></span>
                          </a>
                        }
                        {(MyItemAccountAddr_Details && (MyItemAccountAddr_Details.facebook!="")) && 
                        <a href={MyItemAccountAddr_Details.facebook} target="_blank">
                          <span className="address_text"><i className="fab fa-facebook-f notes_fa mr-2"></i></span>
                          </a>
                        }
                        {(MyItemAccountAddr_Details && MyItemAccountAddr_Details.youtube!="") && 
                        <a href={MyItemAccountAddr_Details.youtube} target="_blank">
                          <span className="address_text"><i className="fab fa-youtube  notes_fa mr-2"></i></span>
                        </a>
                        }
                      </div>
                      <p>
                        <span className="address_text">{halfAddrShow(MyItemAccountAddr)}</span>
                        <span>
                          <i className="fas fa-sticky-note notes_fa" onClick={copyToClipboard}></i>
                        </span>
                      </p>
                      <div className="mt-3 d-flex align-items-center">
                        {
                        MyItemAccountAddr != '' &&(UserAccountAddr==MyItemAccountAddr)&&
                        <Link className="btn_outline_red" to="/edit-profile">Edit Profile</Link>
                    }
                    {
                        MyItemAccountAddr != '' &&(UserAccountAddr==MyItemAccountAddr)&&
                        <div class="dropdown">
                          <button class="bg_red_icon" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-share-alt"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" data-toggle="modal" onClick={()=>onprofileshare()} data-target="#share_modal">Share</a>
                          </div>
                        </div>
}
{
                        MyItemAccountAddr != '' &&(UserAccountAddr==MyItemAccountAddr)&&
                        <div class="dropdown">
                          <button class="bg_red_icon" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-h"></i>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_page_modal">Report Page</a>
                          </div>
                        </div>
}
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
              {/* <div class="d-flex align-items-center">
                  <Link class="goback_link" to="/nft">
                    <i class="bi bi-chevron-left"></i><span>Back</span>
                  </Link>
                </div> */}
                
                <nav className="masonry_tab_nav mt-4 items_tab_outer">
                <div className="nav nav-tabs masonry_tab primary_tab items_tab" id="nav-tab" role="tablist">
                  <a className="nav-link active" id="onsale-tab" data-toggle="tab" href="#onsale" role="tab" aria-controls="onsale" aria-selected="true" onClick={() => Tab_Click('onsale')}>
                    <div className="tab_head">OnSale</div>
                    <div className="tab_count">{OnSale_Count}</div>
                  </a>
                  <a className="nav-link" id="created-tab" data-toggle="tab" href="#created" role="tab" aria-controls="created" aria-selected="false" onClick={() => Tab_Click('created')}>
                    <div className="tab_head">Created</div>
                    <div className="tab_count">{Created_Count}</div>
                  </a>
                  <a className="nav-link" id="owned-tab" data-toggle="tab" href="#owned" role="tab" aria-controls="owned" aria-selected="false" onClick={() => Tab_Click('owned')}>
                    <div className="tab_head">Owned</div>
                    <div className="tab_count">{Owned_Count}</div>
                  </a>
                  <a className="nav-link" id="liked-tab" data-toggle="tab" href="#liked" role="tab" aria-controls="liked" aria-selected="false" onClick={() => Tab_Click('liked')}>
                    <div className="tab_head">Liked</div>
                    <div className="tab_count">{Liked_Count}</div>
                  </a>
                  {/* <a className="nav-link" id="activity-tab" data-toggle="tab" href="#activity" role="tab" aria-controls="activity" aria-selected="false" >
                    <div className="tab_head">Activity</div>
                    <div className="tab_count">{0}</div>
                  </a>
                  <a className="nav-link" id="following-tab" data-toggle="tab" href="#following" role="tab" aria-controls="following" aria-selected="false" >
                    <div className="tab_head">Following</div>
                    <div className="tab_count">{0}</div>
                  </a>
                  <a className="nav-link" id="followers-tab" data-toggle="tab" href="#followers" role="tab" aria-controls="followers" aria-selected="false" >
                    <div className="tab_head">Followers</div>
                    <div className="tab_count">{0}</div>
                  </a> */}
                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="onsale" role="tabpanel" aria-labelledby="onsale-tab">
                  <div className="proposal_panel_overall">
                    {(OnSale_Count > 0 || OnSale_List.length > 0)?(
                    <div className="text-center py-5">
                      <div className="row following_row">
                        {
                          OnSale_List.map((item) => {
                            return (
                              <div className="col-12 col-md-6 col-xl-3">
                                <div className="single_collectible">
                                <TokenItem
                                      item={item}
                                      Set_item={Set_item}
                                      LikedTokenList={LikedTokenList}
                                      hitLike={LikeForwardRef.current.hitLike}
                                      UserAccountAddr={UserAccountAddr}
                                      UserAccountBal={UserAccountBal}
                                      PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                      PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                      PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                      Set_Bids={Set_Bids}
                                      Bids = {Bids}
                                      Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                      Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                      Set_MyTokenBalance = {Set_MyTokenBalance}
                                      Set_MyTokenDetail={Set_MyTokenDetail}
                                      Set_AllowedQuantity={Set_AllowedQuantity}
                                      Set_YouWillPay = {Set_YouWillPay}
                                      Set_YouWillPayFee = {Set_YouWillPayFee}
                                      Set_YouWillGet = {Set_YouWillGet}
                                      Burn_Click={BurnForwardRef.current.Burn_Click}
                                      Stake_Click={StakeForwardRef.current.Stake_Click}
                                      CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                      WalletConnected={WalletConnected}
                                      SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                      ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                      from = "item"
                                   />
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    ):(
                    <div className="text-center py-5">
                      <p className="not_found_text">No items found</p>
                      <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                      <div className="mt-3">
                        <Button className="green_btn"><Link to="/nft">Browse Marketplace</Link></Button>
                      </div>
                    </div>
                    )}
                  </div>
                </div>
                <div className="tab-pane fade" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                  <div className="proposal_panel_overall">
                    {(Collectibles_Count > 0 || Collectibles_List.length > 0) ?(
                    <div className="text-center py-5">
                      <div className="row following_row">          
                        {
                          Collectibles_List.map((item) => {
                            return (
                              <div className="col-12 col-md-6 col-xl-3">
                                <div className="single_collectible">
                                  <TokenItem
                                    item={item}
                                    Set_item = {Set_item}
                                    LikedTokenList={LikedTokenList}
                                    hitLike={LikeForwardRef.current.hitLike}
                                    UserAccountAddr={UserAccountAddr}
                                    UserAccountBal={UserAccountBal}
                                    PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                    PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                    PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                    WalletConnected={WalletConnected}
                                    SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                    ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                    CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                    Set_Bids={Set_Bids}
                                    Bids = {Bids}
                                    Burn_Click={BurnForwardRef.current.Burn_Click}
                                    Stake_Click={StakeForwardRef.current.Stake_Click}
                                    Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                    Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                    Set_MyTokenBalance = {Set_MyTokenBalance}
                                    Set_MyTokenDetail={Set_MyTokenDetail}
                                    Set_AllowedQuantity={Set_AllowedQuantity}
                                    Set_YouWillPay = {Set_YouWillPay}
                                    Set_YouWillPayFee = {Set_YouWillPayFee}
                                    Set_YouWillGet = {Set_YouWillGet}
                                    from = "item"
                                  />
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    ):(
                    <div className="text-center py-5">
                      <p className="not_found_text">No items found</p>
                      <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                      <div className="mt-3">
                        <Button className="green_btn"><Link to="/nft">Browse Marketplace</Link></Button>
                      </div>
                    </div>
                    )}
                  </div>
                </div>
                <div className="tab-pane fade" id="created" role="tabpanel" aria-labelledby="created-tab">
                  <div className="proposal_panel_overall">
                    {(Created_Count > 0 || Created_List.length > 0) ?(
                    <div className="text-center py-5">
                      <div className="row following_row">          
                        {
                          Created_List.map((item) => {
                            console.log("Created_List",item)
                            return (
                              <div className="col-12 col-md-6 col-xl-3">
                                <div className="single_collectible">
                                  <TokenItem
                                    item={item}
                                    Set_item = {Set_item}
                                    LikedTokenList={LikedTokenList}
                                    hitLike={LikeForwardRef.current.hitLike}
                                    UserAccountAddr={UserAccountAddr}
                                    UserAccountBal={UserAccountBal}
                                    PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                    PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                    PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                    WalletConnected={WalletConnected}
                                    SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                    ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                    CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                    Burn_Click={BurnForwardRef.current.Burn_Click}
                                    Stake_Click={StakeForwardRef.current.Stake_Click}
                                    Set_Bids={Set_Bids}
                                    Bids = {Bids}
                                    Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                    Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                    Set_MyTokenBalance = {Set_MyTokenBalance}
                                    Set_MyTokenDetail={Set_MyTokenDetail}
                                    Set_AllowedQuantity={Set_AllowedQuantity}
                                    Set_YouWillPay = {Set_YouWillPay}
                                    Set_YouWillPayFee = {Set_YouWillPayFee}
                                    Set_YouWillGet = {Set_YouWillGet}
                                    from = "item"
                                  />
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    ):(
                    <div className="text-center py-5">
                      <p className="not_found_text">No items found</p>
                      <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                      <div className="mt-3">
                        <Button className="green_btn"><Link to="/nft">Browse Marketplace</Link></Button>
                      </div>
                    </div>
                    )}
                  </div>
                </div>
                <div className="tab-pane fade" id="owned" role="tabpanel" aria-labelledby="owned-tab">
                  <div className="proposal_panel_overall">
                    {(Owned_Count > 0 || Owned_List.length > 0) ?(
                    <div className="text-center py-5">
                      <div className="row following_row">          
                        {
                          Owned_List.map((item) => {
                            return (
                              <div className="col-12 col-md-6 col-xl-3">
                                <div className="single_collectible">
                                  <TokenItem
                                    item={item}
                                    Set_item = {Set_item}
                                    Set_HitItem={Set_HitItem}
                                    LikedTokenList={LikedTokenList}
                                    hitLike={LikeForwardRef.current.hitLike}
                                    UserAccountAddr={UserAccountAddr}
                                    UserAccountBal={UserAccountBal}
                                    PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                    PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                    PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                    WalletConnected={WalletConnected}
                                    CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                    Burn_Click={BurnForwardRef.current.Burn_Click}
                                    Stake_Click={StakeForwardRef.current.Stake_Click}
                                    Set_Bids={Set_Bids}
                                    Bids = {Bids}
                                    Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                    Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                    Set_MyTokenBalance = {Set_MyTokenBalance}
                                    Set_MyTokenDetail={Set_MyTokenDetail}
                                    Set_AllowedQuantity={Set_AllowedQuantity}
                                    Set_YouWillPay = {Set_YouWillPay}
                                    Set_YouWillPayFee = {Set_YouWillPayFee}
                                    Set_YouWillGet = {Set_YouWillGet}
                                    from = "item"
                                  />
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    ):(
                    <div className="text-center py-5">
                      <p className="not_found_text">No items found</p>
                      <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                      <div className="mt-3">
                        <Button className="green_btn"><Link to="/nft">Browse Marketplace</Link></Button>
                      </div>
                    </div>
                    )}
                  </div>
                </div>

                <div className="tab-pane fade" id="liked" role="tabpanel" aria-labelledby="liked-tab">
                <div className="proposal_panel_overall">
                    {(Liked_Count > 0 || Liked_List.length > 0) ?(
                    <div className="text-center py-5">
                      <div className="row following_row">          
                        {
                          Liked_List.map((item) => {
                            return (
                              <div className="col-12 col-md-6 col-xl-3">
                                <div className="single_collectible">
                                   <TokenItem
                                      item={item}
                                      Set_item={Set_item}
                                      LikedTokenList={LikedTokenList}
                                      hitLike={LikeForwardRef.current.hitLike}
                                      UserAccountAddr={UserAccountAddr}
                                      UserAccountBal={UserAccountBal}
                                      PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                      PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                      PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                      Set_Bids={Set_Bids}
                                      Bids = {Bids}
                                      Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                      Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                      Set_MyTokenBalance = {Set_MyTokenBalance}
                                      Set_MyTokenDetail={Set_MyTokenDetail}
                                      Set_AllowedQuantity={Set_AllowedQuantity}
                                      Set_YouWillPay = {Set_YouWillPay}
                                      Set_YouWillPayFee = {Set_YouWillPayFee}
                                      Set_YouWillGet = {Set_YouWillGet}
                                      Burn_Click={BurnForwardRef.current.Burn_Click}
                                      Stake_Click={StakeForwardRef.current.Stake_Click}
                                      CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                      WalletConnected={WalletConnected}
                                      SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                      ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                      from = "item"
                                   />
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    ):(
                    <div className="text-center py-5">
                      <p className="not_found_text">No items found</p>
                      <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                      <div className="mt-3">
                        <Button className="green_btn"><Link to="/nft">Browse Marketplace</Link></Button>
                      </div>
                    </div>
                    )}
                  </div>
                </div>
               
              </div>
              </GridItem>             
            </GridContainer>
          </div>
        </main>
        
        <WalletModal/>    


      {/* Report Modal */}
      <div class="modal fade primary_modal" id="report_page_modal" tabindex="-1" role="dialog" aria-labelledby="choose_collection_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="report_page_modalLabel">Report This Profile?</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <p className="mt-0 approve_text">Tell us how this user violates the rules of the site</p>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">Message</label>                    
                    <textarea className="form-control primary_inp" onChange={inputChange} id="description" placeholder="Tell us some details" rows="5"></textarea>
                    {ValidateError.description && <span className="text-danger">{ValidateError.description}</span>}
                
                  </div>
                </div>
                <div className="text-center mt-4">
                  <Button className="primary_btn w-100" onClick={()=>submituserreport()}>Submit</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>   

      </div>
    );
}

NFT.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(NFT);
