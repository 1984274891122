import React,{useEffect} from 'react'
import { Help } from '@material-ui/icons';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import config from "../lib/config"
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

const WalletModal =(props)=> {
  const [currAddr, setcurrAddr] = React.useState("");
  const [blns, setBln]          = React.useState(0);


useEffect(() => {
  getconnect();
}, []);
  async function getconnect() {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          window.ethereum.enable().then(async function () {
            const web3 = new Web3(window.web3.currentProvider);
            if (window.web3.currentProvider.isMetaMask === true) {
              await web3.eth.getAccounts(async function (error, result) {
                var currAddr = window.web3.eth.defaultAccount;
                setcurrAddr(currAddr);
                var web3 = new Web3(window.ethereum);
                window.web3.eth.getBalance(result[0],async (error, balance) => {
                    var currbal = balance / 1000000000000000000;
                    setBln(currbal.toFixed(5));
                  }
                );
           
              });
            }
          });
        } else {
        //  toast.success("Please Add Metamask External", toasterOption);
        }
      } catch (err) {}
    } else {
     // toast.success("Please Add Metamask External", toasterOption);
    }
  }

  async function disconnectMetamask() {
    if (localStorage.getItem("xdtitfnbolav")) {
      localStorage.removeItem("xdtitfnbolav");
      window.location.reload(false)
    } 
  }
  async function handleWallet() {

    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
        if(window.web3.currentProvider.networkVersion == config.networkVersion){
        if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            localStorage.setItem("xdtitfnbolav",'yes');
            window.location.reload(false)
          })
        }
      }else{
        toast.error("Please Choose Ethereum Mainnet", toasterOption);
      }
        })
        }else{
          //toast.success("Please Add Metamask External", toasterOption);
        }
      }catch(err){}
     }else{
       // toast.success("Please Add Metamask External", toasterOption);
     }
  }
  return (
    <div className="modal fade primary_modal" id="wallet_modal" tabIndex="-1" role="dialog" aria-labelledby="wallet_modal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="wallet_modal">Connect to a wallet</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
          {(!localStorage.getItem("xdtitfnbolav"))?(
            <div className="wallet_panel" onClick={()=>handleWallet()}>
              <h2>Metamask</h2>
              <img src={require("../assets/images/icon_meta_mask.png")} alt="Icon" className="img-fluid" />
            </div>
          ):(
            <div>
                <Button onClick={()=>disconnectMetamask()}>Logout</Button>
            </div>
          )}
            {/* <div className="wallet_panel">
              <h2>TrustWallet</h2>
              <img src={require("../assets/images/icon_trust_wallet.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel">
              <h2>MathWallet</h2>
              <img src={require("../assets/images/icon_math_wallet.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel">
              <h2>TokenPocket</h2>
              <img src={require("../assets/images/icon_pp.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel">
              <h2>WalletConnect</h2>
              <img src={require("../assets/images/icon_wallet_connect.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel">
              <h2>Binance Chain Wallet</h2>
              <img src={require("../assets/images/icon_binance_chain_wallet.png")} alt="Icon" className="img-fluid" />
            </div> */}
            <div className="wallet_modal_footer">
              <a href="#" target="_blank"><svg viewBox="0 0 20 20" color="text" width="20px" xmlns="http://www.w3.org/2000/svg" class="sc-bdfBQB ceaFhT"><path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM9 14H11V16H9V14ZM10.61 4.04C8.55 3.74 6.73 5.01 6.18 6.83C6 7.41 6.44 8 7.05 8H7.25C7.66 8 7.99 7.71 8.13 7.33C8.45 6.44 9.4 5.83 10.43 6.05C11.38 6.25 12.08 7.18 12 8.15C11.9 9.49 10.38 9.78 9.55 11.03C9.55 11.04 9.54 11.04 9.54 11.05C9.53 11.07 9.52 11.08 9.51 11.1C9.42 11.25 9.33 11.42 9.26 11.6C9.25 11.63 9.23 11.65 9.22 11.68C9.21 11.7 9.21 11.72 9.2 11.75C9.08 12.09 9 12.5 9 13H11C11 12.58 11.11 12.23 11.28 11.93C11.3 11.9 11.31 11.87 11.33 11.84C11.41 11.7 11.51 11.57 11.61 11.45C11.62 11.44 11.63 11.42 11.64 11.41C11.74 11.29 11.85 11.18 11.97 11.07C12.93 10.16 14.23 9.42 13.96 7.51C13.72 5.77 12.35 4.3 10.61 4.04Z"></path></svg><span>Learn how to connect</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
)
}

export default WalletModal;